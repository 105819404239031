import { combineReducers } from 'redux';
import { UserCreateState, userCreateReducer } from './Create';
import { UserRetrieveState, userRetrieveReducer } from './Retrieve';

export interface UserCrudState {
  create: UserCreateState;
  retrieve: UserRetrieveState;
}

const reducer = combineReducers<UserCrudState>({
  create: userCreateReducer,
  retrieve: userRetrieveReducer,
});

export * from './Create';
export * from './Entity';
export * from './Retrieve';

export { reducer as crudUserReducer };
