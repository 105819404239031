import { AppState } from '..';
import { RequestState } from '../../Services/Api/models';
import { UserEntityState, User, USER_ROLE } from './Entity';

const UserRequestSelector = {
  selectState: ({ requests: { user } }: AppState) => user,
};

const UserCreateSelector = {
  selectState: (appState: AppState): RequestState => {
    const { create } = UserRequestSelector.selectState(appState);
    return create;
  },
};

const UserRetrieveSelector = {
  selectState: (appState: AppState): RequestState => {
    const { retrieve } = UserRequestSelector.selectState(appState);
    return retrieve;
  },
  selectIsLoading: (appState: AppState): boolean => {
    const { isLoading } = UserRetrieveSelector.selectState(appState);
    return isLoading;
  },
};

const UserEntitySelector = {
  selectState: ({ entities: { users } }: AppState): UserEntityState => users,
  selectUsers: (appState: AppState): User[] => {
    const { users } = UserEntitySelector.selectState(appState);
    return users;
  },
  selectProviders: (appState: AppState): User[] => {
    const users = UserEntitySelector.selectUsers(appState);
    return users.filter(user => user.role === USER_ROLE.provider.toUpperCase());
  },
  selectInvestors: (appState: AppState): User[] => {
    const users = UserEntitySelector.selectUsers(appState);
    return users.filter(user => user.role === USER_ROLE.investor.toUpperCase());
  },
  selectUserById: (appState: AppState, id: string): User | undefined => {
    const users = UserEntitySelector.selectUsers(appState);
    return users.find(user => user.id === id);
  },
};

export { UserCreateSelector, UserEntitySelector, UserRetrieveSelector };
