import { combineReducers } from 'redux';
import { AddressRetrieveState, addressRetrieveReducer } from './Retrieve';

export interface AddressCrudState {
  retrieve: AddressRetrieveState;
}

const reducer = combineReducers<AddressCrudState>({
  retrieve: addressRetrieveReducer,
});

export * from './Entity';
export * from './Retrieve';

export { reducer as crudAddressReducer };
