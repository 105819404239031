import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { AuctionTitleContainer } from './Styles/TitleWithGoBack.styled';

import {
  lotResume,
  backFirstLetterCapitalized,
} from '../../../../../Common/strings';

interface Props {
  providerName: string;
  onGoBackClick: () => void;
}

const TitleWithGoBack: React.FC<Props> = (props: Props) => {
  const { providerName, onGoBackClick } = props;

  return (
    <AuctionTitleContainer>
      <Tooltip title="Voltar" arrow>
        <IconButton
          size="small"
          onClick={onGoBackClick}
          aria-label={backFirstLetterCapitalized}
          style={{ marginRight: '24px' }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      </Tooltip>
      <h1>{lotResume}</h1>
      <p>
        <strong>Fornecedor: </strong>
        {`${providerName}`}
      </p>
    </AuctionTitleContainer>
  );
};

export { TitleWithGoBack };
