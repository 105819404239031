import styled from 'styled-components';

import ticket from '../../../../Assets/ticket.svg';

export const Container = styled.div`
  position: relative;
  width: 336px;
  height: 310px;
`;

export const Ticket = styled.img.attrs({
  src: ticket,
  alt: 'Lote/Parte',
})`
  width: 336px;
`;
