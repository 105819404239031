import React from 'react';
import Button from '@material-ui/core/Button';
import { DropzoneInputProps } from 'react-dropzone';

import * as S from './DropzoneList.styled';

import { Items } from './Items';

import { addMore } from '../../../Common/strings';

interface Props {
  files: File[];
  maxFiles: number;
  open: () => void;
  onDeleteClick: (file: File) => void;
  getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
}

const DropzoneList: React.FC<Props> = (props: Props) => {
  const { files, maxFiles, open, onDeleteClick, getInputProps } = props;
  const classes = S.useStyles();

  return (
    <S.ListContainer>
      <Items items={files} onDeleteClick={onDeleteClick} />

      {files.length < maxFiles && (
        <S.ButtonContainer>
          <input {...getInputProps()} />
          <Button
            className={classes.addMoreButton}
            variant="contained"
            onClick={open}
          >
            {addMore}
          </Button>
        </S.ButtonContainer>
      )}
    </S.ListContainer>
  );
};

export { DropzoneList };
