import React from 'react';
import { useHistory } from 'react-router-dom';

import { Lot as LotEntity } from '../../../../Redux/Lot/Entity';

import { Lot as LotContainer } from '../../Components/Lot';
import { DefaultButton } from '../../../../Components/Buttons';

import * as S from './Styles/Lot.styled';

interface Props {
  lot: LotEntity;
}

const Lot: React.FC<Props> = ({ lot }: Props) => {
  const history = useHistory();
  const {
    id,
    owner_lot_name,
    total_value_lot,
    total_invoices,
    total_invalid_invoices,
    return_percent_expected,
  } = lot;

  const handleButtonClick = (): void => history.push(`lotes/${id}/notas`);

  return (
    <LotContainer>
      <S.Content>
        <S.InfoWrapper>
          <S.Label>Fornecedor</S.Label>
          <S.Value>{owner_lot_name}</S.Value>
        </S.InfoWrapper>
        <S.ValuesWrapper>
          <S.Label>Valor total:</S.Label>
          <S.Value>{`R$ ${total_value_lot}`}</S.Value>
          <S.Label>Total de notas</S.Label>
          <S.Value>{total_invoices}</S.Value>
          <S.Label>Total de notas inválidas</S.Label>
          <S.Value>{total_invalid_invoices}</S.Value>
          <S.Label>Taxa experada</S.Label>
          <S.Value>{return_percent_expected}</S.Value>
        </S.ValuesWrapper>
        <S.Footer>
          <DefaultButton
            text="resumo do lote"
            variant_type="primary"
            onClick={handleButtonClick}
          />
        </S.Footer>
      </S.Content>
    </LotContainer>
  );
};

export { Lot };
