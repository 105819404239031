import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  align-items: center;
  padding: 24px 32px;
  grid-template-columns: repeat(12, 1fr);

  color: var(--color-black);

  h1,
  p {
    margin: 0;
    grid-column: span 4;
  }
`;

export const Title = styled.h1`
  font-size: calc(var(--font-size-base) + 8px);
`;

export const FollowButtonContainer = styled.div`
  text-align: end;
  grid-column: span 1;
`;

export const BidButtonContainer = styled.div`
  grid-column: span 2;
`;
