import React from 'react';

import { MUIDataTableColumnDef } from 'mui-datatables';

import { Table } from '../../../../Components/Table';
import { Loading } from '../../../../Components/Loading';

import { User } from '../../../../Redux/User/Entity';

import { investorsFirstLetterCapitalized } from '../../../../Common/strings';

interface Props {
  investors: User[];
  isLoading: boolean;
  columns: MUIDataTableColumnDef[];
}

const InvestorTable: React.FC<Props> = (props: Props) => {
  const { investors, isLoading, columns } = props;

  return isLoading ? (
    <Loading />
  ) : (
    <Table
      data={investors}
      columns={columns}
      title={investorsFirstLetterCapitalized}
    />
  );
};

export { InvestorTable };
