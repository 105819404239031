import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../Styles/themes';

import cloudUpload from '../../../Assets/cloudUpload.svg';

export const DragAndDrop = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  grid-template-rows: repeat(2, max-content) 1fr;
`;

export const UploadImage = styled.img.attrs({
  src: cloudUpload,
  alt: 'Upload de arquivos',
})`
  width: 150px;
  height: 150px;
  justify-self: center;
`;

export const DragAndDropText = styled.p`
  margin: 0;
  font-family: 'Open Sans';
  font-size: 24px;
  color: #353535;
  text-align: center;
`;

export const useStyles = makeStyles({
  clickToSelectButton: {
    justifySelf: 'center',
    fontFamily: 'Roboto Slab',
    padding: 0,
    fontWeight: 500,
    fontSize: 12,
    color: COLORS.WHITE,
    background: '#2C98F0',
    height: '36px',
    width: '240px',
  },
});
