import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

interface Props extends DialogProps {
  title: string;
  error?: boolean;
  children: React.ReactNode;
  onCancelClick: () => void;
  onConfirmClick?: () => void;
}

export const ConfirmDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props);
  const { title, children, onConfirmClick, onCancelClick, ...rest } = props;

  return (
    <Dialog
      {...rest}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.title} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button className={classes.cancelButton} onClick={onCancelClick}>
          Cancelar
        </Button>
        <Button
          autoFocus
          type="submit"
          color="primary"
          onClick={onConfirmClick}
          className={classes.confirmButton}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  title: {
    color: ({ error }: Props) =>
      error ? 'var(--color-red)' : 'var(--color-light-blue)',
  },
  cancelButton: {
    color: 'var(--color-gray)',
  },
  confirmButton: {
    color: 'var(--color-light-blue)',
  },
});
