import styled from 'styled-components';

import MaterialDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

export const Dialog = styled(MaterialDialog)``;

export const Title = styled(DialogTitle)`
  color: var(--color-light-blue);
`;

export const Content = styled(DialogContent)`
  margin-bottom: 32px;
`;

export const Description = styled(DialogContentText)`
  font-weight: bold;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-gap: 16px;
`;

export const Label = styled.p`
  color: var(--color-gray);
`;

export const Value = styled.strong`
  font-weight: bold;
  color: var(--color-black);
`;

export const ListTitle = styled.p`
  font-weight: bold;
  color: var(--color-black);
`;

export const InvoiceList = styled.ul`
  list-style: none;
`;

export const InvoiceItem = styled.li`
  &:after {
    display: block;
    content: '';
    margin-bottom: 8px;
  }

  &:last-child {
    &:after {
      margin-bottom: 0;
    }
  }
`;

export const ButtonsContainer = styled(DialogActions)`
  margin: 0px 16px 8px 0px;
`;
