import { Entity } from '../../models';

import { InvoiceTypes } from '../types';
import { InvoiceActions } from '../actions';

interface Address {
  cep: string;
  complement: string;
  neighborhood: string;
  number: string;
  street: string;
  uf: string;
}

interface Crt {
  detail: string;
  number: string;
}
interface Emitter {
  address: Address;
  crt: Crt;
  docNumber: string;
  name: string;
  type: string;
}

interface Receiver {
  address: Address;
  docNumber: string;
  name: string;
  type: string;
}

interface Payment {
  indicatorDetail: string;
  detail: string;
  value: string;
}

export interface Invoice extends Entity {
  invoiceId: string;
  comments: string;
  is_approve: boolean;
  is_valid: boolean;
  docNumber: string;
  operationNature: string;
  payment: Payment;
  insurance: string;
  value: string;
  status: string;
  productValue: string;
  shippingValue: string;
  emissionDate: Date | string;
  emitter: Emitter;
  receiver: Receiver;
}

export interface InvoiceEntityState {
  invoices: Invoice[];
}

const initialState: InvoiceEntityState = {
  invoices: [],
};

export function invoiceEntityReducer(
  state = initialState,
  action: InvoiceActions,
): InvoiceEntityState {
  switch (action.type) {
    case InvoiceTypes.RETRIEVE_INVOICES_BY_LOT_ID_SUCCESS: {
      const invoices = action.payload;
      return { ...state, invoices };
    }
    case InvoiceTypes.RETRIEVE_INVOICES_BY_AUCTION_ITEM_SUCCESS: {
      const invoices = action.payload;
      return { ...state, invoices };
    }
    default:
      return state;
  }
}
