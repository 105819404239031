import styled from 'styled-components';

import { COLORS } from '../../../../Styles/themes';

export const ProviderUploadContainer = styled.div`
  height: calc(100vh - 64px);

  display: flex;
  align-items: stretch;
  margin: 0px 82px 0px 82px;
`;

export const ProviderUploadTextContainer = styled.div`
  height: calc(100vh - 64px);
  width: 50vw;

  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-family: 'Open Sans';
    font-weight: 37.5rem;
    font-size: 2.25rem;
    color: ${COLORS.MEDIUM_BLUE};
    margin-bottom: 16px;
  }

  h2 {
    font-family: 'Open Sans';
    font-weight: 25rem;
    font-size: 1.5rem;
    color: #999;
    margin-bottom: 16px;
  }

  h3 {
    font-family: 'Open Sans';
    font-weight: 25rem;
    font-size: 1.12rem;
    color: ${COLORS.LIGHTEST_ORANGE};
    margin-bottom: 32px;
  }

  ul {
    margin-left: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    li {
      font-family: 'Open Sans';
      font-weight: 37.5rem;
      font-size: 1.5rem;
      color: ${COLORS.MEDIUM_BLUE};
    }
  }
`;

export const ListTitle = styled.p`
  font-family: 'Open Sans';
  font-weight: 37.5rem;
  font-size: 1.5rem;
  color: ${COLORS.MEDIUM_BLUE};
  margin-bottom: 24px;
`;

export const DropzoneContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50vw;
`;
