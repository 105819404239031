import React from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';

import { useStyles } from './styles';

interface Props extends ButtonProps {
  title: string;
}

const TextButton: React.FC<Props> = ({ title, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <Button className={classes.root} {...rest}>
      {title}
    </Button>
  );
};

export { TextButton };
