import apisauce, {
  ApisauceInstance,
  ApiResponse as ApisauceResponse,
} from 'apisauce';

import { apiUrl } from '../../Config';

export type ApiResponse<T> = ApisauceResponse<T>;

export type ApiInstance = ApisauceInstance;

export function createApi(baseURL: string): ApisauceInstance {
  const api = apisauce.create({
    baseURL,
  });

  return api;
}

let apiInstance: ApiInstance;

export function getApiInstance(baseURL: string = apiUrl): ApisauceInstance {
  if (!apiInstance) {
    if (!baseURL) {
      throw new Error('baseURL not defined');
    }
    apiInstance = createApi(baseURL);
  }
  return apiInstance;
}
