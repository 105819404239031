import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

import { InvoiceStatus } from '../../Models';

import { Invoice } from './Entity';
import { InvoiceTypes } from './types';

interface RetrieveRequest {
  id: string;
  page: number;
  status?: InvoiceStatus;
}

export interface EvaluateInvoiceRequest {
  lotId: string;
  invoiceId: string;
  comments: string;
  is_approved: boolean;
  status?: InvoiceStatus;
}

export const InvoiceRetrieveActionsCreators = {
  invoiceRetrieveByLotIdRequest: (
    id: string,
    page: number,
    status: InvoiceStatus,
  ): Readonly<{
    payload: RetrieveRequest;
    type: InvoiceTypes.RETRIEVE_INVOICES_BY_LOT_ID_REQUEST;
  }> =>
    createAction(InvoiceTypes.RETRIEVE_INVOICES_BY_LOT_ID_REQUEST, {
      id,
      page,
      status,
    }),
  invoiceRetrieveByLotIdSuccess: (
    invoices: Invoice[],
  ): Readonly<{
    type: InvoiceTypes.RETRIEVE_INVOICES_BY_LOT_ID_SUCCESS;
    payload: Invoice[];
  }> =>
    createAction(InvoiceTypes.RETRIEVE_INVOICES_BY_LOT_ID_SUCCESS, invoices),
  invoiceRetrieveByLotIdFailure: (
    errorMsg: string,
  ): Readonly<{
    payload: string;
    type: InvoiceTypes.RETRIEVE_INVOICES_BY_LOT_ID_FAILURE;
  }> =>
    createAction(InvoiceTypes.RETRIEVE_INVOICES_BY_LOT_ID_FAILURE, errorMsg),
  invoiceRetrieveByAuctionItemSuccess: (
    invoices: Invoice[],
  ): Readonly<{
    type: InvoiceTypes.RETRIEVE_INVOICES_BY_AUCTION_ITEM_SUCCESS;
    payload: Invoice[];
  }> =>
    createAction(
      InvoiceTypes.RETRIEVE_INVOICES_BY_AUCTION_ITEM_SUCCESS,
      invoices,
    ),
};

export const InvoicePatchActionsCreators = {
  invoiceEvaluateByLotIdAndInvoiceIdRequest: (
    request: EvaluateInvoiceRequest,
  ): Readonly<{
    payload: EvaluateInvoiceRequest;
    type: InvoiceTypes.EVALUATE_INVOICE_BY_ID_REQUEST;
  }> => createAction(InvoiceTypes.EVALUATE_INVOICE_BY_ID_REQUEST, request),
  invoiceEvaluateByLotIdAndInvoiceIdSuccess: (): Readonly<{
    type: InvoiceTypes.EVALUATE_INVOICE_BY_ID_SUCCESS;
  }> => createAction(InvoiceTypes.EVALUATE_INVOICE_BY_ID_SUCCESS),
  invoiceEvaluateByLotIdAndInvoiceIdFailure: (
    errorMsg: string,
  ): Readonly<{
    payload: string;
    type: InvoiceTypes.EVALUATE_INVOICE_BY_ID_FAILURE;
  }> => createAction(InvoiceTypes.EVALUATE_INVOICE_BY_ID_FAILURE, errorMsg),
  invoiceEvaluateInitialState: (): Readonly<{
    type: InvoiceTypes.EVALUATE_INVOICE_INITIAL_STATE;
  }> => createAction(InvoiceTypes.EVALUATE_INVOICE_INITIAL_STATE),
};

export type InvoiceRetrieveActions = ActionsUnion<
  typeof InvoiceRetrieveActionsCreators
>;

export type InvoicePatchActions = ActionsUnion<
  typeof InvoicePatchActionsCreators
>;

export type InvoiceActions = InvoiceRetrieveActions | InvoicePatchActions;
