import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

import { SampleTypes } from './types';

export const SampleCreators = {
  incrementCountRequest: (): Readonly<{
    type: SampleTypes.INCREMENT_COUNT_REQUEST;
  }> => createAction(SampleTypes.INCREMENT_COUNT_REQUEST),

  incrementCountSuccess: (): Readonly<{
    type: SampleTypes.INCREMENT_COUNT_SUCCESS;
  }> => createAction(SampleTypes.INCREMENT_COUNT_SUCCESS),

  decrementCountRequest: (): Readonly<{
    type: SampleTypes.DECREMENT_COUNT_REQUEST;
  }> => createAction(SampleTypes.DECREMENT_COUNT_REQUEST),

  decrementCountSuccess: (): Readonly<{
    type: SampleTypes.DECREMENT_COUNT_SUCCESS;
  }> => createAction(SampleTypes.DECREMENT_COUNT_SUCCESS),
};

export type SampleActions = ActionsUnion<typeof SampleCreators>;
