import styled from 'styled-components';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { DRAWER_WIDTH } from '../../Common/enums';

import { COLORS } from '../../Styles/themes';
import simpleLogo from '../../Assets/simpleLogo.svg';

export const Title = styled.p`
  font-family: 'Roboto Slab';
  font-weight: 500px;
  font-size: 20px;
  color: ${COLORS.WHITE};
`;

export const Logo = styled.img.attrs({
  src: simpleLogo,
  alt: 'Arena Crédito',
})`
  width: 43;
  height: 30;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: `${DRAWER_WIDTH}px`,
      flexShrink: 0,
    },
    drawerPaper: {
      width: `${DRAWER_WIDTH}px`,
    },
    // necessary for content to be below app bar
    toolbar: {
      display: 'grid',
      gridGap: 42,
      gridTemplateColumns: 'max-content max-content',
      paddingLeft: 16,
      paddingRight: 16,
      alignItems: 'center',
      background: COLORS.BLUE,
      height: 64,
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
      marginTop: 64,
    },
    logoutWrapper: {
      display: 'flex',
      flex: 1,
      alignItems: 'flex-end',
    },
    logoutButton: {
      paddingTop: '8px',
      borderTop: '1px solid #eee',
    },
  }),
);
