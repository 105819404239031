import { AppState } from '..';
import { ParameterEntityState, Parameter } from './Entity';

const ParameterEntitySelector = {
  selectState: ({ entities: { parameter } }: AppState): ParameterEntityState =>
    parameter,
  selectParameters: (appState: AppState): Parameter[] => {
    const { parameters } = ParameterEntitySelector.selectState(appState);
    return parameters;
  },
};

export { ParameterEntitySelector };
