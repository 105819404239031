import React, { useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { InvoiceStatus } from '../../../Models';

import { InvoiceRetrieveActionsCreators } from '../../../Redux/Invoice/actions';
import {
  InvoiceEntitySelector,
  InvoiceRetrieveAllStateSelectors,
} from '../../../Redux/Invoice/selectors';
import { AuthSelectors } from '../../../Redux/Auth/selectors';

import { AuthRoutes } from '../../../Routes';

import { StatusBar } from '../../../Components/StatusBar';
import { InvoiceTable, InvoiceTableActionsColumn } from './Components';

import {
  invalidCapitalized,
  underAnalysisCapitalized,
  refusedFirstLetterCapitalized,
  approvedFirstLetterCapitalized,
} from '../../../Common/strings';

interface ParamsState {
  id: string;
}

const InvoiceListPage: React.FC = () => {
  const { id } = useParams<ParamsState>();
  const history = useHistory();
  const invoices = useSelector(InvoiceEntitySelector.selectInvoices);
  const isLoading = useSelector(
    InvoiceRetrieveAllStateSelectors.selectIsLoading,
  );
  const isProvider = useSelector(AuthSelectors.selectIsProvider);

  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [status, setStatus] = React.useState<InvoiceStatus>('pending');
  const tabs = [
    underAnalysisCapitalized,
    refusedFirstLetterCapitalized,
    approvedFirstLetterCapitalized,
    invalidCapitalized,
  ];

  const setPageName = (): string => {
    return isProvider
      ? `${AuthRoutes.provider}${AuthRoutes.lots}/nota/analise`
      : `${AuthRoutes.backoffice}${AuthRoutes.lots}/nota/avaliacao`;
  };

  const handleEvaluateClick = (invoiceId: string): void => {
    history.push(setPageName(), { invoiceId, lotId: id });
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Sacado',
      name: 'emitter.name',
    },
    {
      label: 'CNPJ',
      name: 'cnpj',
    },
    {
      label: 'Data/hora',
      name: 'emissionDate',
    },
    {
      label: 'Estado',
      name: 'emitter.address.uf',
    },
    {
      label: 'Tipo',
      name: 'emitter.type',
    },
    {
      label: ' ',
      name: 'id',
      options: {
        customBodyRender: id => {
          return (
            <InvoiceTableActionsColumn
              id={id}
              handleEvaluateClick={() => handleEvaluateClick(id)}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (tabValue === 0) {
      setStatus('pending');
    } else if (tabValue === 1) {
      setStatus('refused');
    } else if (tabValue === 2) {
      setStatus('approved');
    } else if (tabValue === 3) {
      setStatus('invalid');
    }
  }, [tabValue]);

  const loadInvoicesByLotId = useCallback(
    (page = 1) => {
      dispatch(
        InvoiceRetrieveActionsCreators.invoiceRetrieveByLotIdRequest(
          id,
          page,
          status,
        ),
      );
    },
    [dispatch, id, status],
  );

  useEffect(() => loadInvoicesByLotId(), [loadInvoicesByLotId]);

  const handleChangePage = (currentPage: number): void => {
    setPage(currentPage);
    loadInvoicesByLotId(currentPage + 1);
  };

  const options: MUIDataTableOptions = {
    page,
    onChangePage: handleChangePage,
    enableNestedDataAccess: '.',
  };

  return (
    <>
      <StatusBar tabs={tabs} value={tabValue} onChange={setTabValue} />
      <InvoiceTable
        options={options}
        invoices={invoices}
        columns={columns}
        isLoading={isLoading}
      />
    </>
  );
};

export { InvoiceListPage };
