export enum InvoiceTypes {
  EVALUATE_INVOICE_INITIAL_STATE = '[invoice] EVALUATE_INVOICE_INITIAL_STATE',
  EVALUATE_INVOICE_BY_ID_REQUEST = '[invoice] EVALUATE_INVOICE_BY_ID_REQUEST',
  EVALUATE_INVOICE_BY_ID_SUCCESS = '[invoice] EVALUATE_INVOICE_BY_ID_SUCCESS',
  EVALUATE_INVOICE_BY_ID_FAILURE = '[invoice] EVALUATE_INVOICE_BY_ID_FAILURE',
  RETRIEVE_INVOICES_BY_LOT_ID_REQUEST = '[invoice] RETRIEVE_INVOICES_BY_LOT_ID_REQUEST',
  RETRIEVE_INVOICES_BY_LOT_ID_SUCCESS = '[invoice] RETRIEVE_INVOICES_BY_LOT_ID_SUCCESS',
  RETRIEVE_INVOICES_BY_LOT_ID_FAILURE = '[invoice] RETRIEVE_INVOICES_BY_LOT_ID_FAILURE',
  RETRIEVE_INVOICES_BY_AUCTION_ITEM_SUCCESS = '[invoice] RETRIEVE_INVOICES_BY_AUCTION_ITEM_SUCCESS',
}
