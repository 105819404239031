import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

import { UserTypes } from './types';
import { USER_ROLE, User } from './Entity';
import { Status } from '../../Common/Types';

export interface PatchUserRequestEvaluationActionRequest {
  comments: string;
  request_id: number;
  is_approved: boolean;
  closed_request: boolean;
}

export interface PatchUserRequestEvaluationActionResponse {
  user_id: string;
  request_id: number;
  is_approved: boolean;
}

export interface CreateUserActionRequest {
  cnpj: string;
  email: string;
  role: USER_ROLE;
  password: string;
  full_name: string;
}

export interface UserRetrieveRequest {
  limit?: number;
  page?: number;
  role_name: USER_ROLE;
  filter_by: Status;
}

export const UserRetrieveAllActionCreators = {
  userRetrieveAllRequest: (
    request: UserRetrieveRequest,
  ): Readonly<{
    type: UserTypes.RETRIEVE_ALL_USERS_REQUEST;
    payload: UserRetrieveRequest;
  }> => createAction(UserTypes.RETRIEVE_ALL_USERS_REQUEST, request),

  userRetrieveAllSuccess: (
    response: User[],
  ): Readonly<{
    type: UserTypes.RETRIEVE_ALL_USERS_SUCCESS;
    payload: User[];
  }> => createAction(UserTypes.RETRIEVE_ALL_USERS_SUCCESS, response),

  userRetrieveAllFailure: (
    errorMsg: string,
  ): Readonly<{
    type: UserTypes.RETRIEVE_ALL_USERS_FAILURE;
    payload: string;
  }> => createAction(UserTypes.RETRIEVE_ALL_USERS_FAILURE, errorMsg),
};

export const UserCreateActionCreators = {
  userCreateRequest: (
    request: CreateUserActionRequest,
  ): Readonly<{
    type: UserTypes.CREATE_USER_REQUEST;
    payload: CreateUserActionRequest;
  }> => createAction(UserTypes.CREATE_USER_REQUEST, request),

  userCreateSuccess: (): Readonly<{
    type: UserTypes.CREATE_USER_SUCCESS;
  }> => createAction(UserTypes.CREATE_USER_SUCCESS),

  userCreateFailure: (
    errorMsg: string,
  ): Readonly<{
    type: UserTypes.CREATE_USER_FAILURE;
    payload: string;
  }> => createAction(UserTypes.CREATE_USER_FAILURE, errorMsg),
};

export type UserCreateActions = ActionsUnion<typeof UserCreateActionCreators>;
export type UserRetrieveWaitingApproval = ActionsUnion<
  typeof UserRetrieveAllActionCreators
>;

export type UserActions = UserCreateActions | UserRetrieveWaitingApproval;
