function getApiBaseUrl(): string {
  const apiUrlEnv = process.env.REACT_APP_API_BASE_URL;

  if (!apiUrlEnv) {
    throw new Error('process.env.REACT_APP_API_BASE_URL obrigatório');
  }

  return apiUrlEnv;
}

const apiUrl = getApiBaseUrl();

export { apiUrl };
