import React from 'react';

import SearchIcon from '@material-ui/icons/Search';

import { DefaultButton } from '../../../../Components/Buttons';

import { evaluateCapitalized } from '../../../../Common/strings';

interface Props {
  onEvaluateClick: () => void;
}

const ProviderTableActionsColumn: React.FC<Props> = (props: Props) => {
  const { onEvaluateClick } = props;

  return (
    <DefaultButton
      variant_type="primary"
      endIcon={<SearchIcon />}
      text={evaluateCapitalized}
      onClick={onEvaluateClick}
    />
  );
};

export { ProviderTableActionsColumn };
