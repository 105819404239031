import React from 'react';

import {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
  MUIDataTableProps,
} from 'mui-datatables';

import { Table } from '../../../../Components/Table';
import { Loading } from '../../../../Components/Loading';

type Diff<T, K> = Omit<T, keyof K>;

interface Props extends Diff<MUIDataTableProps, { data: any; title: string }> {
  isLoading: boolean;
  columns: MUIDataTableColumnDef[];
  options?: MUIDataTableOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invoices: (any | number[] | string[])[] | undefined;
}

const InvoiceTable: React.FC<Props> = (props: Props) => {
  const { options, columns, invoices, isLoading, ...rest } = props;
  return isLoading ? (
    <Loading />
  ) : (
    <Table data={invoices} columns={columns} options={options} {...rest} />
  );
};

export { InvoiceTable };
