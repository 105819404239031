import React, { useCallback, useEffect, useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { RegisterComponent } from './Components';

import { parseCnpj } from '../../../../Common/functionUtils';
import { UserCreateSelector } from '../../../../Redux/User/selectors';
import {
  CreateUserActionRequest,
  UserCreateActionCreators,
} from '../../../../Redux/User/actions';
import { USER_ROLE } from '../../../../Redux/User/Entity';

import {
  invalidCnpj,
  nameRequired,
  cnpjRequired,
  requiredEmail,
  writeValidEmail,
  requiredPassword,
  minPasswordLength,
} from '../../../../Common/strings';

interface Props {
  sumStep: (value: number) => void;
}

const RegisterStep: React.FC<Props> = ({ sumStep }) => {
  const dispatch = useDispatch();
  const userCreateState = useSelector(UserCreateSelector.selectState);
  const { isLoading: isCreating, errorMsg, isDone } = userCreateState;

  const [open, setOpen] = useState(false);

  const initialValues: CreateUserActionRequest = {
    full_name: '',
    cnpj: '',
    email: '',
    password: '',
    role: USER_ROLE.none,
  };

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required(nameRequired),
    email: Yup.string().email(writeValidEmail).required(requiredEmail),
    role: Yup.string().required(),
    cnpj: Yup.string().cnpj(invalidCnpj).required(cnpjRequired),
    password: Yup.string().required(requiredPassword).min(8, minPasswordLength),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => handleRegister({ values }),
  });

  const handleRegister = useCallback(
    ({ values }) => {
      const { cnpj, ...rest } = values;
      const request = { cnpj: parseCnpj(cnpj), ...rest };

      dispatch(UserCreateActionCreators.userCreateRequest(request));
    },
    [dispatch],
  );

  useEffect(() => {
    if (isDone && !errorMsg) {
      sumStep(1);
    }
  }, [errorMsg, sumStep, isDone]);

  const handleCloseAlert = useCallback(
    (_?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    },
    [],
  );

  useEffect(() => {
    if (errorMsg) {
      setOpen(true);
    }
  }, [errorMsg]);

  return (
    <RegisterComponent
      open={open}
      formik={formik}
      errorMsg={errorMsg}
      isSubmitting={isCreating}
      onCloseAlert={handleCloseAlert}
    />
  );
};

export { RegisterStep };
