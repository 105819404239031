import React, { useState } from 'react';

import { IconButton } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';

import { passwordFirstLetterCapitalized } from '../../Common/strings';

import { DefaultTextField } from '.';

const PasswordTextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const { placeholder, name, id } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (): void => setShowPassword(show => !show);

  return (
    <DefaultTextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      variant="outlined"
      id={id || 'password'}
      name={name || 'password'}
      placeholder={placeholder || passwordFirstLetterCapitalized}
      inputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockOpenOutlined />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export { PasswordTextField };
