import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

import { SellingOrderTypes } from './types';
import { SellingOrderResponse } from './Entity';

export const SellingOrderRetrieveActionsCreators = {
  sellingOrderRetrieveAllRequest: (): Readonly<{
    type: SellingOrderTypes.RETRIEVE_ALL_SELLING_ORDER_REQUEST;
  }> => createAction(SellingOrderTypes.RETRIEVE_ALL_SELLING_ORDER_REQUEST),

  sellingOrderRetrieveAllSuccess: (
    response: SellingOrderResponse,
  ): Readonly<{
    type: SellingOrderTypes.RETRIEVE_ALL_SELLING_ORDER_SUCCESS;
    payload: SellingOrderResponse;
  }> =>
    createAction(
      SellingOrderTypes.RETRIEVE_ALL_SELLING_ORDER_SUCCESS,
      response,
    ),

  sellingOrderRetrieveAllFailure: (
    errorMsg: string,
  ): Readonly<{
    type: SellingOrderTypes.RETRIEVE_ALL_SELLING_ORDER_FAILURE;
    payload: string;
  }> =>
    createAction(
      SellingOrderTypes.RETRIEVE_ALL_SELLING_ORDER_FAILURE,
      errorMsg,
    ),
};

export type SellingOrderRetrieveActions = ActionsUnion<
  typeof SellingOrderRetrieveActionsCreators
>;

export type SellingOrderActions = SellingOrderRetrieveActions;
