import React from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';

import { BUTTON_BACKGROUND } from '../../../Common/enums';

import { useStyles } from './styles';

export interface Props extends ButtonProps {
  title: string;
  background: BUTTON_BACKGROUND;
}

const TableButton: React.FC<Props> = (props: Props) => {
  const { title, variant, ...rest } = props;

  const classes = useStyles(props);

  return (
    <Button className={classes.root} variant={variant || 'contained'} {...rest}>
      {title}
    </Button>
  );
};

export { TableButton };
