import React from 'react';

import SearchIcon from '@material-ui/icons/Search';

import * as S from './Styles/AuctionInvoiceDetailsActionsColumn.styled';

interface Props {
  onAnalyseClick: () => void;
}

export const AuctionInvoiceDetailsActionsColumn: React.FC<Props> = ({
  onAnalyseClick,
}: Props) => {
  return (
    <S.CustomButton variant="primary" onClick={onAnalyseClick}>
      <S.Text>Analisar</S.Text>
      <SearchIcon />
    </S.CustomButton>
  );
};
