import { combineReducers } from 'redux';

import { lotCreateReducer, LotCreateState } from './Create';
import { lotRetrieveReducer, LotRetrieveState } from './Retrieve';

export interface LotCrudState {
  create: LotCreateState;
  retrieve: LotRetrieveState;
}

const reducer = combineReducers<LotCrudState>({
  create: lotCreateReducer,
  retrieve: lotRetrieveReducer,
});

export * from './Entity';
export * from './Create';
export * from './Retrieve';

export { reducer as crudLotReducer };
