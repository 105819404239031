import { makeStyles } from '@material-ui/core/styles';
import { ButtonProps } from '@material-ui/core/Button';

import { COLORS } from '../../../Styles/themes';

export interface Props extends ButtonProps {
  text: string;
  isLoading?: boolean;
  variant_type: 'primary' | 'secondary';
}

export const useStyles = makeStyles({
  root: {
    height: '32px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 500,
    width: '144px',
    fontFamily: 'Roboto Slab',
    textTransform: 'uppercase',
    color: ({ variant_type }: Props) =>
      isPrimary(variant_type) ? COLORS.WHITE : COLORS.PRIMARY_BLUE,
    background: ({ variant_type }: Props) =>
      isPrimary(variant_type) ? COLORS.PRIMARY_BLUE : COLORS.WHITE,

    '&:hover': {
      opacity: 0.7,
      background: ({ variant_type }: Props) =>
        isPrimary(variant_type) ? COLORS.PRIMARY_BLUE : COLORS.WHITE,
    },
  },
  loading: {
    color: COLORS.GREEN,
  },
});

const isPrimary = (type: 'primary' | 'secondary' | undefined): boolean =>
  type === 'primary';
