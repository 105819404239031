import { makeStyles } from '@material-ui/core/styles';

import { BUTTON_BACKGROUND } from '../../../Common/enums';

import { Props } from '.';

export const useStyles = makeStyles({
  root: {
    height: 36,
    fontSize: 14,
    color: 'white',
    borderRadius: 4,
    fontWeight: 500,
    marginRight: '16px',
    fontFamily: 'Roboto Slab',
    background: ({ background }: Props) => setBackground(background),

    '&:hover': {
      opacity: 0.3,
      background: ({ background }: Props) => setBackground(background),
    },
  },
});

function setBackground(type: BUTTON_BACKGROUND): string {
  if (type === BUTTON_BACKGROUND.SECONDARY) {
    return '#E10050';
  }
  return '#2C98F0';
}
