import React from 'react';
import { useHistory } from 'react-router-dom';

import { BackButton } from '../../../../Components/Buttons';

import {
  Container,
  Content,
  Title,
  Subtitle,
  Logo,
  InvoiceImage,
} from './styles';

import {
  ifYourCompany,
  provideYourInformationWithSafety,
} from '../../../../Common/strings';

const LeftContainer: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <Content>
        <div>
          <BackButton onClick={() => history.push('/')} />
          <Logo />
        </div>
        <Title>{provideYourInformationWithSafety}</Title>
        <Subtitle>{ifYourCompany}</Subtitle>
      </Content>
      <InvoiceImage />
    </Container>
  );
};

export { LeftContainer };
