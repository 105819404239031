import styled from 'styled-components';
import { styled as styledMaterial } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { COLORS } from '../../../Styles/themes';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${COLORS.BLUE};

  width: 50vw;
`;

export const Form = styled.form`
  width: 40vw;
  display: flex;
  flex: 1;
  text-align: center;
  flex-direction: column;

  > div {
    margin-bottom: 2vh;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24;
  justify-content: space-between;

  > :first-child {
    margin-right: 24px;
  }
`;

export const LoginText = styledMaterial(Typography)({
  marginTop: '7vh',
  fontSize: '3rem',
  fontWeight: 'bold',
  textAlign: 'start',
  color: COLORS.WHITE,
  fontFamily: 'Open Sans',
  width: '40vw',
  marginBottom: '152px',
});

export const ForgotPasswordButton = styledMaterial(Button)({
  marginTop: 24,
  fontSize: '0.9rem',
  color: COLORS.WHITE,
  fontFamily: 'Open Sans',
});
