import React, { useCallback } from 'react';

import { Tabs } from '../Tabs';

import { Container } from './styles';

interface Props {
  tabs: string[];
  value: number;
  onChange: (value: number) => void;
}

const StatusBar: React.FC<Props> = ({ tabs, value, onChange }: Props) => {
  const handleChange = useCallback(
    (_: React.ChangeEvent, newValue: number) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <Container>
      <Tabs value={value} tabs={tabs} onChange={handleChange} />
    </Container>
  );
};

export { StatusBar };
