import { Status } from '../Common/enums';

export type InvoiceStatus =
  | 'approved'
  | 'pending'
  | 'refused'
  | 'invalid'
  | 'ongoing';
export type Role = 'backoffice' | 'fornecedor' | 'investidor';

export interface User {
  _id?: string;
  address: string;
  cep: string;
  cnpj: string;
  complement: string;
  cpf: string;
  createdAt: Date;
  email: string;
  evaluations: string[];
  full_name: string;
  house_number: string;
  is_approved: boolean;
  neighborhood: string;
  social_reason: string;
  state: string;
  updatedAt: Date;
  was_evaluated: boolean;
  role: Role;
}

interface Atividade {
  text: string;
  code: string;
}

export interface Company {
  uf: string;
  cep: string;
  efr?: string;
  cnpj: string;
  nome: string;
  tipo: string;
  email: string;
  porte: string;
  bairro: string;
  numero: string;
  status: string;
  message?: string;
  fantasia: string;
  situacao: string;
  telefone: string;
  municipio: string;
  logradouro: string;
  complemento: string;
  data_situacao?: string;
  capital_social?: string;
  motivo_situacao?: string;
  natureza_juridica?: string;
  situacao_especial?: string;
  atividade_principal: Atividade;
  atividade_secundaria: Atividade;
  data_situacao_especial?: string;
}

export interface SideBarItem {
  label: string;
  icon: React.ReactNode;
  route: string;
}

export enum ROLE {
  NONE = '',
  INVESTOR = 'investidor',
  PROVIDER = 'fornecedor',
  BACK_OFFICE = 'back-office',
}

export interface SideBarProps {
  role: ROLE;
  items: SideBarItem[];
  onClick: (value: SideBarItem) => void;
}

export interface Auction {
  id: number;
  hour: string;
  lot: string;
  value: string;
  provider: string;
  quantity: number;
  status: Status;
  end_in: string;
  biddings: number;
  best_bid: number;
}
