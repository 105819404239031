export interface Entity {
  id: string;
  _id?: string;
}

export enum ROLE {
  NONE = '',
  INVESTOR = 'investidor',
  PROVIDER = 'fornecedor',
  BACK_OFFICE = 'back-office',
}

export interface Bid {
  _id: string;
  __v: number;
  item: string;
  value: number;
  bidder: string;
  createdAt: Date;
  updatedAt: Date;
}
