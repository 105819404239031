import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from '../../../../../Styles/themes';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background: ${COLORS.BLUE};
  padding: 8px 42px 24px;

  grid-template-rows: repeat(4, max-content);
  grid-row-gap: 32px;

  h1 h2 p {
    font-family: 'Open Sans';
    color: ${COLORS.WHITE};
  }
`;

export const Title = styled.h1`
  grid-column: span 12;
  font-size: 48px;
  font-weight: bold;
`;

export const Subtitle = styled.h2`
  grid-column: span 12;
  font-size: 36px;
`;

export const InterTitle = styled.p`
  grid-column: span 12;
  font-size: 24px;
`;

export const Form = styled.form`
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`;

export const WitchProfileText = styled.p`
  grid-column: span 6;
  font-size: 18px;
`;

export const RoundedButtonWrapper = styled.div`
  grid-column: span 3;
`;

export const IllegalInfoText = styled.p`
  grid-column: span 12;
  font-size: 14px;
  color: #999;
`;

export const useStyles = makeStyles({
  radioGroup: {
    gridColumn: 'span 6',
    display: 'grid',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridTemplateAreas: `
    'button-provider'
    'button-investor'`,
    marginBottom: '16px',

    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width: 780px)']: {
      gridTemplateRows: '1fr',
      gridTemplateColumns: 'repeat(2, max-content)',
      gridTemplateAreas: `'button-provider button-investor'`,
    },
  },
  providerButton: {
    gridArea: 'button-provider',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width: 780px)']: {
      marginRight: '24px',
    },
  },
  investorButton: {
    gridArea: 'button-investor',
  },
});
