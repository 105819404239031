/* eslint-disable no-useless-constructor */
import { ApiInstance, ApiResponse, getApiInstance } from '..';

import { InvoiceStatus } from '../../../Models';

import { Lot } from '../../../Redux/Lot/Entity';
import { Invoice } from '../../../Redux/Invoice/Entity';
import { EvaluateInvoiceRequest } from '../../../Redux/Invoice/actions';

interface Params {
  limited_at?: number;
  status?: InvoiceStatus;
}
export interface LotApi {
  retrieveLots({ limited_at, status }: Params): Promise<ApiResponse<Lot[]>>;
  uploadInvoice(
    invoices: File[],
    expectedRate: number,
  ): Promise<ApiResponse<void>>;
  evaluateInvoiceByLotIdAndInvoiceId(
    request: EvaluateInvoiceRequest,
  ): Promise<ApiResponse<void>>;
  retrieveInvoicesByLotId(
    id: string,
    page: number,
    status?: InvoiceStatus,
  ): Promise<ApiResponse<Invoice[]>>;
}

export class LotApiImpl implements LotApi {
  url = 'lots';

  constructor(private api: ApiInstance) {}

  retrieveLots({
    limited_at = 10000,
    status,
  }: Params): Promise<ApiResponse<Lot[]>> {
    return this.api.get(`${this.url}`, {
      limited_at,
      invoices: status,
    });
  }

  uploadInvoice(
    invoices: File[],
    expectedRate: number,
  ): Promise<ApiResponse<void>> {
    const formData = new FormData();
    invoices.forEach(invoice => formData.append('files', invoice));

    return this.api.post(`${this.url}/invoice`, formData, {
      params: {
        percentExpected: expectedRate,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  evaluateInvoiceByLotIdAndInvoiceId({
    lotId,
    invoiceId,
    ...body
  }: EvaluateInvoiceRequest): Promise<ApiResponse<void>> {
    return this.api.put(
      `${this.url}/${lotId}/invoices/${invoiceId}/evaluate`,
      body,
    );
  }

  retrieveInvoicesByLotId(
    id: string,
    page: number,
    status?: InvoiceStatus,
  ): Promise<ApiResponse<Invoice[]>> {
    return this.api.get(`${this.url}/${id}/invoices`, {
      page,
      filtered_by: status,
    });
  }
}

let instance: LotApi;

export function getLotApiInstance(api: ApiInstance): LotApi {
  if (!instance) {
    const myApi = api || getApiInstance(api);
    instance = new LotApiImpl(myApi);
  }
  return instance;
}
