import React from 'react';

import MaterialTabs from '@material-ui/core/Tabs';

import { Tab } from './styles';

interface Props {
  value: number;
  tabs: string[];
  onChange: (_: React.ChangeEvent<any>, newValue: number) => void;
}

const Tabs: React.FC<Props> = ({ value, tabs, onChange }: Props) => {
  return (
    <MaterialTabs value={value} onChange={onChange} indicatorColor="primary">
      {tabs.map(tab => (
        <Tab label={tab} key={tab} />
      ))}
    </MaterialTabs>
  );
};

export { Tabs };
