import React from 'react';
import { FormikProps } from 'formik';

import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import { Dialog } from '../../../../Components/Dialog';
import { DefaultButton } from '../../../../Components/Buttons';

import { Invoice } from '../../../../Redux/Invoice/Entity';

import { EvaluateValues } from '..';

import {
  ok,
  shipping,
  insurance,
  totalValue,
  productValue,
  opsHasAnError,
  formOfPayment,
  ufCapitalized,
  cepCapitalized,
  refuseCapitalized,
  approveCapitalized,
  failureEvaluatedInvoice,
  nameFirstLetterCapitalized,
  successfulEvaluatedInvoice,
  numberFirstLetterCapitalized,
  commentFirstLetterCapitalized,
  successFirstLetterCapitalized,
  addressFirstLetterCapitalized,
  complementFirstLetterCapitalized,
  neighborhoodFirstLetterCapitalized,
} from '../../../../Common/strings';

import * as S from './InvoiceEvaluateForm.styled';

interface Props {
  invoice: Invoice;
  errorMsg: string;
  isProvider: boolean;
  openDialog: boolean;
  formik: FormikProps<EvaluateValues>;
  onDialogClose: () => void;
  setIsApproved: (isApproved: boolean) => void;
}

const InvoiceEvaluateForm: React.FC<Props> = (props: Props) => {
  const classes = S.useStyles();

  const {
    formik,
    invoice,
    errorMsg,
    isProvider,
    openDialog,
    onDialogClose,
    setIsApproved,
  } = props;

  return (
    <S.Form onSubmit={formik.handleSubmit}>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDialog}
        error={!!errorMsg}
        confirmButtonText={ok}
        title={errorMsg ? opsHasAnError : successFirstLetterCapitalized}
        text={errorMsg ? failureEvaluatedInvoice : successfulEvaluatedInvoice}
        onClose={onDialogClose}
      />
      <S.Header isProvider={isProvider}>
        <S.HeaderTitle>Avaliação de NFe - </S.HeaderTitle>
        <S.HeaderSubtitle>{`${invoice.id}`}</S.HeaderSubtitle>
        <S.HeaderButtonsWrapper>
          <DefaultButton
            type="submit"
            isLoading={false}
            variant_type="primary"
            endIcon={<ThumbUpIcon />}
            text={approveCapitalized}
            disabled={invoice.status === 'invalid'}
            onClick={() => setIsApproved(true)}
          />
          <DefaultButton
            type="submit"
            variant_type="secondary"
            endIcon={<CloseIcon />}
            text={refuseCapitalized}
            disabled={invoice.status === 'invalid'}
            onClick={() => setIsApproved(false)}
          />
        </S.HeaderButtonsWrapper>
      </S.Header>
      <S.InfosWrapper>
        <S.Title>Informações da NFe</S.Title>
        <S.ValuesWrapper>
          <S.Label>{`${totalValue}:`}</S.Label>
          <S.Value>{`R$ ${invoice.value}`}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${productValue}:`}</S.Label>
          <S.Value>{`R$ ${invoice.productValue}`}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${shipping}:`}</S.Label>
          <S.Value>{`R$ ${invoice.shippingValue}`}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${formOfPayment}:`}</S.Label>
          <S.Value>{invoice.payment?.indicatorDetail}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>Natureza da operação:</S.Label>
          <S.Value>{invoice.operationNature}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${insurance}:`}</S.Label>
          <S.Value>{`R$ ${invoice.insurance}`}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>Data de emissão:</S.Label>
          <S.Value>{invoice.emissionDate}</S.Value>
        </S.ValuesWrapper>
      </S.InfosWrapper>
      <S.InfosWrapper>
        <S.Title>Informações do sacado</S.Title>
        <S.ValuesWrapper>
          <S.Label>{`${nameFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.name}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${cepCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.address.cep}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${addressFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.address.street}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${numberFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.address.number}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${complementFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.address.complement}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${neighborhoodFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.address.neighborhood}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${ufCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.address.uf}</S.Value>
        </S.ValuesWrapper>
      </S.InfosWrapper>
      <S.InfosWrapper>
        <S.Title>Informações do destinatário</S.Title>
        <S.ValuesWrapper>
          <S.Label>Destinatário:</S.Label>
          <S.Value>{invoice.receiver?.name}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${cepCapitalized}:`}</S.Label>
          <S.Value>{invoice.receiver?.address.cep}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${addressFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.receiver?.address.street}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${numberFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.receiver?.address.number}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${complementFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.receiver?.address.complement}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${neighborhoodFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.receiver?.address.neighborhood}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${ufCapitalized}:`}</S.Label>
          <S.Value>{invoice.receiver?.address.uf}</S.Value>
        </S.ValuesWrapper>
      </S.InfosWrapper>
      <TextField
        rows={4}
        multiline
        id="comment"
        variant="outlined"
        onChange={formik.handleChange}
        value={formik.values.comment}
        label={commentFirstLetterCapitalized}
        className={classes.commentsTextArea}
        helperText={formik.touched.comment && formik.errors.comment}
        error={formik.touched.comment && Boolean(formik.errors.comment)}
      />
    </S.Form>
  );
};

export { InvoiceEvaluateForm };
