import { combineReducers } from 'redux';

import {
  sellingOrderRetrieveReducer,
  SellingOrderRetrieveState,
} from './Retrieve';

export interface SellingOrderCrudState {
  retrieve: SellingOrderRetrieveState;
}

const reducer = combineReducers<SellingOrderCrudState>({
  retrieve: sellingOrderRetrieveReducer,
});

export * from './Entity';
export * from './Retrieve';

export { reducer as crudSellingOrderReducer };
