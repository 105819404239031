import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  InvoiceEntitySelector,
  InvoicePatchSelectors,
} from '../../../Redux/Invoice/selectors';
import { AppState } from '../../../Redux';
import { Invoice } from '../../../Redux/Invoice/Entity';
import { AuthSelectors } from '../../../Redux/Auth/selectors';
import { InvoicePatchActionsCreators } from '../../../Redux/Invoice/actions';

import { InvoiceEvaluateForm } from './Components';

import { requiredComment } from '../../../Common/strings';

interface LocationState {
  lotId: string;
  invoiceId: string;
}

export interface EvaluateValues {
  comment: string;
  isApproved: boolean;
}

const InvoiceEvaluatePage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation<LocationState>();
  const { invoiceId, lotId } = state;
  const isProvider = useSelector(AuthSelectors.selectIsProvider);
  const isDone = useSelector(InvoicePatchSelectors.selectIsDone);
  const errorMsg = useSelector(InvoicePatchSelectors.selectErrorMsg);

  useEffect(() => {
    if (isDone && !errorMsg) {
      setOpen(true);
    }
  }, [isDone, errorMsg]);

  const handleDialogClose = (): void => {
    setOpen(false);
    history.push(`/backoffice/lotes/${lotId}/notas`);
    dispatch(InvoicePatchActionsCreators.invoiceEvaluateInitialState());
  };

  const selectInvoiceById = (
    appState: AppState,
    id: string,
  ): Invoice | undefined => {
    return InvoiceEntitySelector.selectInvoiceById(appState, id);
  };

  const invoice = useSelector((appState: AppState) =>
    selectInvoiceById(appState, invoiceId),
  );

  const initialValues: EvaluateValues = {
    comment: invoice?.comments ? invoice.comments : '',
    isApproved: false,
  };

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required(requiredComment),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ comment, isApproved }) => handleSubmit(comment, isApproved),
  });

  const handleSubmit = (comment: string, isApproved: boolean): void => {
    dispatch(
      InvoicePatchActionsCreators.invoiceEvaluateByLotIdAndInvoiceIdRequest({
        lotId,
        invoiceId,
        comments: comment,
        is_approved: isApproved,
      }),
    );
  };

  const setIsApproved = (isApproved: boolean): void => {
    formik.values.isApproved = isApproved;
  };

  return invoice ? (
    <InvoiceEvaluateForm
      formik={formik}
      openDialog={open}
      invoice={invoice}
      errorMsg={errorMsg}
      isProvider={isProvider}
      setIsApproved={setIsApproved}
      onDialogClose={handleDialogClose}
    />
  ) : (
    <h1>Error</h1>
  );
};

export { InvoiceEvaluatePage };
