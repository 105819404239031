import { createStyles, makeStyles } from '@material-ui/core/styles';

import { COLORS } from '../../Styles/themes';

export const useStyles = makeStyles(() =>
  createStyles({
    appBar: {
      height: 64,
      background: COLORS.BLUE,
    },
  }),
);
