import { AuthActions, AuthTypes } from './actions';

import { User } from '../User/Entity';

export interface AuthState {
  isLogged: boolean;
  isLogging: boolean;
  errorMsg: string;
  user?: User;
  authToken?: string;
}

const initialState: AuthState = {
  isLogged: false,
  isLogging: false,
  errorMsg: '',
};

export function authReducer(
  state = initialState,
  action: AuthActions,
): AuthState {
  switch (action.type) {
    case AuthTypes.SIGN_IN_REQUEST: {
      return {
        isLogged: false,
        isLogging: true,
        errorMsg: '',
      };
    }
    case AuthTypes.SIGN_IN_SUCCESS: {
      const { authToken, user } = action.payload;

      return {
        user,
        authToken,
        isLogged: true,
        isLogging: false,
        errorMsg: '',
      };
    }
    case AuthTypes.SIGN_IN_FAILURE: {
      const errorMsg = action.payload;

      return {
        errorMsg,
        isLogging: false,
        isLogged: false,
      };
    }
    case AuthTypes.LOGOUT_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
}
