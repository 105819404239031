import { combineReducers } from 'redux';

import { LotRetrieveAllState, lotRetrieveAllReducer } from './all';

export interface LotRetrieveState {
  all: LotRetrieveAllState;
}

const reducer = combineReducers<LotRetrieveState>({
  all: lotRetrieveAllReducer,
});

export { reducer as lotRetrieveReducer };
