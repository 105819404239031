import { RequestState } from '../../../Services/Api/models';

import { InvoiceTypes } from '../types';
import { InvoiceActions } from '../actions';

export type InvoicePatchState = RequestState;

const initialState: InvoicePatchState = {
  isLoading: false,
  isDone: false,
  hasError: false,
  errorMsg: '',
};

export function invoicePatchReducer(
  state = initialState,
  action: InvoiceActions,
): InvoicePatchState {
  switch (action.type) {
    case InvoiceTypes.EVALUATE_INVOICE_BY_ID_REQUEST: {
      return {
        errorMsg: '',
        isDone: false,
        isLoading: true,
        hasError: false,
      };
    }
    case InvoiceTypes.EVALUATE_INVOICE_BY_ID_SUCCESS: {
      return {
        isDone: true,
        errorMsg: '',
        hasError: false,
        isLoading: false,
      };
    }
    case InvoiceTypes.EVALUATE_INVOICE_BY_ID_FAILURE: {
      const errorMsg = action.payload;
      return {
        errorMsg,
        isDone: true,
        hasError: true,
        isLoading: false,
      };
    }
    case InvoiceTypes.EVALUATE_INVOICE_INITIAL_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
