import { RequestState } from '../../../Services/Api/models';

import { AuctionItemsTypes } from '../types';
import { AuctionItemCreateActions } from '../actions';

export type AuctionItemCreateState = RequestState;

const initialState: AuctionItemCreateState = {
  errorMsg: '',
  isDone: false,
  hasError: false,
  isLoading: false,
};

export function auctionItemCreateReducer(
  state = initialState,
  action: AuctionItemCreateActions,
): AuctionItemCreateState {
  switch (action.type) {
    case AuctionItemsTypes.CREATE_AUCTION_ITEM_REQUEST: {
      return {
        isLoading: true,
        isDone: false,
        hasError: false,
        errorMsg: '',
      };
    }
    case AuctionItemsTypes.CREATE_AUCTION_ITEM_SUCCESS: {
      return {
        isLoading: false,
        isDone: true,
        hasError: false,
        errorMsg: '',
      };
    }
    case AuctionItemsTypes.CREATE_AUCTION_ITEM_FAILURE: {
      return {
        isLoading: false,
        isDone: true,
        hasError: true,
        errorMsg: action.payload,
      };
    }
    default:
      return state;
  }
}
