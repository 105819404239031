import { RequestState } from '../../../Services/Api/models';

import { InvoiceTypes } from '../types';
import { InvoiceActions } from '../actions';

export type InvoiceRetrieveAllState = RequestState;

const initialState: InvoiceRetrieveAllState = {
  isLoading: false,
  isDone: false,
  hasError: false,
  errorMsg: '',
};

export function invoiceRetrieveAllReducer(
  state = initialState,
  action: InvoiceActions,
): InvoiceRetrieveAllState {
  switch (action.type) {
    case InvoiceTypes.RETRIEVE_INVOICES_BY_LOT_ID_REQUEST: {
      return {
        errorMsg: '',
        isDone: false,
        isLoading: true,
        hasError: false,
      };
    }
    case InvoiceTypes.RETRIEVE_INVOICES_BY_LOT_ID_SUCCESS: {
      return {
        isDone: true,
        errorMsg: '',
        hasError: false,
        isLoading: false,
      };
    }
    case InvoiceTypes.RETRIEVE_INVOICES_BY_LOT_ID_FAILURE: {
      const errorMsg = action.payload;
      return {
        errorMsg,
        isDone: true,
        hasError: true,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
