import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    height: 36,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Roboto Slab',
  },
});
