import styled from 'styled-components';

import { Props } from '.';

export const Button = styled.button<Props>`
  padding: 8px;
  border-radius: 4px;
  font-family: 'Roboto Slab';
  text-transform: 'uppercase';
  font-size: var(--font-size-base);
  color: ${({ variant }) =>
    variantIsPrimary(variant) ? 'var(--color--white)' : 'var(--color-gray)'};
  border: ${({ variant }) =>
    variantIsPrimary(variant) ? '1px solid var(--color-light-blue)' : 'none'};
  background: ${({ variant }) =>
    variantIsPrimary(variant) ? 'var(--color-light-blue)' : 'transparent'};

  &:hover {
    opacity: 0.7;
  }
`;

const variantIsPrimary = (variant: 'primary' | 'secondary'): boolean =>
  variant === 'primary';
