import { combineReducers } from 'redux';

import {
  AuctionItemRetrieveAllState,
  auctionItemRetrieveAllReducer,
} from './all';

export interface AuctionItemRetrieveState {
  all: AuctionItemRetrieveAllState;
}

const reducer = combineReducers<AuctionItemRetrieveState>({
  all: auctionItemRetrieveAllReducer,
});

export { reducer as auctionItemRetrieveReducer };
