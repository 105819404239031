import { Entity } from '../../models';

import { LotTypes } from '../types';
import { LotActions } from '../actions';

export interface Lot extends Entity {
  status: string;
  owner_lot_id: string;
  total_invoices: string;
  owner_lot_name: string;
  total_value_lot: number;
  processingQuantity: number;
  total_refused_invoices: number;
  total_pending_invoices: number;
  total_invalid_invoices: number;
  total_approved_invoices: number;
  return_percent_expected: number;
}

export interface LotEntityState {
  lots: Lot[];
}

const initialState: LotEntityState = {
  lots: [],
};

export function lotEntityReducer(
  state = initialState,
  action: LotActions,
): LotEntityState {
  switch (action.type) {
    case LotTypes.RETRIEVE_LOTS_SUCCESS: {
      const lots = action.payload;
      return { ...state, lots };
    }
    default:
      return state;
  }
}
