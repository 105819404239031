import React from 'react';
import GavelIcon from '@material-ui/icons/Gavel';

import { DefaultButton } from '../../../../../Components/Buttons/DefaultButton';
import { TableToolbarSelect } from '../../../../../Components/TableToolbarSelect';

interface Props {
  onAuctionClick: (selectedIndex: number[] | undefined) => void;
  selectedRows?: {
    data: {
      index: number;
      dataIndex: number;
    }[];
    lookup: any;
  };
}

export const CustomTableToolbarSelect: React.FC<Props> = (props: Props) => {
  const { onAuctionClick, selectedRows, ...rest } = props;

  return (
    <TableToolbarSelect
      text="nota(s) selecionada(s)"
      selectedRows={selectedRows}
      {...rest}
    >
      <DefaultButton
        endIcon={<GavelIcon />}
        variant_type="primary"
        text="Leiloar"
        onClick={
          () => onAuctionClick(selectedRows?.data.map(({ index }) => index))
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
    </TableToolbarSelect>
  );
};
