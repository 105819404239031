import { all, AllEffect, ForkEffect, takeLatest } from 'redux-saga/effects';
import { Apis } from '../Services';

import { AuctionItemsTypes } from '../Redux/AuctionItem/types';
import {
  giveBid,
  createAuctionItem,
  favoriteAuctionItem,
  unfavoriteAuctionItem,
  retrieveAllAuctionItem,
  retrieveAllBiddedAuctions,
  retrieveAllFavoriteAuctionItem,
} from './Auction';

import { LotTypes } from '../Redux/Lot/types';
import { lotPost, lotRetrieveAll } from './Lot';

import { AuthTypes } from '../Redux/Auth/actions';
import { signIn, logout } from './Auth';

import { AddressTypes } from '../Redux/Address/types';
import { addressRetrieveOneSaga } from './Address';

import { UserTypes } from '../Redux/User/types';
import { userCreateSaga, userRetrieveAll } from './User';

import { InvoiceTypes } from '../Redux/Invoice/types';
import { lotInvoiceRetrieveByLotId, lotInvoiceEvaluateById } from './Invoice';

import { parameterRetrieveAll } from './Parameter';
import { ParameterTypes } from '../Redux/Parameter/types';

import { evaluationCreate } from './Evaluation';
import { EvaluationTypes } from '../Redux/Evaluation/types';

import { retrieveAllSellingOrder } from './SellingOrder';
import { SellingOrderTypes } from '../Redux/SellingOrder/types';

export function* rootSaga(
  apis: Apis,
): Generator<ForkEffect<never> | AllEffect<unknown>> {
  yield all([
    takeLatest(AuctionItemsTypes.GIVE_BID_REQUEST, giveBid, apis.auction),
    takeLatest(
      AuctionItemsTypes.RETRIEVE_ALL_BIDDED_AUCTION_ITEM_REQUEST,
      retrieveAllBiddedAuctions,
      apis.user,
    ),
    takeLatest(
      SellingOrderTypes.RETRIEVE_ALL_SELLING_ORDER_REQUEST,
      retrieveAllSellingOrder,
      apis.sellingOrder,
    ),
    takeLatest(
      AuctionItemsTypes.FAVORITE_AUCTION_ITEM_REQUEST,
      favoriteAuctionItem,
      apis.auction,
    ),
    takeLatest(
      AuctionItemsTypes.UNFAVORITE_AUCTION_ITEM_REQUEST,
      unfavoriteAuctionItem,
      apis.auction,
    ),
    takeLatest(
      AuctionItemsTypes.RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_REQUEST,
      retrieveAllFavoriteAuctionItem,
      apis.user,
    ),
    takeLatest(
      AuctionItemsTypes.RETRIEVE_ALL_AUCTION_ITEM_REQUEST,
      retrieveAllAuctionItem,
      apis.auction,
    ),
    takeLatest(
      AuctionItemsTypes.CREATE_AUCTION_ITEM_REQUEST,
      createAuctionItem,
      apis.auction,
    ),
    takeLatest(
      AddressTypes.RETRIEVE_ONE_ADDRESS_REQUEST,
      addressRetrieveOneSaga,
      apis.address,
    ),
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn, apis.auth),
    takeLatest(AuthTypes.LOGOUT_SUCCESS, logout, apis.auth),
    takeLatest(UserTypes.CREATE_USER_REQUEST, userCreateSaga, apis.auth),
    takeLatest(
      UserTypes.RETRIEVE_ALL_USERS_REQUEST,
      userRetrieveAll,
      apis.user,
    ),
    takeLatest(LotTypes.CREATE_LOT_REQUEST, lotPost, apis.lot),
    takeLatest(LotTypes.RETRIEVE_LOTS_REQUEST, lotRetrieveAll, apis.lot),
    takeLatest(
      InvoiceTypes.RETRIEVE_INVOICES_BY_LOT_ID_REQUEST,
      lotInvoiceRetrieveByLotId,
      apis.lot,
    ),
    takeLatest(
      InvoiceTypes.EVALUATE_INVOICE_BY_ID_REQUEST,
      lotInvoiceEvaluateById,
      apis.lot,
    ),
    takeLatest(
      ParameterTypes.RETRIEVE_PARAMETERS_REQUEST,
      parameterRetrieveAll,
      apis.parameter,
    ),
    takeLatest(
      EvaluationTypes.CREATE_EVALUATION_REQUEST,
      evaluationCreate,
      apis.user,
    ),
  ]);
}
