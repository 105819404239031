import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { makeStyles } from '@material-ui/core/styles';

import { ProviderUpload } from './Components';
import { Logo } from '../../../Components/Sidebar/styles';

import { COLORS } from '../../../Styles/themes';

import {
  profileFirstLetterCapitalized,
  providerFirstLetterCapitalized,
} from '../../../Common/strings';

const ProviderUploadEvaluatePage: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.titleContainer}>
            <Logo />
            <span className={classes.text}>
              {providerFirstLetterCapitalized}
            </span>
          </div>
          <IconButton
            aria-label={profileFirstLetterCapitalized}
            className={classes.icon}
          >
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ProviderUpload />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    height: '100vh',
  },
  appbar: {
    height: '64px',
    display: 'flex',
    padding: '0px 42px',
    background: COLORS.BLUE,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    color: COLORS.WHITE,
  },
  text: {
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    fontSize: 20,
    marginLeft: 42,
  },
});

export { ProviderUploadEvaluatePage };
