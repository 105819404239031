import { call, put } from 'redux-saga/effects';
import { format } from 'date-fns';

import { UserApi } from '../../Services/Api/User';
import { AuctionApi } from '../../Services/Api/Auction';

import {
  AuctionItemPutActionsCreators,
  AuctionItemCreateActionsCreators,
  AuctionItemRetrieveActionsCreators,
} from '../../Redux/AuctionItem/actions';
import { InvoiceRetrieveActionsCreators } from '../../Redux/Invoice/actions';

import { Invoice } from '../../Redux/Invoice/Entity';
import { AuctionItem } from '../../Redux/AuctionItem/Entity';

export function* retrieveAllAuctionItem(
  api: AuctionApi,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: ReturnType<
    typeof AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllRequest
  >,
): any {
  const { ok, data, problem } = yield call([api, api.retrieveAuctionItems]);

  if (ok) {
    yield put(
      AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllSuccess(
        formatResponseAuctionItems(data.data),
      ),
    );

    const invoices: Invoice[] = getAuctionItemInvoices(data.data);

    yield put(
      InvoiceRetrieveActionsCreators.invoiceRetrieveByAuctionItemSuccess(
        invoices,
      ),
    );
  } else {
    const message = data && data.message ? data.message : `${problem}`;

    yield put(
      AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllFailure(message),
    );
  }
}

export function* createAuctionItem(
  api: AuctionApi,
  action: ReturnType<
    typeof AuctionItemCreateActionsCreators.auctionItemCreateRequest
  >,
): any {
  const request = action.payload;
  const { ok, data, problem } = yield call(
    [api, api.createAuctionItem],
    request,
  );

  if (ok) {
    yield put(AuctionItemCreateActionsCreators.auctionItemCreateSuccess());
  } else {
    const message = data && data.message ? data.message : `${problem}`;
    yield put(
      AuctionItemCreateActionsCreators.auctionItemCreateFailure(message),
    );
  }
}

export function* retrieveAllFavoriteAuctionItem(
  api: UserApi,
  action: ReturnType<
    typeof AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllFavoriteRequest
  >,
): any {
  const userId = action.payload;

  const { ok, data, problem } = yield call(
    [api, api.retrieveFavoriteAuctionItems],
    userId,
  );

  if (ok) {
    const auctionItems = formatResponseAuctionItems(data.data);
    const favoriteAuctionItems = auctionItems.map(auctionItem => ({
      ...auctionItem,
      isFavorite: true,
    }));

    yield put(
      AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllFavoriteSuccess(
        favoriteAuctionItems,
      ),
    );

    const invoices: Invoice[] = getAuctionItemInvoices(data.data);

    yield put(
      InvoiceRetrieveActionsCreators.invoiceRetrieveByAuctionItemSuccess(
        invoices,
      ),
    );
  } else {
    const message = data && data.message ? data.message : `${problem}`;

    yield put(
      AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllFavoriteFailure(
        message,
      ),
    );
  }
}

export function* favoriteAuctionItem(
  api: AuctionApi,
  action: ReturnType<
    typeof AuctionItemPutActionsCreators.auctionItemFavoriteRequest
  >,
): any {
  const auctionItemId = action.payload;

  const { ok, data, problem } = yield call(
    [api, api.favoriteAuctionItemById],
    auctionItemId,
  );

  if (ok) {
    yield put(
      AuctionItemPutActionsCreators.auctionItemFavoriteSuccess(auctionItemId),
    );
  } else {
    const message = data && data.message ? data.message : `${problem}`;

    console.error(`[auction_item] FAVORITE_AUCTION_ITEM_FAILURE: ${message}`);

    yield put(
      AuctionItemPutActionsCreators.auctionItemFavoriteFailure(
        'Não foi possível favoritar este leilão. Tente novamente mais tarde.',
      ),
    );
  }
}

export function* unfavoriteAuctionItem(
  api: AuctionApi,
  action: ReturnType<
    typeof AuctionItemPutActionsCreators.auctionItemUnfavoriteRequest
  >,
): any {
  const auctionItemId = action.payload;

  const { ok, data, problem } = yield call(
    [api, api.unfavoriteAuctionItemById],
    auctionItemId,
  );

  if (ok) {
    yield put(
      AuctionItemPutActionsCreators.auctionItemUnfavoriteSuccess(auctionItemId),
    );
  } else {
    const message = data && data.message ? data.message : `${problem}`;

    console.error(`[auction_item] UNFAVORITE_AUCTION_ITEM_FAILURE: ${message}`);

    yield put(
      AuctionItemPutActionsCreators.auctionItemUnfavoriteFailure(
        'Não foi possível desfavoritar este leilão. Tente novamente mais tarde.',
      ),
    );
  }
}

export function* giveBid(
  api: AuctionApi,
  action: ReturnType<typeof AuctionItemPutActionsCreators.giveBidRequest>,
): any {
  const { auctionItemId, bid } = action.payload;

  const { ok, data, problem } = yield call([api, api.giveBid], {
    auctionItemId,
    bid,
  });

  if (ok) {
    yield put(AuctionItemPutActionsCreators.giveBidSuccess(action.payload));
  } else {
    const message = data && data.message ? data.message : `${problem}`;

    console.error(`[auction_item] GIVE_BID_FAILURE: ${message}`);

    yield put(
      AuctionItemPutActionsCreators.giveBidFailure(
        message ||
          'Não foi possível dar um lance neste lote. Tente novamente mais tarde.',
      ),
    );
  }
}

export function* retrieveAllBiddedAuctions(
  api: UserApi,
  action: ReturnType<
    typeof AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllBiddedRequest
  >,
): any {
  const userId = action.payload;

  const { ok, data, problem } = yield call(
    [api, api.retrieveAllBiddedAuctions],
    userId,
  );

  if (ok) {
    const responseItems = data.data.map(({ item, amIWinning, myBid }: any) => ({
      ...item,
      myBid,
      amIWinning,
    }));

    const auctionItems = formatResponseAuctionItems(responseItems);

    yield put(
      AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllBiddedSuccess(
        auctionItems,
      ),
    );

    const invoices: Invoice[] = getAuctionItemInvoices(responseItems);

    yield put(
      InvoiceRetrieveActionsCreators.invoiceRetrieveByAuctionItemSuccess(
        invoices,
      ),
    );
  } else {
    const message = data && data.message ? data.message : `${problem}`;

    console.error(`[auction_item] RETRIEVE_ALL_BIDDED_AUCTIONS: ${message}`);

    yield put(
      AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllBiddedFailure(
        message ||
          'Não foi possível recuperar os leilões. Tente novamente mais tarde.',
      ),
    );
  }
}

const formatResponseAuctionItems = (auctionItems: any): AuctionItem[] => {
  return auctionItems.map((auctionItem: any) => {
    const { invoices, ...rest } = auctionItem;
    return {
      ...rest,
      invoices: invoices.map((invoice: any) => {
        const { encoded, ...rest } = invoice;
        return {
          ...rest,
          encoded: {
            ...encoded,
            invoice: {
              ...encoded.invoice,
              emissionDate: format(
                new Date(encoded.invoice.emissionDate),
                'dd/MM/yyyy HH:mm',
              ),
            },
          },
        };
      }),
    };
  });
};

const getAuctionItemInvoices = (auctionItems: AuctionItem[]): Invoice[] => {
  const auctionItemsInvoices = auctionItems.flatMap(
    (auctionItem: any) => auctionItem.invoices,
  );

  return auctionItemsInvoices.map(
    ({
      _id,
      status,
      comments,
      approved,
      invoiceId,
      encoded: { invoice, receiver, emitter },
    }: any) => ({
      id: _id,
      status,
      emitter,
      comments,
      receiver,
      invoiceId,
      is_valid: true,
      is_approve: approved,
      value: invoice.value,
      payment: invoice.payment,
      docNumber: invoice.docNumber,

      operationNature: invoice.operationNature,
      productValue: invoice.totalValues.productValue,
      insurance: invoice.totalValues.insuranceValue,
      shippingValue: invoice.totalValues.shippingValue,
      emissionDate: format(new Date(invoice.emissionDate), 'dd/MM/yyyy HH:mm'),
    }),
  );
};
