import { RequestState } from '../../../Services/Api/models';

import { LotTypes } from '../types';
import { LotActions } from '../actions';

export type LotRetrieveAllState = RequestState;

const initialState: LotRetrieveAllState = {
  isLoading: false,
  isDone: false,
  hasError: false,
  errorMsg: '',
};

export function lotRetrieveAllReducer(
  state = initialState,
  action: LotActions,
): LotRetrieveAllState {
  switch (action.type) {
    case LotTypes.RETRIEVE_LOTS_REQUEST: {
      return {
        errorMsg: '',
        isDone: false,
        isLoading: true,
        hasError: false,
      };
    }
    case LotTypes.RETRIEVE_LOTS_SUCCESS: {
      return {
        isDone: true,
        errorMsg: '',
        hasError: false,
        isLoading: false,
      };
    }
    case LotTypes.RETRIEVE_LOTS_FAILURE: {
      const errorMsg = action.payload;
      return {
        errorMsg,
        isDone: true,
        hasError: true,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
