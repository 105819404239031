import { SellingOrderTypes } from '../types';
import { SellingOrderActions } from '../actions';

export type SellingOrder = {
  _id: string;
  status: string;
  item: {
    totalValue: number;
  };
  winner: {
    full_name: string;
    cpf?: string;
  };
  owner: {
    full_name: string;
    cnpj?: string;
  };
  bid: {
    value: number;
  };
  createdAt?: Date;
  updatedAt?: Date;
  total_invoices: number;
};

export type SellingOrderResponse = SellingOrder[];

export type SellingOrderEntityState = {
  sellingOrders: SellingOrder[];
};

const initialState: SellingOrderEntityState = {
  sellingOrders: [],
};

export function sellingOrderEntityReducer(
  state = initialState,
  action: SellingOrderActions,
): SellingOrderEntityState {
  switch (action.type) {
    case SellingOrderTypes.RETRIEVE_ALL_SELLING_ORDER_SUCCESS: {
      const sellingOrders = action.payload;
      return { ...state, sellingOrders };
    }
    default:
      return state;
  }
}
