import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { TextButton } from '../../../../Components/Buttons';
import { Dropzone, DROPZONE_MAX_FILES } from '../../../../Components/Dropzone';

import { COLORS } from '../../../../Styles/themes';

import {
  ListTitle,
  DropzoneContainer,
  ProviderUploadContainer,
  ProviderUploadTextContainer,
} from './styles';

import {
  renameTheDocuments,
  welcomeToSecondStep,
  necessaryDocumentation,
  makeUploadOfRequiredFiles,
  cancelFirstLetterCapitalized,
  finishFirstLetterCapitalized,
} from '../../../../Common/strings';

const ProviderUpload: React.FC = () => {
  const [files, setFiles] = React.useState<File[]>([]);
  const classes = useStyles();

  const differenceBetweenArrays = useCallback(
    (arrA: File[], arrB: File[]) =>
      arrA.filter(
        (itemA: File) => !arrB.some((itemB: File) => itemB.name === itemA.name),
      ),
    [],
  );

  const hasReachedLimitOfFiles = useCallback(
    (acceptedFiles: File[]) =>
      files.length + acceptedFiles.length > DROPZONE_MAX_FILES,
    [files.length],
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (hasReachedLimitOfFiles(acceptedFiles)) {
        return;
      }

      const difference = differenceBetweenArrays(acceptedFiles, files);

      setFiles([...files, ...difference]);
    },
    [differenceBetweenArrays, files, hasReachedLimitOfFiles],
  );

  const handleDeleteFile = useCallback(
    (file: File) => {
      const filteredFiles = files.filter(item => item.name !== file.name);
      setFiles(filteredFiles);
    },
    [files],
  );

  return (
    <ProviderUploadContainer>
      <ProviderUploadTextContainer>
        <h1>{welcomeToSecondStep}</h1>
        <h2>{makeUploadOfRequiredFiles}</h2>
        <h3>{renameTheDocuments}</h3>

        <ListTitle>{`${necessaryDocumentation}:`}</ListTitle>
        <ul>
          <li>Documento 1</li>
          <li>Documento 2</li>
          <li>Documento 3</li>
        </ul>
      </ProviderUploadTextContainer>
      <DropzoneContainer>
        <Dropzone
          files={files}
          onDrop={handleDrop}
          onDeleteClick={handleDeleteFile}
        />
        <div className={classes.buttonContainer}>
          <Button className={classes.finishButton}>
            {finishFirstLetterCapitalized}
          </Button>
          <TextButton title={cancelFirstLetterCapitalized} />
        </div>
      </DropzoneContainer>
    </ProviderUploadContainer>
  );
};

const useStyles = makeStyles({
  finishButton: {
    color: COLORS.WHITE,
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    fontSize: '14px',
    marginRight: '56px',
    background: '#2C98F0',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '80px',
    width: '658px',
  },
});

export { ProviderUpload };
