import React from 'react';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';

import { AppState } from '../../../../Redux';
import { Invoice } from '../../../../Redux/Invoice/Entity';
import { AuthSelectors } from '../../../../Redux/Auth/selectors';
import { InvoiceEntitySelector } from '../../../../Redux/Invoice/selectors';

import { DefaultButton } from '../../../../Components/Buttons';

import {
  analyzeCapitalized,
  evaluateCapitalized,
} from '../../../../Common/strings';

interface Props {
  id: string;
  handleEvaluateClick: () => void;
}

const InvoiceTableActionsColumn: React.FC<Props> = (props: Props) => {
  const { id, handleEvaluateClick } = props;

  const selectInvoiceById = (
    appState: AppState,
    id: string,
  ): Invoice | undefined => {
    return InvoiceEntitySelector.selectInvoiceById(appState, id);
  };

  const invoice = useSelector((appState: AppState) =>
    selectInvoiceById(appState, id),
  );

  const isProvider = useSelector(AuthSelectors.selectIsProvider);

  const setText = (): string => {
    return invoice?.status === 'invalid' || isProvider
      ? analyzeCapitalized
      : evaluateCapitalized;
  };

  return (
    <DefaultButton
      endIcon={<SearchIcon />}
      variant_type="primary"
      text={setText()}
      onClick={handleEvaluateClick}
    />
  );
};

export { InvoiceTableActionsColumn };
