import { ParameterTypes } from '../types';
import { Entity, ROLE } from '../../models';
import { ParameterActions } from '../actions';
import { ParameterOption } from '../../ParameterOption';

export interface Parameter extends Entity {
  name: string;
  selected?: number;
  parameter?: string;
  options: ParameterOption[];
  role?: ROLE.INVESTOR | ROLE.PROVIDER;
}

export interface ParameterEntityState {
  parameters: Parameter[];
}

const initialState: ParameterEntityState = {
  parameters: [],
};

export function parameterEntityReducer(
  state = initialState,
  action: ParameterActions,
): ParameterEntityState {
  switch (action.type) {
    case ParameterTypes.RETRIEVE_PARAMETERS_SUCCESS: {
      const parameters = action.payload;
      return { ...state, parameters };
    }
    default:
      return state;
  }
}
