/* eslint-disable no-useless-constructor */
import { ApiInstance, ApiResponse, getApiInstance } from '..';
import { User } from '../../../Redux/User/Entity';
import { UserRetrieveRequest } from '../../../Redux/User/actions';
import { AuctionItem } from '../../../Redux/AuctionItem/Entity';

export interface EvaluateParameters {
  id: string;
  selected: string;
}

export interface EvaluateUserRequest {
  approved: boolean;
  comment: string;
  parameters: EvaluateParameters[];
}
export interface UserApi {
  evaluateUser(
    userId: string,
    request: EvaluateUserRequest,
  ): Promise<ApiResponse<void>>;
  getUsersByRole(request: UserRetrieveRequest): Promise<ApiResponse<User[]>>;
  retrieveAllBiddedAuctions(
    userId: string,
  ): Promise<ApiResponse<AuctionItem[]>>;
  retrieveFavoriteAuctionItems(
    userId: string,
  ): Promise<ApiResponse<AuctionItem[]>>;
}

export class UserApiImpl implements UserApi {
  url = 'users';

  constructor(private api: ApiInstance) {}

  retrieveAllBiddedAuctions(
    userId: string,
  ): Promise<ApiResponse<AuctionItem[]>> {
    return this.api.get(`${this.url}/${userId}/items/bidded`);
  }

  getUsersByRole(request: UserRetrieveRequest): Promise<ApiResponse<User[]>> {
    const { role_name, filter_by, limit, page } = request;
    return this.api.get(`${this.url}/${role_name}`, {
      filter_by,
      limit,
      page,
    });
  }

  evaluateUser(
    userId: string,
    request: EvaluateUserRequest,
  ): Promise<ApiResponse<void>> {
    return this.api.post(`${this.url}/${userId}/evaluate`, request);
  }

  retrieveFavoriteAuctionItems(
    userId: string,
  ): Promise<ApiResponse<AuctionItem[]>> {
    return this.api.get(`${this.url}/${userId}/favorites`);
  }
}

let instance: UserApi;

export function getUserApiInstance(api: ApiInstance): UserApi {
  if (!instance) {
    const myApi = api || getApiInstance();
    instance = new UserApiImpl(myApi);
  }

  return instance;
}
