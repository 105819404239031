import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import MaterialAppBar from '@material-ui/core/AppBar';

import { useStyles } from './styles';

const AppBar: React.FC = () => {
  const classes = useStyles();

  return (
    <MaterialAppBar position="fixed" className={classes.appBar}>
      <Toolbar />
    </MaterialAppBar>
  );
};

export { AppBar };
