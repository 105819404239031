import React from 'react';

import * as S from './Score.styled';

interface Props {
  score: number;
}

const Score: React.FC<Props> = (props: Props) => {
  const { score, ...rest } = props;
  return (
    <S.Container {...rest}>
      <S.Value>{score}</S.Value>
    </S.Container>
  );
};

export { Score };
