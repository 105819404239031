import React from 'react';

import { Lot as LotEntity } from '../../../../Redux/Lot/Entity';

import { Lot } from './Lot';
import { EmptyState } from '../../Components';
import { Loading } from '../../../../Components/Loading';

import * as S from './Styles/LotList.styled';

import { emptyEvaluateLots } from '../../../../Common/strings';

interface Props {
  lots: LotEntity[];
  isLoading: boolean;
}

const LotList: React.FC<Props> = ({ lots, isLoading }: Props) => {
  return isLoading ? (
    <Loading />
  ) : (
    <>
      {lots.length > 0 ? (
        <S.Wrapper>
          {lots.map(lot => (
            <Lot key={lot.id} lot={lot} />
          ))}
        </S.Wrapper>
      ) : (
        <EmptyState message={emptyEvaluateLots} />
      )}
    </>
  );
};

export { LotList };
