import React from 'react';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import * as S from './index.styled';

interface Props {
  isFilled: boolean;
}

export const StarIcon: React.FC<Props> = ({ isFilled }: Props) => {
  return isFilled ? <S.FilledStar /> : <StarBorderIcon />;
};
