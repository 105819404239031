import { AppState } from '..';
import { AddressCommon } from './Entity';
import { RequestState } from '../../Services/Api/models';

const AddressRequestSelector = {
  selectState: ({ requests: { address } }: AppState) => address,
};

const AddressRetrieveSelectors = {
  selectState: (appState: AppState) => {
    const { retrieve } = AddressRequestSelector.selectState(appState);
    return retrieve;
  },
};

const AddressRetrieveOneSelectors = {
  selectState: (appState: AppState): RequestState => {
    const { one } = AddressRetrieveSelectors.selectState(appState);
    return one;
  },
  selectIsLoading: (appState: AppState): boolean => {
    const { isLoading } = AddressRetrieveOneSelectors.selectState(appState);
    return isLoading;
  },
};

const AddressEntitySelector = {
  selectState: ({ entities: { address } }: AppState): AddressCommon => address,
  selectAddress: (appState: AppState): AddressCommon => {
    const address = AddressEntitySelector.selectState(appState);
    return address;
  },
};

export { AddressEntitySelector, AddressRetrieveOneSelectors };
