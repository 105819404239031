import styled from 'styled-components';

import { COLORS } from '../../../Styles/themes';

export const List = styled.ul`
  margin-bottom: 42px;
  list-style: none;
`;

export const Item = styled.li`
  margin-bottom: 8px;
  margin-left: 20px;
  display: grid;
  grid-template-columns: auto max-content;

  p {
    margin: 0;
    font-size: 24px;
    font-family: 'Open Sans';
    color: ${COLORS.LIGHTEST_GRAY};
  }
`;

export const Divider = styled.div`
  border: 1px solid #dadada;
  margin-bottom: 8px;
`;
