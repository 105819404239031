import React, { useCallback } from 'react';
import {
  Redirect,
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { USER_ROLE } from '../Redux/User/Entity';
import { AuthSelectors } from '../Redux/Auth/selectors';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  requiredRoles?: USER_ROLE[];
  component: React.ComponentType;
}

const AuthRoute: React.FC<RouteProps> = ({
  isPrivate = false,
  requiredRoles,
  component: Component,
  ...rest
}) => {
  const { user } = useSelector(AuthSelectors.selectState);

  const isPrivateRoute = useCallback(() => isPrivate === !!user, [
    isPrivate,
    user,
  ]);

  const userHasRequiredRole = useCallback(
    () => !!user && requiredRoles?.includes(user.role),
    [requiredRoles, user],
  );

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivateRoute() && userHasRequiredRole() ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export { AuthRoute };
