import React from 'react';

import { TextFieldProps } from '@material-ui/core/TextField';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';

import { emailFirstLetterCapitalized } from '../../Common/strings';

import { DefaultTextField } from '.';

const EmailTextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const { placeholder, id, name } = props;
  return (
    <DefaultTextField
      {...props}
      type="email"
      variant="outlined"
      id={id || 'email'}
      name={name || 'email'}
      placeholder={placeholder || emailFirstLetterCapitalized}
      inputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <EmailOutlined fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export { EmailTextField };
