import React from 'react';

import { Sidebar } from '.';

import { AppBar } from '../AppBar';

import { SideBarProps } from '../../Models';

type Props = SideBarProps;

const SideBarWithAppBar: React.FC<Props> = (props: Props) => {
  const { role, items, onClick } = props;

  return (
    <>
      <AppBar />
      <Sidebar role={role} items={items} onClick={onClick} />
    </>
  );
};

export { SideBarWithAppBar };
