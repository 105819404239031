import { AppState } from '..';
import { Lot, LotEntityState } from './Entity';
import { RequestState } from '../../Services/Api/models';

const LotRequestState = {
  selectState: ({ requests: { lot } }: AppState) => lot,
};

const LotEntitySelector = {
  selectState: ({ entities: { lots } }: AppState): LotEntityState => lots,
  selectLots: (appState: AppState): Lot[] => {
    const { lots } = LotEntitySelector.selectState(appState);
    return lots;
  },
  selectLotById: (appState: AppState, id: string): Lot | undefined => {
    const lots = LotEntitySelector.selectLots(appState);

    return lots.find(lot => lot.id === id);
  },
};

const LotRetrieveSelectors = {
  selectState: (appState: AppState) => {
    const { retrieve } = LotRequestState.selectState(appState);
    return retrieve;
  },
};

const LotRetrieveAllStateSelectors = {
  selectState: (appState: AppState): RequestState => {
    const { all } = LotRetrieveSelectors.selectState(appState);
    return all;
  },
  selectIsLoading: (appState: AppState): boolean => {
    const { isLoading } = LotRetrieveAllStateSelectors.selectState(appState);
    return isLoading;
  },
};

const LotCreateStateSelectors = {
  selectState: (appState: AppState): RequestState => {
    const { create } = LotRequestState.selectState(appState);
    return create;
  },
  selectIsLoading: (appState: AppState): boolean => {
    const { isLoading } = LotCreateStateSelectors.selectState(appState);
    return isLoading;
  },
  selectErrorMsg: (appState: AppState): string => {
    const { errorMsg } = LotCreateStateSelectors.selectState(appState);
    return errorMsg;
  },
  selectIsDone: (appState: AppState): boolean => {
    const { isDone } = LotCreateStateSelectors.selectState(appState);
    return isDone;
  },
};

export {
  LotEntitySelector,
  LotCreateStateSelectors,
  LotRetrieveAllStateSelectors,
};
