import { RequestState } from '../../../Services/Api/models';

import { UserTypes } from '../types';
import { UserActions } from '../actions';

export type UserRetrieveState = RequestState;

const initialState: UserRetrieveState = {
  isLoading: false,
  isDone: false,
  hasError: false,
  errorMsg: '',
};

export function userRetrieveReducer(
  state = initialState,
  action: UserActions,
): UserRetrieveState {
  switch (action.type) {
    case UserTypes.RETRIEVE_ALL_USERS_REQUEST: {
      return {
        errorMsg: '',
        isDone: false,
        isLoading: true,
        hasError: false,
      };
    }
    case UserTypes.RETRIEVE_ALL_USERS_SUCCESS: {
      return {
        isLoading: false,
        isDone: true,
        hasError: false,
        errorMsg: '',
      };
    }
    case UserTypes.RETRIEVE_ALL_USERS_FAILURE: {
      return {
        isLoading: false,
        isDone: true,
        hasError: true,
        errorMsg: action.payload,
      };
    }
    default:
      return state;
  }
}
