import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

import { EvaluateUserRequest } from '../../Services/Api/User';

import { EvaluationTypes } from './types';

interface EvaluateRequest {
  userId: string;
  request: EvaluateUserRequest;
}

export const EvaluationCreateActionCreators = {
  evaluationCreateEvaluationRequest: (
    userId: string,
    request: EvaluateUserRequest,
  ): Readonly<{
    type: EvaluationTypes.CREATE_EVALUATION_REQUEST;
    payload: EvaluateRequest;
  }> =>
    createAction(EvaluationTypes.CREATE_EVALUATION_REQUEST, {
      userId,
      request,
    }),
  userPostCreateEvaluationSuccess: (): Readonly<{
    type: EvaluationTypes.CREATE_EVALUATION_SUCCESS;
  }> => createAction(EvaluationTypes.CREATE_EVALUATION_SUCCESS),
  userPostCreateEvaluationFailure: (
    errorMsg: string,
  ): Readonly<{
    type: EvaluationTypes.CREATE_EVALUATION_FAILURE;
    payload: string;
  }> => createAction(EvaluationTypes.CREATE_EVALUATION_FAILURE, errorMsg),
  userPostCreateEvaluationInitialState: (): Readonly<{
    type: EvaluationTypes.CREATE_EVALUATION_INITIAL_STATE;
  }> => createAction(EvaluationTypes.CREATE_EVALUATION_INITIAL_STATE),
};

export type EvaluationCreateActions = ActionsUnion<
  typeof EvaluationCreateActionCreators
>;

export type EvaluationActions = EvaluationCreateActions;
