import { RequestState } from '../../../Services/Api/models';

import { AuctionItemsTypes } from '../types';
import { AuctionItemActions } from '../actions';

export type AuctionItemRetrieveAllState = RequestState;

const initialState: AuctionItemRetrieveAllState = {
  isLoading: false,
  isDone: false,
  hasError: false,
  errorMsg: '',
};

export function auctionItemRetrieveAllReducer(
  state = initialState,
  action: AuctionItemActions,
): AuctionItemRetrieveAllState {
  switch (action.type) {
    case AuctionItemsTypes.RETRIEVE_ALL_AUCTION_ITEM_REQUEST: {
      return {
        errorMsg: '',
        isDone: false,
        isLoading: true,
        hasError: false,
      };
    }
    case AuctionItemsTypes.RETRIEVE_ALL_AUCTION_ITEM_SUCCESS: {
      return {
        isDone: true,
        errorMsg: '',
        hasError: false,
        isLoading: false,
      };
    }
    case AuctionItemsTypes.RETRIEVE_ALL_AUCTION_ITEM_FAILURE: {
      const errorMsg = action.payload;
      return {
        errorMsg,
        isDone: true,
        hasError: true,
        isLoading: false,
      };
    }
    case AuctionItemsTypes.RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_REQUEST: {
      return {
        errorMsg: '',
        isDone: false,
        isLoading: true,
        hasError: false,
      };
    }
    case AuctionItemsTypes.RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_SUCCESS: {
      return {
        isDone: true,
        errorMsg: '',
        hasError: false,
        isLoading: false,
      };
    }
    case AuctionItemsTypes.RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_FAILURE: {
      const errorMsg = action.payload;
      return {
        errorMsg,
        isDone: true,
        hasError: true,
        isLoading: false,
      };
    }
    case AuctionItemsTypes.RETRIEVE_ALL_BIDDED_AUCTION_ITEM_REQUEST: {
      return {
        errorMsg: '',
        isDone: false,
        isLoading: true,
        hasError: false,
      };
    }
    case AuctionItemsTypes.RETRIEVE_ALL_BIDDED_AUCTION_ITEM_SUCCESS: {
      return {
        isDone: true,
        errorMsg: '',
        hasError: false,
        isLoading: false,
      };
    }
    case AuctionItemsTypes.RETRIEVE_ALL_BIDDED_AUCTION_ITEM_FAILURE: {
      const errorMsg = action.payload;
      return {
        errorMsg,
        isDone: true,
        hasError: true,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
