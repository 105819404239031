import styled from 'styled-components';

import logo from '../../../Assets/logo.svg';
import { COLORS } from '../../../Styles/themes';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  height: 90%;
  display: grid;
  padding: 16px 12px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content repeat(3, 1fr);

  border-radius: 10px;
  border: 2px solid #173a5a;
`;

export const Header = styled.div`
  grid-column: span 3;
  margin-bottom: 24px;
`;

export const Logo = styled.img.attrs({
  src: logo,
  alt: 'Arena Crédito',
})`
  width: 100%;
  height: 118px;
  margin-bottom: 80px;
`;

export const Title = styled.h1`
  font-size: 24px;
  color: ${COLORS.BLUE};
  font-family: 'Open Sans';
  margin-bottom: 16px;
`;

export const Subtitle = styled.h2`
  font-size: 16px;
  color: ${COLORS.BLUE};
  font-family: 'Open Sans';
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-column: span 3;
  width: 100%;
  align-self: center;
`;
