import React from 'react';
import MaskedInput from 'react-text-mask';

import InputAdornment from '@material-ui/core/InputAdornment';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';

import { DefaultTextField, DefaultTextFieldProps } from '.';

import { InputMasksTypes, InputMasks } from '../../Common/enums';

type Props = DefaultTextFieldProps & {
  mask: InputMasksTypes;
};

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask: InputMasksTypes;
}

const TextMaskCustom: React.FC<TextMaskCustomProps> = (
  props: TextMaskCustomProps,
) => {
  const { inputRef, mask, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={InputMasks[mask]}
      placeholderChar={'\u2000'}
    />
  );
};

const MaskedTextField: React.FC<Props> = (props: Props) => {
  const { mask, inputProps } = props;
  return (
    <DefaultTextField
      {...props}
      inputProps={{
        ...inputProps,
        inputComponent: TextMaskCustom as any,
        inputProps: { mask },
      }}
    />
  );
};

const CnpjTextField: React.FC<DefaultTextFieldProps> = (
  props: DefaultTextFieldProps,
) => <MaskedTextField {...props} placeholder="CNPJ" mask="cnpj" name="cnpj" />;

const CpfTextField: React.FC<DefaultTextFieldProps> = (
  props: DefaultTextFieldProps,
) => <MaskedTextField {...props} placeholder="CPF" mask="cpf" />;

const CpfCnpjTextField: React.FC<DefaultTextFieldProps> = (
  props: DefaultTextFieldProps,
) => <MaskedTextField {...props} placeholder="CPF/CNPJ" mask="cpfCnpj" />;

const PhoneTextField: React.FC<DefaultTextFieldProps> = (
  props: DefaultTextFieldProps,
) => (
  <MaskedTextField
    {...props}
    mask="phone"
    inputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <LocalPhoneOutlinedIcon />
        </InputAdornment>
      ),
    }}
  />
);

const CepTextField: React.FC<DefaultTextFieldProps> = (
  props: DefaultTextFieldProps,
) => (
  <MaskedTextField
    {...props}
    placeholder="Cep"
    mask="cep"
    inputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <PlaceOutlinedIcon />
        </InputAdornment>
      ),
    }}
  />
);

export {
  CnpjTextField,
  CpfTextField,
  CpfCnpjTextField,
  PhoneTextField,
  CepTextField,
};
