import React, { useState, useEffect, useCallback } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AuthRoutes } from '../../Routes';
import { AuthSelectors } from '../../Redux/Auth/selectors';
import { AuthActionsCreators } from '../../Redux/Auth/actions';

import { SignIn } from './Components';

import {
  requiredEmail,
  writeValidEmail,
  requiredPassword,
  minPasswordLength,
  maxPasswordLength,
} from '../../Common/strings';

export interface LoginValues {
  password: string;
  email: string;
}

const SignInPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const errorMsg = useSelector(AuthSelectors.selectErrorMsg);
  const isLogging = useSelector(AuthSelectors.selectIsLogging);
  const isAdmin = useSelector(AuthSelectors.selectIsAdmin);
  const isProvider = useSelector(AuthSelectors.selectIsProvider);
  const isInvestor = useSelector(AuthSelectors.selectIsInvestor);
  const isBackOffice = useSelector(AuthSelectors.selectIsBackOffice);

  const initialValues: LoginValues = { password: '', email: '' };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, minPasswordLength)
      .max(20, maxPasswordLength)
      .required(requiredPassword),
    email: Yup.string().email(writeValidEmail).required(requiredEmail),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ password, email }) => signIn({ password, email }),
  });

  const navigateTo = useCallback(
    ({ screenName }) => {
      history.push(screenName);
    },
    [history],
  );

  const screenNameByRole = useCallback(() => {
    let screenName = '/';
    if (isInvestor) {
      screenName = `${AuthRoutes.investor}${AuthRoutes.auction}`;
    } else if (isBackOffice || isAdmin) {
      screenName = `${AuthRoutes.backoffice}/fornecedores`;
    } else if (isProvider) {
      screenName = `${AuthRoutes.provider}/lotes`;
    }

    return screenName;
  }, [isAdmin, isBackOffice, isInvestor, isProvider]);

  useEffect(() => {
    const screenName = screenNameByRole();

    navigateTo({ screenName });
  }, [navigateTo, screenNameByRole]);

  const signIn = useCallback(
    ({ password, email }) => {
      dispatch(AuthActionsCreators.signInRequest({ email, password }));
    },
    [dispatch],
  );

  const handleCloseAlert = useCallback(
    (_?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    },
    [],
  );

  useEffect(() => {
    if (errorMsg) {
      setOpen(true);
    }
  }, [errorMsg]);

  return (
    <SignIn
      open={open}
      formik={formik}
      errorMsg={errorMsg}
      isLogging={isLogging}
      onCloseAlert={handleCloseAlert}
      onSignUp={() => navigateTo({ screenName: '/signup' })}
    />
  );
};

export { SignInPage };
