import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { AuthRoutes } from '../../../../../Routes';

import { AppState } from '../../../../../Redux';
import { LotEntitySelector } from '../../../../../Redux/Lot/selectors';
import { InvoiceRetrieveActionsCreators } from '../../../../../Redux/Invoice/actions';
import { AuctionItemCreateActionsCreators } from '../../../../../Redux/AuctionItem/actions';
import {
  InvoiceEntitySelector,
  InvoiceRetrieveAllStateSelectors,
} from '../../../../../Redux/Invoice/selectors';
import { AuctionItemCreateSelectors } from '../../../../../Redux/AuctionItem/selector';

import { Alert } from '../../../../../Components/Alert';
import { InvoiceTable } from '../../../Invoice/Components/InvoiceTable';

import { ConfirmAuctionDialog } from './ConfirmAuctionDialog';
import { CustomTableToolbarSelect } from './CustomTableToolbarSelect';

import { TitleWithGoBack } from '../Components/TitleWithGoBack';
import { AuctionInvoiceDetailsActionsColumn } from '../Components/AuctionInvoiceDetailsActionsColumn';

interface ParamsState {
  id: string;
}

export type SelectedInvoice = {
  id: string;
  invoiceId: string;
};

export const BackOfficeAuctionInvoiceListPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<ParamsState>();

  const selectLotById = useCallback(
    (appState: AppState) => {
      return LotEntitySelector.selectLotById(appState, id);
    },
    [id],
  );

  const lot = useSelector(selectLotById);
  const invoices = useSelector(InvoiceEntitySelector.selectInvoices);
  const isLoadingInvoices = useSelector(
    InvoiceRetrieveAllStateSelectors.selectIsLoading,
  );

  const isCreatingAuctionItem = useSelector(
    AuctionItemCreateSelectors.selectIsLoading,
  );
  const isDoneCreateAuctionItem = useSelector(
    AuctionItemCreateSelectors.selectIsDone,
  );
  const errorMsgCreateAuctionItem = useSelector(
    AuctionItemCreateSelectors.selectErrorMsg,
  );

  const [page, setPage] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [quantitySelectedInvoices, setQuantitySelectedInvoices] = useState(0);
  const [selectedInvoices, setSelectedInvoiceIds] = useState<SelectedInvoice[]>(
    [],
  );

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Identificador',
      name: 'invoiceId',
    },
    {
      label: 'Sacado',
      name: 'emitter.name',
    },
    {
      label: 'CNPJ',
      name: 'cnpj',
    },
    {
      label: 'Data/hora',
      name: 'emissionDate',
    },
    {
      label: 'Estado',
      name: 'emitter.address.uf',
    },
    {
      label: 'Tipo',
      name: 'emitter.type',
    },
    {
      label: ' ',
      name: 'id',
      options: {
        customBodyRender: id => {
          return (
            <AuctionInvoiceDetailsActionsColumn
              onAnalyseClick={() => handleAnalyseClick(id)}
            />
          );
        },
      },
    },
  ];

  const handleCloseAlert = (): void => setOpenAlert(false);
  const handleCloseDialog = (): void => setOpenDialog(false);

  const handleChangePage = (currentPage: number): void => {
    setPage(currentPage);
    loadInvoicesByLotId(currentPage + 1);
  };

  const options: MUIDataTableOptions = {
    page,
    onChangePage: handleChangePage,
    enableNestedDataAccess: '.',
  };

  const handleAnalyseClick = (id: string): void => {
    history.push(
      `${AuthRoutes.backoffice}${AuthRoutes.auction}/${AuthRoutes.lots}/nota/detalhes`,
      { invoiceId: id },
    );
  };

  const handleAuctionClick = (selectedIndex: number[] | undefined): void => {
    setOpenDialog(true);

    setSelectedInvoiceIds(
      selectedIndex?.map(selectedId => ({
        id: invoices[selectedId].id,
        invoiceId: invoices[selectedId].invoiceId,
      })) || [],
    );

    setQuantitySelectedInvoices(selectedIndex?.length || 0);
  };

  const loadInvoicesByLotId = useCallback(
    (page = 1) => {
      dispatch(
        InvoiceRetrieveActionsCreators.invoiceRetrieveByLotIdRequest(
          id,
          page,
          'approved',
        ),
      );
    },
    [dispatch, id],
  );

  const goBack = useCallback(() => history.goBack(), [history]);

  const handleConfirmAuction = useCallback(() => {
    if (lot) {
      dispatch(
        AuctionItemCreateActionsCreators.auctionItemCreateRequest({
          creatorId: lot.owner_lot_id,
          invoicesId: selectedInvoices.map(({ id }) => id),
          returnPercentExpected: lot.return_percent_expected,
        }),
      );
    }
  }, [dispatch, lot, selectedInvoices]);

  useEffect(() => loadInvoicesByLotId(), [loadInvoicesByLotId]);

  useEffect(() => {
    if (isDoneCreateAuctionItem) {
      if (errorMsgCreateAuctionItem) {
        setOpenAlert(true);
        return;
      }
      history.push(`${AuthRoutes.backoffice}${AuthRoutes.auction}`);
    }
  }, [errorMsgCreateAuctionItem, history, isDoneCreateAuctionItem]);

  return (
    <>
      <TitleWithGoBack
        providerName={lot?.owner_lot_name || ''}
        onGoBackClick={goBack}
      />
      <InvoiceTable
        options={options}
        invoices={invoices}
        columns={columns}
        isLoading={isLoadingInvoices}
        components={{
          TableToolbarSelect: ({ ...rest }) => (
            <CustomTableToolbarSelect
              onAuctionClick={handleAuctionClick}
              {...rest}
            />
          ),
        }}
      />
      <ConfirmAuctionDialog
        open={openDialog}
        isLoading={isCreatingAuctionItem}
        title="Resumo à subir para leilão"
        totalValue={lot?.total_value_lot || 0}
        providerName={lot?.owner_lot_name || ''}
        expectedRate={lot?.return_percent_expected || 0}
        selectedInvoices={selectedInvoices}
        quantitySelectedInvoices={quantitySelectedInvoices}
        text="Você deseja subir as seguintes notas para leilão?"
        cancelButtonText="Cancelar"
        confirmButtonText="Confirmar"
        onClose={handleCloseDialog}
        onConfirmClick={handleConfirmAuction}
        onCancelClick={handleCloseDialog}
      />
      <Alert
        open={openAlert}
        severity="error"
        text="Não foi possível criar seu leilão. Tente novamente mais tarde."
        onClose={handleCloseAlert}
      />
    </>
  );
};
