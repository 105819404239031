import { combineReducers } from 'redux';

import { ParameterRetrieveAllState, parameterRetrieveAllReducer } from './all';

export interface ParameterRetrieveState {
  all: ParameterRetrieveAllState;
}

const reducer = combineReducers<ParameterRetrieveState>({
  all: parameterRetrieveAllReducer,
});

export { reducer as parameterRetrieveReducer };
