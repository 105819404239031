import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { AuthActionsCreators } from '../../Redux/Auth/actions';

import { SideBarProps } from '../../Models';

import * as S from './styles';

interface IHandleClick {
  label: string;
  route: string;
  index: number;
  icon: React.ReactNode;
}

const Sidebar: React.FC<SideBarProps> = ({
  role,
  items,
  onClick,
}: SideBarProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = S.useStyles();

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const logout = (): void => {
    dispatch(AuthActionsCreators.logout());
    history.push('/');
  };

  const handleItemClick = ({
    label,
    icon,
    route,
    index,
  }: IHandleClick): void => {
    setSelectedIndex(index);
    onClick({ label, icon, route });
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
          <S.Logo />
          <S.Title>{role}</S.Title>
        </div>
        <Divider />
        <List>
          {items.map(({ label, icon, route }, index) => (
            <ListItem
              button
              selected={selectedIndex === index}
              key={label}
              onClick={() => handleItemClick({ label, icon, route, index })}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
        <div className={classes.logoutWrapper}>
          <ListItem
            button
            key="Sair"
            onClick={logout}
            className={classes.logoutButton}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </div>
      </Drawer>
    </div>
  );
};

export { Sidebar };
