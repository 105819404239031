import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../Styles/themes';

export const ListContainer = styled.div`
  display: grid;
  margin: 42px 14px;
`;

export const ButtonContainer = styled.div`
  justify-self: end;
`;

export const useStyles = makeStyles({
  addMoreButton: {
    fontSize: 14,
    width: '201px',
    height: '36px',
    fontWeight: 500,
    marginBottom: '38px',
    color: COLORS.WHITE,
    background: '#2C98F0',
    fontFamily: 'Roboto Slab',
  },
});
