import { RequestState } from '../../../Services/Api/models';

import { LotTypes } from '../types';
import { LotActions } from '../actions';

export type LotCreateState = RequestState;

const initialState: LotCreateState = {
  errorMsg: '',
  isDone: false,
  hasError: false,
  isLoading: false,
};

export function lotCreateReducer(
  state = initialState,
  action: LotActions,
): LotCreateState {
  switch (action.type) {
    case LotTypes.CREATE_LOT_REQUEST: {
      return {
        isLoading: true,
        isDone: false,
        hasError: false,
        errorMsg: '',
      };
    }
    case LotTypes.CREATE_LOT_SUCCESS: {
      return {
        isLoading: false,
        isDone: true,
        hasError: false,
        errorMsg: '',
      };
    }
    case LotTypes.CREATE_LOT_FAILURE: {
      return {
        isLoading: false,
        isDone: true,
        hasError: true,
        errorMsg: action.payload,
      };
    }
    case LotTypes.CREATE_LOT_INITIAL_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
