/* eslint-disable no-useless-constructor */
import { ApiInstance, ApiResponse, getApiInstance } from '..';

import { AddressRequest } from '../../../Redux/Address/Entity';

export interface AddressApi {
  getAddressByCep({ cep }: AddressRequest): Promise<ApiResponse<string>>;
}

export class AddressApiImpl implements AddressApi {
  url = 'https://brasilapi.com.br/api/cep/v1';

  constructor(private api: ApiInstance) {}

  getAddressByCep({ cep }: AddressRequest): Promise<ApiResponse<string>> {
    return this.api.get(`${this.url}/${cep}`);
  }
}

let instance: AddressApi;

export function getAddressApiInstance(api: ApiInstance): AddressApi {
  if (!instance) {
    const myApi = api || getApiInstance();
    instance = new AddressApiImpl(myApi);
  }

  return instance;
}
