import React from 'react';

import { MUIDataTableColumnDef } from 'mui-datatables';

import { Table } from '../../../../Components/Table';
import { Loading } from '../../../../Components/Loading';

import { User } from '../../../../Redux/User/Entity';

interface Props {
  providers: User[];
  isLoading: boolean;
  columns: MUIDataTableColumnDef[];
}

const ProviderTable: React.FC<Props> = (props: Props) => {
  const { columns, providers, isLoading } = props;
  return isLoading ? <Loading /> : <Table data={providers} columns={columns} />;
};

export { ProviderTable };
