import { combineReducers } from 'redux';

import { LotEntityState, lotEntityReducer } from '../Lot';
import { UserEntityState, userEntityReducer } from '../User';
import { InvoiceEntityState, invoiceEntityReducer } from '../Invoice';
import { AddressEntityState, addressEntityReducer } from '../Address';
import { ParameterEntityState, parameterEntityReducer } from '../Parameter';
import {
  AuctionItemEntityState,
  auctionItemEntityReducer,
} from '../AuctionItem';
import {
  SellingOrderEntityState,
  sellingOrderEntityReducer,
} from '../SellingOrder';

export interface EntitiesState {
  lots: LotEntityState;
  users: UserEntityState;
  address: AddressEntityState;
  invoices: InvoiceEntityState;
  parameter: ParameterEntityState;
  auctionItem: AuctionItemEntityState;
  sellingOrder: SellingOrderEntityState;
}

const reducer = combineReducers<EntitiesState>({
  lots: lotEntityReducer,
  users: userEntityReducer,
  address: addressEntityReducer,
  invoices: invoiceEntityReducer,
  parameter: parameterEntityReducer,
  auctionItem: auctionItemEntityReducer,
  sellingOrder: sellingOrderEntityReducer,
});

export { reducer as entitiesReducer };
