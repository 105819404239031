import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Button } from './styles';

interface Props {
  text: string;
  isLoading?: boolean;
  variant: 'primary' | 'secondary';
  type?: 'button' | 'submit' | 'reset';
  onClick: () => void;
}

const RoundedButton: React.FC<Props> = (props: Props) => {
  const { variant, isLoading, text, type, onClick } = props;
  const classes = useStyles();

  return (
    <Button variant={variant} onClick={onClick} type={type || 'button'}>
      {isLoading ? (
        <CircularProgress size={32} className={classes.loading} />
      ) : (
        text
      )}
    </Button>
  );
};

const useStyles = makeStyles({
  loading: {
    color: green[500],
  },
});

export { RoundedButton };
