import { AppState } from '..';
import { Invoice, InvoiceEntityState } from './Entity';
import { RequestState } from '../../Services/Api/models';

const InvoiceRequestState = {
  selectState: ({ requests: { invoice } }: AppState) => invoice,
};

export const InvoiceEntitySelector = {
  selectState: ({ entities: { invoices } }: AppState): InvoiceEntityState =>
    invoices,
  selectInvoices: (appState: AppState): Invoice[] => {
    const { invoices } = InvoiceEntitySelector.selectState(appState);
    return invoices;
  },
  selectInvoiceById: (appState: AppState, id: string): Invoice | undefined => {
    const invoices = InvoiceEntitySelector.selectInvoices(appState);
    return invoices.find(invoice => invoice?.id === id || invoice?._id === id);
  },
};

const InvoiceRetrieveSelectors = {
  selectState: (appState: AppState) => {
    const { retrieve } = InvoiceRequestState.selectState(appState);
    return retrieve;
  },
};

export const InvoiceRetrieveAllStateSelectors = {
  selectState: (appState: AppState): RequestState => {
    const { all } = InvoiceRetrieveSelectors.selectState(appState);
    return all;
  },
  selectIsLoading: (appState: AppState): boolean => {
    const { isLoading } = InvoiceRetrieveAllStateSelectors.selectState(
      appState,
    );
    return isLoading;
  },
};

export const InvoicePatchSelectors = {
  selectState: (appState: AppState): RequestState => {
    const { patch } = InvoiceRequestState.selectState(appState);
    return patch;
  },
  selectIsLoading: (appState: AppState): boolean => {
    const { isLoading } = InvoicePatchSelectors.selectState(appState);
    return isLoading;
  },
  selectErrorMsg: (appState: AppState): string => {
    const { errorMsg } = InvoicePatchSelectors.selectState(appState);
    return errorMsg;
  },
  selectIsDone: (appState: AppState): boolean => {
    const { isDone } = InvoicePatchSelectors.selectState(appState);
    return isDone;
  },
};
