import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

import { AddressTypes } from './types';
import { AddressRequest, AddressResponse } from './Entity';

export const AddressRetrieveOneActionCreators = {
  addressRetrieveOneRequest: ({
    cep,
  }: AddressRequest): Readonly<{
    type: AddressTypes.RETRIEVE_ONE_ADDRESS_REQUEST;
    payload: AddressRequest;
  }> => createAction(AddressTypes.RETRIEVE_ONE_ADDRESS_REQUEST, { cep }),

  addressRetrieveOneSuccess: (
    response: AddressResponse,
  ): Readonly<{
    type: AddressTypes.RETRIEVE_ONE_ADDRESS_SUCCESS;
    payload: AddressResponse;
  }> => createAction(AddressTypes.RETRIEVE_ONE_ADDRESS_SUCCESS, response),

  addressRetrieveOneFailure: (
    errorMsg: string,
  ): Readonly<{
    type: AddressTypes.RETRIEVE_ONE_ADDRESS_FAILURE;
    payload: string;
  }> => createAction(AddressTypes.RETRIEVE_ONE_ADDRESS_FAILURE, errorMsg),
};

export type AddressRetrieveOneActions = ActionsUnion<
  typeof AddressRetrieveOneActionCreators
>;

export type AddressActions = AddressRetrieveOneActions;
