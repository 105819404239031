import { combineReducers } from 'redux';

import { LotCrudState, crudLotReducer } from '../Lot';
import { UserCrudState, crudUserReducer } from '../User';
import { AddressCrudState, crudAddressReducer } from '../Address';
import { InvoiceCrudState, crudInvoiceReducer } from '../Invoice';
import { ParameterCrudState, crudParameterReducer } from '../Parameter';
import { EvaluationCrudState, crudEvaluationReducer } from '../Evaluation';
import { AuctionItemCrudState, crudAuctionItemReducer } from '../AuctionItem';
import {
  SellingOrderCrudState,
  crudSellingOrderReducer,
} from '../SellingOrder';

export interface RequestState {
  lot: LotCrudState;
  user: UserCrudState;
  address: AddressCrudState;
  invoice: InvoiceCrudState;
  parameter: ParameterCrudState;
  evaluation: EvaluationCrudState;
  auctionItem: AuctionItemCrudState;
  sellingOrder: SellingOrderCrudState;
}

const reducer = combineReducers<RequestState>({
  lot: crudLotReducer,
  user: crudUserReducer,
  address: crudAddressReducer,
  invoice: crudInvoiceReducer,
  parameter: crudParameterReducer,
  evaluation: crudEvaluationReducer,
  auctionItem: crudAuctionItemReducer,
  sellingOrder: crudSellingOrderReducer,
});

export { reducer as requestsReducer };
