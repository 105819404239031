import { Bid, Entity } from '../../models';
import { AuctionItemsTypes } from '../types';
import { Invoice } from '../../Invoice/Entity';
import { AuctionItemActions } from '../actions';
import { User, InvoiceStatus } from '../../../Models';

export interface AuctionItem extends Entity {
  creator: User;
  bestBid?: Bid;
  supplier: User;
  createdAt: Date;
  totalValue: number;
  updatedAt: number;
  invoices: Invoice[];
  isFavorite: boolean;
  invoiceCount: number;
  status: InvoiceStatus;
  amIWinning?: boolean;
  returnPercentExpected: number;
  myBid?: {
    value: number;
  };
}

export interface AuctionItemEntityState {
  auctionItems: AuctionItem[];
}

const initialState: AuctionItemEntityState = {
  auctionItems: [],
};

export function auctionItemEntityReducer(
  state = initialState,
  action: AuctionItemActions,
): AuctionItemEntityState {
  switch (action.type) {
    case AuctionItemsTypes.RETRIEVE_ALL_AUCTION_ITEM_SUCCESS: {
      const auctionItems = action.payload;

      return { ...state, auctionItems };
    }
    case AuctionItemsTypes.RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_SUCCESS: {
      const auctionItems = action.payload;

      return { ...state, auctionItems };
    }
    case AuctionItemsTypes.RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_FAILURE: {
      return { ...state, auctionItems: [] };
    }
    case AuctionItemsTypes.GIVE_BID_SUCCESS: {
      const { bid, auctionItemId } = action.payload;

      const auctionItems: AuctionItem[] = state.auctionItems.map(
        (auctionItem: AuctionItem) =>
          updateAuctionItemBestBidByAuctionItemId(
            auctionItemId,
            bid,
            auctionItem,
          ),
      );
      return { ...state, auctionItems };
    }
    case AuctionItemsTypes.RETRIEVE_ALL_BIDDED_AUCTION_ITEM_SUCCESS: {
      const auctionItems = action.payload;

      return { ...state, auctionItems };
    }
    case AuctionItemsTypes.RETRIEVE_ALL_BIDDED_AUCTION_ITEM_FAILURE: {
      return { ...state, auctionItems: [] };
    }
    case AuctionItemsTypes.FAVORITE_AUCTION_ITEM_SUCCESS: {
      const auctionItemId = action.payload;

      const auctionItems = state.auctionItems.map(
        (auctionItem: AuctionItem) => {
          if (auctionItem._id === auctionItemId) {
            return {
              ...auctionItem,
              isFavorite: true,
            };
          }

          return auctionItem;
        },
      );

      return { ...state, auctionItems };
    }
    case AuctionItemsTypes.UNFAVORITE_AUCTION_ITEM_SUCCESS: {
      const auctionItemId = action.payload;

      const auctionItems = state.auctionItems.map(
        (auctionItem: AuctionItem) => {
          if (auctionItem._id === auctionItemId) {
            return {
              ...auctionItem,
              isFavorite: false,
            };
          }

          return auctionItem;
        },
      );

      return { ...state, auctionItems };
    }
    default:
      return state;
  }
}

const updateAuctionItemBestBidByAuctionItemId = (
  auctionItemId: string,
  newBestBidValue: number,
  auctionItem: AuctionItem,
): AuctionItem => {
  if (auctionItem._id === auctionItemId && auctionItem.bestBid) {
    const bestBid: Bid = {
      ...auctionItem.bestBid,
      value: newBestBidValue,
    };
    return { ...auctionItem, bestBid };
  }
  return { ...auctionItem };
};
