import React from 'react';
import { useSelector } from 'react-redux';

import { AuthSelectors } from '../../../Redux/Auth/selectors';

import { BackOfficeAuctionInvoiceListPage } from './Contexts/BackOffice/AuctionInvoiceListPage';
import { InvestorAuctionItemInvoiceListPage } from './Contexts/Investor/AuctionInvoiceListPage';

export const AuctionInvoiceListPage: React.FC = () => {
  const isBackOffice = useSelector(AuthSelectors.selectIsBackOffice);

  return (
    <>
      {isBackOffice ? (
        <BackOfficeAuctionInvoiceListPage />
      ) : (
        <InvestorAuctionItemInvoiceListPage />
      )}
    </>
  );
};
