import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  AuctionItemPutActionsCreators,
  AuctionItemRetrieveActionsCreators,
} from '../../../../../Redux/AuctionItem/actions';
import {
  AuctionItemPutSelectors,
  AuctionItemEntitySelector,
  AuctionItemRetrieveAllSelectors,
} from '../../../../../Redux/AuctionItem/selector';
import { AuthSelectors } from '../../../../../Redux/Auth/selectors';

import { GiveBidDialog } from './GiveBidDialog';

import { Alert } from '../../../../../Components/Alert';
import { EmptyState } from '../../../Components/EmptyState';
import { StatusBar } from '../../../../../Components/StatusBar';
import { AuctionItemCardList } from '../Components/AuctionItemCardList';

import { atAuctionCapitalized } from '../../../../../Common/strings';
import { AuthRoutes } from '../../../../../Routes';

export const InvestorAuctionListPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tabs = [atAuctionCapitalized, 'Favoritos', 'Participando'];

  const [tabValue, setTabValue] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [auctionItemId, setAuctionItemId] = useState('');
  const [openBidDialog, setOpenBidDialog] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);

  const user = useSelector(AuthSelectors.selectLoggedUser);
  const auctionItems = useSelector(
    AuctionItemEntitySelector.selectAuctionItems,
  );
  const isLoading = useSelector(
    AuctionItemRetrieveAllSelectors.selectIsLoading,
  );
  const errorMsgPutAuctionItem = useSelector(
    AuctionItemPutSelectors.selectErrorMsg,
  );
  const isDoneGiveBid = useSelector(AuctionItemPutSelectors.selectIsDone);

  const isFavoritesTabSelected = useCallback(() => tabValue === 1, [tabValue]);
  const isFollowingTabSelected = useCallback(() => tabValue === 2, [tabValue]);

  const loadAuctions = useCallback(() => {
    if (isFavoritesTabSelected()) {
      dispatch(
        AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllFavoriteRequest(
          user?.id || '',
        ),
      );
    } else if (isFollowingTabSelected()) {
      dispatch(
        AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllBiddedRequest(
          user?.id || '',
        ),
      );
    } else {
      dispatch(
        AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllRequest(),
      );
    }
  }, [dispatch, isFavoritesTabSelected, isFollowingTabSelected, user?.id]);

  const handleCloseErrorAlert = (): void => setOpenErrorAlert(false);

  const handleResumeClick = (id: string): void => {
    history.push(`${AuthRoutes.investor}${AuthRoutes.auction}/${id}/notas`);
  };

  const handleOnGiveABidClick = (id: string): void => {
    setAuctionItemId(id);
    setOpenBidDialog(true);
  };

  const handleOnConfirmBidClick = (bid: number): void => {
    dispatch(
      AuctionItemPutActionsCreators.giveBidRequest({ auctionItemId, bid }),
    );
    setOpenBidDialog(false);
  };

  const handleFollowClick = (id: string): void => {
    const auctionItem = auctionItems.find(item => item._id === id);

    auctionItem?.isFavorite
      ? dispatch(AuctionItemPutActionsCreators.auctionItemUnfavoriteRequest(id))
      : dispatch(AuctionItemPutActionsCreators.auctionItemFavoriteRequest(id));
  };

  useEffect(() => {
    loadAuctions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, isFavoritesTabSelected]);

  useEffect(() => {
    if (errorMsgPutAuctionItem) {
      setOpenErrorAlert(true);
    }
  }, [errorMsgPutAuctionItem]);

  useEffect(() => {
    if (isDoneGiveBid && !errorMsgPutAuctionItem) {
      setOpenAlert(true);
    }
  }, [errorMsgPutAuctionItem, isDoneGiveBid]);

  return (
    <>
      <StatusBar tabs={tabs} value={tabValue} onChange={setTabValue} />
      {auctionItems.length === 0 && !isLoading ? (
        <EmptyState message="Sem leilão" />
      ) : (
        <AuctionItemCardList
          isLoading={isLoading}
          auctionItems={auctionItems}
          onResumeClick={handleResumeClick}
          onFollowClick={handleFollowClick}
          onGiveABidClick={handleOnGiveABidClick}
        />
      )}
      <GiveBidDialog
        open={openBidDialog}
        onCancelClick={() => setOpenBidDialog(false)}
        onConfirmClick={handleOnConfirmBidClick}
      />

      <Alert
        open={openErrorAlert}
        severity="error"
        text={errorMsgPutAuctionItem}
        onClose={handleCloseErrorAlert}
      />

      <Alert
        open={openAlert}
        severity="success"
        text="Leilão criado com sucesso"
        onClose={() => setOpenAlert(false)}
      />
    </>
  );
};
