import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import * as S from './Styles/AuctionItemCard.styled';

import { FingerUpIcon } from '../../../../../Components/Icons';
import { Button } from '../../../../../Components/Buttons/Button';

import { AuctionItem } from '../../../../../Redux/AuctionItem/Entity';

import {
  expectedRate,
  auctionQuantity,
  totalValueOfTheLot,
  lotResumeCapitalized,
  bidsFirstLetterCapitalized,
  providerFirstLetterCapitalized,
  giveAnBidFirstLetterCapitalized,
} from '../../../../../Common/strings';

interface Props {
  auctionItem: AuctionItem;
  onResumeClick: () => void;
  onFollowClick: (auctionItemId: string) => void;
  onGiveABidClick: (auctionItemId: string) => void;
}

const AuctionItemCard: React.FC<Props> = (props: Props) => {
  const { auctionItem, onResumeClick, onGiveABidClick, onFollowClick } = props;

  const setThreePoints = (): string => {
    if (auctionItem.supplier.full_name.length > 30) {
      return `${auctionItem.supplier.full_name.substring(0, 30)}...`;
    }
    return auctionItem.supplier.full_name;
  };

  const handleFollowClick = (): void => {
    onFollowClick(auctionItem._id || auctionItem.id);
  };

  const handleGivBidClick = (): void => {
    onGiveABidClick(auctionItem._id || auctionItem.id);
  };

  return (
    <S.CustomLot>
      <S.CardContainer>
        <S.CardInfoContainer>
          <S.InfoContainer>
            <S.Label>{providerFirstLetterCapitalized}</S.Label>
            <S.Text>{setThreePoints()}</S.Text>
          </S.InfoContainer>
          <S.InfoContainer>
            <S.Label>{auctionQuantity}</S.Label>
            <S.Text>{auctionItem.invoiceCount}</S.Text>
          </S.InfoContainer>
          <S.InfoContainer>
            <S.Label>{totalValueOfTheLot}</S.Label>
            <S.Text>{`R$ ${auctionItem.totalValue}`}</S.Text>
          </S.InfoContainer>
        </S.CardInfoContainer>

        <S.CardStatusContainer>
          <S.IconsContainer>
            {auctionItem?.amIWinning && (
              <Tooltip title="Seu lance está ganhando!" arrow>
                <S.TrophyIcon />
              </Tooltip>
            )}
            <Tooltip title="Acompanhar" arrow>
              <IconButton
                size="small"
                aria-label="Acompanhar"
                onClick={handleFollowClick}
              >
                {auctionItem.isFavorite ? <S.FilledStar /> : <StarBorderIcon />}
              </IconButton>
            </Tooltip>
          </S.IconsContainer>
          <S.CardStatusContent>
            <S.SmallInfoContainer>
              <S.Label>Finaliza em:</S.Label>
              <S.Time>-</S.Time>
            </S.SmallInfoContainer>
            <S.SmallInfoContainer>
              <S.Label>{bidsFirstLetterCapitalized}</S.Label>
              <S.Bid>
                {auctionItem.bestBid ? `${auctionItem.bestBid.value}%` : '-'}
              </S.Bid>
            </S.SmallInfoContainer>
            <S.SmallInfoContainer>
              <S.Label>{expectedRate}</S.Label>
              <S.BestBid>{`${auctionItem.returnPercentExpected}%`}</S.BestBid>
            </S.SmallInfoContainer>
          </S.CardStatusContent>
        </S.CardStatusContainer>

        <S.ButtonsContainer>
          <Button variant="primary" onClick={onResumeClick}>
            <p>{lotResumeCapitalized}</p>
          </Button>
          <S.BidButton variant="secondary" onClick={handleGivBidClick}>
            <FingerUpIcon />
            <p>{giveAnBidFirstLetterCapitalized}</p>
          </S.BidButton>
        </S.ButtonsContainer>
      </S.CardContainer>
    </S.CustomLot>
  );
};

export { AuctionItemCard };
