import React from 'react';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { Table } from '../../../../../Components/Table';

export interface AuctionTableProps {
  columns: MUIDataTableColumnDef[];
  options?: MUIDataTableOptions;
  data: (any | number[] | string[])[] | undefined;
}

const AuctionTable: React.FC<AuctionTableProps> = (
  props: AuctionTableProps,
) => {
  const { data, columns, options, ...rest } = props;

  return <Table data={data} columns={columns} options={options} {...rest} />;
};

export { AuctionTable };
