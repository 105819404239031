import { combineReducers } from 'redux';

import { invoicePatchReducer, InvoicePatchState } from './Patch';
import { invoiceRetrieveReducer, InvoiceRetrieveState } from './Retrieve';

export interface InvoiceCrudState {
  patch: InvoicePatchState;
  retrieve: InvoiceRetrieveState;
}

const reducer = combineReducers<InvoiceCrudState>({
  patch: invoicePatchReducer,
  retrieve: invoiceRetrieveReducer,
});

export * from './Patch';
export * from './Entity';
export * from './Retrieve';

export { reducer as crudInvoiceReducer };
