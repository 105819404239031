import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

import { AuctionItemsTypes } from './types';
import { AuctionItem } from './Entity';

export interface AuctionItemCreateRequest {
  invoicesId: string[];
  creatorId: string;
  returnPercentExpected: number;
}

export interface GiveBidRequest {
  bid: number;
  auctionItemId: string;
}

export const AuctionItemRetrieveActionsCreators = {
  auctionItemRetrieveAllBiddedRequest: (
    userId: string,
  ): Readonly<{
    type: AuctionItemsTypes.RETRIEVE_ALL_BIDDED_AUCTION_ITEM_REQUEST;
    payload: string;
  }> =>
    createAction(
      AuctionItemsTypes.RETRIEVE_ALL_BIDDED_AUCTION_ITEM_REQUEST,
      userId,
    ),
  auctionItemRetrieveAllBiddedSuccess: (
    auctionItems: AuctionItem[],
  ): Readonly<{
    type: AuctionItemsTypes.RETRIEVE_ALL_BIDDED_AUCTION_ITEM_SUCCESS;
    payload: AuctionItem[];
  }> =>
    createAction(
      AuctionItemsTypes.RETRIEVE_ALL_BIDDED_AUCTION_ITEM_SUCCESS,
      auctionItems,
    ),
  auctionItemRetrieveAllBiddedFailure: (
    errorMsg: string,
  ): Readonly<{
    type: AuctionItemsTypes.RETRIEVE_ALL_BIDDED_AUCTION_ITEM_FAILURE;
    payload: string;
  }> =>
    createAction(
      AuctionItemsTypes.RETRIEVE_ALL_BIDDED_AUCTION_ITEM_FAILURE,
      errorMsg,
    ),
  auctionItemRetrieveAllRequest: (): Readonly<{
    type: AuctionItemsTypes.RETRIEVE_ALL_AUCTION_ITEM_REQUEST;
  }> => createAction(AuctionItemsTypes.RETRIEVE_ALL_AUCTION_ITEM_REQUEST),
  auctionItemRetrieveAllSuccess: (
    auctionItems: AuctionItem[],
  ): Readonly<{
    type: AuctionItemsTypes.RETRIEVE_ALL_AUCTION_ITEM_SUCCESS;
    payload: AuctionItem[];
  }> =>
    createAction(
      AuctionItemsTypes.RETRIEVE_ALL_AUCTION_ITEM_SUCCESS,
      auctionItems,
    ),
  auctionItemRetrieveAllFailure: (
    errorMsg: string,
  ): Readonly<{
    type: AuctionItemsTypes.RETRIEVE_ALL_AUCTION_ITEM_FAILURE;
    payload: string;
  }> =>
    createAction(AuctionItemsTypes.RETRIEVE_ALL_AUCTION_ITEM_FAILURE, errorMsg),

  auctionItemRetrieveAllFavoriteRequest: (
    userId: string,
  ): Readonly<{
    type: AuctionItemsTypes.RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_REQUEST;
    payload: string;
  }> =>
    createAction(
      AuctionItemsTypes.RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_REQUEST,
      userId,
    ),
  auctionItemRetrieveAllFavoriteSuccess: (
    auctionItems: AuctionItem[],
  ): Readonly<{
    type: AuctionItemsTypes.RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_SUCCESS;
    payload: AuctionItem[];
  }> =>
    createAction(
      AuctionItemsTypes.RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_SUCCESS,
      auctionItems,
    ),
  auctionItemRetrieveAllFavoriteFailure: (
    errorMsg: string,
  ): Readonly<{
    type: AuctionItemsTypes.RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_FAILURE;
    payload: string;
  }> =>
    createAction(
      AuctionItemsTypes.RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_FAILURE,
      errorMsg,
    ),
};

export const AuctionItemCreateActionsCreators = {
  auctionItemCreateRequest: ({
    invoicesId,
    creatorId,
    returnPercentExpected,
  }: AuctionItemCreateRequest): Readonly<{
    type: AuctionItemsTypes.CREATE_AUCTION_ITEM_REQUEST;
    payload: AuctionItemCreateRequest;
  }> =>
    createAction(AuctionItemsTypes.CREATE_AUCTION_ITEM_REQUEST, {
      invoicesId,
      creatorId,
      returnPercentExpected,
    }),
  auctionItemCreateSuccess: (): Readonly<{
    type: AuctionItemsTypes.CREATE_AUCTION_ITEM_SUCCESS;
  }> => createAction(AuctionItemsTypes.CREATE_AUCTION_ITEM_SUCCESS),
  auctionItemCreateFailure: (
    errorMsg: string,
  ): Readonly<{
    type: AuctionItemsTypes.CREATE_AUCTION_ITEM_FAILURE;
    payload: string;
  }> => createAction(AuctionItemsTypes.CREATE_AUCTION_ITEM_FAILURE, errorMsg),
  auctionItemCreateInitialState: (): Readonly<{
    type: AuctionItemsTypes.CREATE_AUCTION_ITEM_INITIAL_STATE;
  }> => createAction(AuctionItemsTypes.CREATE_AUCTION_ITEM_INITIAL_STATE),
};

export const AuctionItemPutActionsCreators = {
  giveBidRequest: (
    request: GiveBidRequest,
  ): Readonly<{
    type: AuctionItemsTypes.GIVE_BID_REQUEST;
    payload: GiveBidRequest;
  }> => createAction(AuctionItemsTypes.GIVE_BID_REQUEST, request),
  giveBidSuccess: (
    response: GiveBidRequest,
  ): Readonly<{
    type: AuctionItemsTypes.GIVE_BID_SUCCESS;
    payload: GiveBidRequest;
  }> => createAction(AuctionItemsTypes.GIVE_BID_SUCCESS, response),
  giveBidFailure: (
    errorMsg: string,
  ): Readonly<{
    type: AuctionItemsTypes.GIVE_BID_FAILURE;
    payload: string;
  }> => createAction(AuctionItemsTypes.GIVE_BID_FAILURE, errorMsg),
  auctionItemFavoriteRequest: (
    auctionItemId: string,
  ): Readonly<{
    type: AuctionItemsTypes.FAVORITE_AUCTION_ITEM_REQUEST;
    payload: string;
  }> =>
    createAction(
      AuctionItemsTypes.FAVORITE_AUCTION_ITEM_REQUEST,
      auctionItemId,
    ),
  auctionItemFavoriteSuccess: (
    auctionItemId: string,
  ): Readonly<{
    type: AuctionItemsTypes.FAVORITE_AUCTION_ITEM_SUCCESS;
    payload: string;
  }> =>
    createAction(
      AuctionItemsTypes.FAVORITE_AUCTION_ITEM_SUCCESS,
      auctionItemId,
    ),
  auctionItemFavoriteFailure: (
    errorMsg: string,
  ): Readonly<{
    type: AuctionItemsTypes.FAVORITE_AUCTION_ITEM_FAILURE;
    payload: string;
  }> => createAction(AuctionItemsTypes.FAVORITE_AUCTION_ITEM_FAILURE, errorMsg),
  auctionItemUnfavoriteRequest: (
    auctionItemId: string,
  ): Readonly<{
    type: AuctionItemsTypes.UNFAVORITE_AUCTION_ITEM_REQUEST;
    payload: string;
  }> =>
    createAction(
      AuctionItemsTypes.UNFAVORITE_AUCTION_ITEM_REQUEST,
      auctionItemId,
    ),
  auctionItemUnfavoriteSuccess: (
    auctionItemId: string,
  ): Readonly<{
    type: AuctionItemsTypes.UNFAVORITE_AUCTION_ITEM_SUCCESS;
    payload: string;
  }> =>
    createAction(
      AuctionItemsTypes.UNFAVORITE_AUCTION_ITEM_SUCCESS,
      auctionItemId,
    ),
  auctionItemUnfavoriteFailure: (
    errorMsg: string,
  ): Readonly<{
    type: AuctionItemsTypes.UNFAVORITE_AUCTION_ITEM_FAILURE;
    payload: string;
  }> =>
    createAction(AuctionItemsTypes.UNFAVORITE_AUCTION_ITEM_FAILURE, errorMsg),
};

export type AuctionItemPutActions = ActionsUnion<
  typeof AuctionItemPutActionsCreators
>;

export type AuctionItemRetrieveActions = ActionsUnion<
  typeof AuctionItemRetrieveActionsCreators
>;

export type AuctionItemCreateActions = ActionsUnion<
  typeof AuctionItemCreateActionsCreators
>;

export type AuctionItemActions =
  | AuctionItemPutActions
  | AuctionItemCreateActions
  | AuctionItemRetrieveActions;
