import {
  compose,
  Store,
  createStore,
  combineReducers,
  applyMiddleware,
} from 'redux';
import createSagaMiddleware from 'redux-saga';

import { rootSaga } from '../Saga';
import { Apis, getApisInstance } from '../Services';

import { AuthState, authReducer } from './Auth';
import { SampleState, sampleReducer } from './Sample';
import { RequestState, requestsReducer } from './Requests';
import { EntitiesState, entitiesReducer } from './Entities';

export interface AppState {
  auth: AuthState;
  sample: SampleState;
  requests: RequestState;
  entities: EntitiesState;
}

interface StoreResponse {
  store: Store;
}

export const allReducers = combineReducers<AppState>({
  auth: authReducer,
  sample: sampleReducer,
  requests: requestsReducer,
  entities: entitiesReducer,
});

export function createAppStore(): StoreResponse {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    allReducers,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );

  const apis: Apis = getApisInstance();

  sagaMiddleware.run(rootSaga, apis);

  return { store };
}
