import { combineReducers } from 'redux';

import { EvaluationCreateState, evaluationCreateReducer } from './Create';

export interface EvaluationCrudState {
  create: EvaluationCreateState;
}

const reducer = combineReducers<EvaluationCrudState>({
  create: evaluationCreateReducer,
});

export * from './Create';

export { reducer as crudEvaluationReducer };
