import { combineReducers } from 'redux';

import { parameterRetrieveReducer, ParameterRetrieveState } from './Retrieve';

export interface ParameterCrudState {
  retrieve: ParameterRetrieveState;
}

const reducer = combineReducers<ParameterCrudState>({
  retrieve: parameterRetrieveReducer,
});

export * from './Entity';
export * from './Retrieve';

export { reducer as crudParameterReducer };
