import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

import { InvoiceStatus } from '../../Models';

import { LotTypes } from './types';
import { Lot } from './Entity';

interface LotCreateRequest {
  invoices: File[];
  expectedRate: number;
}

interface LotRetrieveQueryParams {
  limited_at?: number;
  invoiceStatus?: InvoiceStatus;
}

export const LotRetrieveActionsCreators = {
  lotRetrieveAllRequest: (
    limited_at?: number,
    invoiceStatus?: InvoiceStatus,
  ): Readonly<{
    type: LotTypes.RETRIEVE_LOTS_REQUEST;
    payload: LotRetrieveQueryParams;
  }> =>
    createAction(LotTypes.RETRIEVE_LOTS_REQUEST, { limited_at, invoiceStatus }),
  lotRetrieveAllSuccess: (
    lots: Lot[],
  ): Readonly<{
    payload: Lot[];
    type: LotTypes.RETRIEVE_LOTS_SUCCESS;
  }> => createAction(LotTypes.RETRIEVE_LOTS_SUCCESS, lots),
  lotRetrieveAllFailure: (
    errorMsg: string,
  ): Readonly<{
    type: LotTypes.RETRIEVE_LOTS_FAILURE;
    payload: string;
  }> => createAction(LotTypes.RETRIEVE_LOTS_FAILURE, errorMsg),
};

export const LotCreateActionsCreators = {
  lotCreateRequest: (
    request: LotCreateRequest,
  ): Readonly<{
    payload: LotCreateRequest;
    type: LotTypes.CREATE_LOT_REQUEST;
  }> => createAction(LotTypes.CREATE_LOT_REQUEST, request),
  lotCreateSuccess: (): Readonly<{ type: LotTypes.CREATE_LOT_SUCCESS }> =>
    createAction(LotTypes.CREATE_LOT_SUCCESS),
  lotCreateFailure: (
    errorMsg: string,
  ): Readonly<{
    payload: string;
    type: LotTypes.CREATE_LOT_FAILURE;
  }> => createAction(LotTypes.CREATE_LOT_FAILURE, errorMsg),
  lotCreateInitialState: (): Readonly<{
    type: LotTypes.CREATE_LOT_INITIAL_STATE;
  }> => createAction(LotTypes.CREATE_LOT_INITIAL_STATE),
};

export type LotRetrieveActions = ActionsUnion<
  typeof LotRetrieveActionsCreators
>;
export type LotCreateActions = ActionsUnion<typeof LotCreateActionsCreators>;
export type LotActions = LotCreateActions | LotRetrieveActions;
