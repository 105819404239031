import { AppState } from '..';
import { RequestState } from '../../Services/Api/models';
import { SellingOrder, SellingOrderEntityState } from './Entity';

const SellingOrderRequestState = {
  selectState: ({ requests: { sellingOrder } }: AppState) => sellingOrder,
};

const SellingOrderEntitySelector = {
  selectState: ({
    entities: { sellingOrder },
  }: AppState): SellingOrderEntityState => sellingOrder,

  selectSellingOrders: (appState: AppState): SellingOrder[] => {
    const { sellingOrders } = SellingOrderEntitySelector.selectState(appState);
    return sellingOrders;
  },

  selectSellingOrderById: (
    appState: AppState,
    id: string,
  ): SellingOrder | undefined => {
    const sellingOrders = SellingOrderEntitySelector.selectSellingOrders(
      appState,
    );

    return sellingOrders.find(sellingOrder => sellingOrder._id === id);
  },
};

const SellingOrderRetrieveSelectors = {
  selectState: (appState: AppState) => {
    const { retrieve } = SellingOrderRequestState.selectState(appState);
    return retrieve;
  },
};

const SellingOrderRetrieveAllStateSelectors = {
  selectState: (appState: AppState): RequestState => {
    const { all } = SellingOrderRetrieveSelectors.selectState(appState);
    return all;
  },
  selectIsLoading: (appState: AppState): boolean => {
    const { isLoading } = SellingOrderRetrieveAllStateSelectors.selectState(
      appState,
    );
    return isLoading;
  },
};

export { SellingOrderEntitySelector, SellingOrderRetrieveAllStateSelectors };
