/* eslint-disable no-useless-constructor */
import { ApiInstance, ApiResponse, getApiInstance } from '..';

import { ROLE } from '../../../Redux/models';
import { Parameter } from '../../../Redux/Parameter/Entity';

export interface ParameterApi {
  retrieveParametersByRole(
    role: ROLE.PROVIDER | ROLE.INVESTOR,
  ): Promise<ApiResponse<Parameter>>;
}

export class ParameterApiImpl implements ParameterApi {
  url = 'parameters';

  constructor(private api: ApiInstance) {}

  retrieveParametersByRole(
    role: ROLE.PROVIDER | ROLE.INVESTOR,
  ): Promise<ApiResponse<Parameter>> {
    return this.api.get(`${this.url}/${role}`);
  }
}

let instance: ParameterApi;

export function getParameterApiInstance(api: ApiInstance): ParameterApi {
  if (!instance) {
    const myApi = api || getApiInstance();
    instance = new ParameterApiImpl(myApi);
  }

  return instance;
}
