import styled from 'styled-components';

export const Container = styled.div`
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

export const Title = styled.h2`
  color: #000;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 22px;
  font-family: 'Roboto Slab';
  grid-column: span 12;
`;

export const DataContainer = styled.div`
  grid-column: span 12;
  margin-bottom: 42px;

  display: grid;
  grid-template-columns: repeat(12, 1fr);

  p {
    font-family: 'Roboto Slab';
    font-size: 12px;
    color: #6d6d6d;
  }
`;

export const PersonalInfoContainer = styled.div`
  grid-column: span 3;
`;

export const UFContainer = styled.div`
  grid-column: span 9;
`;
