import { combineReducers } from 'redux';

import {
  SellingOrderRetrieveAllState,
  sellingOrderRetrieveAllReducer,
} from './all';

export interface SellingOrderRetrieveState {
  all: SellingOrderRetrieveAllState;
}

const reducer = combineReducers<SellingOrderRetrieveState>({
  all: sellingOrderRetrieveAllReducer,
});

export { reducer as sellingOrderRetrieveReducer };
