import { call, put } from 'redux-saga/effects';

import { UserApi } from '../../Services/Api/User';
import { EvaluationCreateActionCreators } from '../../Redux/Evaluation/actions';

export function* evaluationCreate(
  api: UserApi,
  action: ReturnType<
    typeof EvaluationCreateActionCreators.evaluationCreateEvaluationRequest
  >,
): any {
  const { userId, request } = action.payload;
  const { ok, data, problem } = yield call(
    [api, api.evaluateUser],
    userId,
    request,
  );

  if (ok) {
    yield put(EvaluationCreateActionCreators.userPostCreateEvaluationSuccess());
  } else {
    const message = data && data.message ? data.message : `${problem}`;
    yield put(
      EvaluationCreateActionCreators.userPostCreateEvaluationFailure(message),
    );
  }
}
