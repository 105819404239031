import styled from 'styled-components';

import { COLORS } from '../../Styles/themes';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 112px;
  border-radius: 20px;
  background: ${COLORS.BLUE};
`;

export const Value = styled.p`
  font-size: 20px;
  color: ${COLORS.WHITE};
  font-weight: bold;
  font-family: 'Roboto Slab';
`;
