import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { AppState } from '../../../../../Redux';
import { AuthRoutes } from '../../../../../Routes';

import { AuctionItemEntitySelector } from '../../../../../Redux/AuctionItem/selector';
import { AuctionItemPutActionsCreators } from '../../../../../Redux/AuctionItem/actions';

import { AuctionTable } from '../Components';
import { TitleWithGoBack } from './TitleWithGoBack';
import { AuctionInvoiceDetailsActionsColumn } from '../Components/AuctionInvoiceDetailsActionsColumn';

interface ParamsState {
  id: string;
}

export const InvestorAuctionItemInvoiceListPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<ParamsState>();

  const [page, setPage] = useState(0);
  const [openBidDialog, setOpenBidDialog] = useState(false);
  const [openFollowDialog, setOpenFollowDialog] = useState(false);

  const auctionItem = useSelector((appState: AppState) =>
    AuctionItemEntitySelector.selectAuctionItemById(appState, id),
  );

  const handleChangePage = (currentPage: number): void => {
    setPage(currentPage);
    // loadInvoicesByLotId(currentPage + 1);
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      label: '',
      name: 'id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    { label: 'Identificador', name: 'invoiceId' },
    { label: 'Sacado', name: 'encoded.emitter.name' },
    { label: 'Destinatário', name: 'encoded.receiver.name' },
    { label: 'Data de emissão', name: 'encoded.invoice.emissionDate' },
    { label: 'Valor total', name: 'encoded.invoice.value' },
    {
      label: 'Análise',
      name: '_id',
      options: {
        customBodyRender: id => {
          return (
            <AuctionInvoiceDetailsActionsColumn
              onAnalyseClick={() => handleAnalyseClick(id)}
            />
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    page,
    onChangePage: handleChangePage,
    enableNestedDataAccess: '.',
  };

  const goBack = useCallback(() => history.goBack(), [history]);

  const handleOnConfirmBidDialog = (): void => {
    setOpenBidDialog(false);
  };

  const handleOnConfirmFollowDialogClick = (): void => {
    if (auctionItem) {
      const auctionItemId = auctionItem._id || '';

      auctionItem?.isFavorite
        ? dispatch(
            AuctionItemPutActionsCreators.auctionItemUnfavoriteRequest(
              auctionItemId,
            ),
          )
        : dispatch(
            AuctionItemPutActionsCreators.auctionItemFavoriteRequest(
              auctionItemId,
            ),
          );
    }
    setOpenFollowDialog(false);
  };

  const handleAnalyseClick = (id: string): void => {
    history.push(
      `${AuthRoutes.investor}${AuthRoutes.auction}${AuthRoutes.lots}/nota/detalhes`,
      { invoiceId: id },
    );
  };

  return !auctionItem ? (
    <div>
      <p>Erro</p>
    </div>
  ) : (
    <>
      <TitleWithGoBack
        isFilled={auctionItem.isFavorite}
        openBidDialog={openBidDialog}
        openFollowDialog={openFollowDialog}
        providerName={auctionItem?.supplier.full_name}
        onGoBackClick={goBack}
        onBidClick={() => setOpenBidDialog(true)}
        onFollowClick={() => setOpenFollowDialog(true)}
        onConfirmBidDialogClick={handleOnConfirmBidDialog}
        onCancelBidDialogClick={() => setOpenBidDialog(false)}
        onConfirmFollowDialogClick={handleOnConfirmFollowDialogClick}
        onCancelFollowDialogClick={() => setOpenFollowDialog(false)}
      />
      <AuctionTable
        columns={columns}
        options={options}
        data={auctionItem?.invoices}
      />
    </>
  );
};
