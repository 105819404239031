import { call, put } from 'redux-saga/effects';

import { User, UserRequest, USER_ROLE } from '../../Redux/User/Entity';
import { AuthApi } from '../../Services/Api/Auth';
import { parseToCnpj, parseToCep } from '../../Common/functionUtils';

import {
  UserCreateActionCreators,
  UserRetrieveAllActionCreators,
} from '../../Redux/User/actions';

import { UserApi } from '../../Services/Api/User';

export function* userCreateSaga(
  authApi: AuthApi,
  action: ReturnType<typeof UserCreateActionCreators.userCreateRequest>,
): any {
  const { full_name, cnpj, email, role, password } = action.payload;

  const request: UserRequest = {
    cnpj,
    role,
    email,
    password,
    full_name,
    user_contacts: [],
  };

  const { ok, data, problem } = yield call([authApi, authApi.signUp], request);

  if (ok) {
    yield put(UserCreateActionCreators.userCreateSuccess());
  } else {
    const message = data && data.message ? data.message : `${problem}`;
    yield put(UserCreateActionCreators.userCreateFailure(message));
  }
}

export function* userRetrieveAll(
  api: UserApi,
  action: ReturnType<
    typeof UserRetrieveAllActionCreators.userRetrieveAllRequest
  >,
): any {
  const { role_name } = action.payload;

  const {
    ok,
    data: { data },
    problem,
  } = yield call([api, api.getUsersByRole], action.payload);

  if (ok) {
    const users = formatResponseToUsers(data, role_name);
    yield put(UserRetrieveAllActionCreators.userRetrieveAllSuccess(users));
  } else {
    const message = data && data.message ? data.message : `${problem}`;
    yield put(UserRetrieveAllActionCreators.userRetrieveAllFailure(message));
  }
}

function formatResponseToUsers(response: any, role: USER_ROLE): User[] {
  return response.map(
    ({
      _id,
      cep,
      cnpj,
      email,
      state,
      address,
      full_name,
      complement,
      evaluations,
      house_number,
      neighborhood,
      is_approved,
      social_reason,
    }: any) => ({
      email,
      state,
      id: _id,
      address,
      full_name,
      complement,
      is_approved,
      evaluations,
      neighborhood,
      house_number,
      social_reason,
      cep: parseToCep(cep),
      cnpj: parseToCnpj(cnpj),
      role: role.toUpperCase(),
    }),
  );
}
