/* eslint-disable no-param-reassign */

import { AxiosRequestConfig } from 'axios';
import { ApiInstance, ApiResponse, getApiInstance } from '..';

import { UserRequest } from '../../../Redux/User/Entity';

export const AUTHORIZATION_HEADER = 'Authorization';
export const AUTH_TOKEN_PREFIX = 'Bearer ';

export interface AccountCredentials {
  email: string;
  password: string;
}

export class AuthApi {
  authToken = '';

  constructor(private api: ApiInstance) {
    this.api.addRequestTransform(this.fillAuthHeader.bind(this));
  }

  logout(): Promise<ApiResponse<void>> {
    this.authToken = '';

    return Promise.resolve({
      ok: true,
      problem: null,
      originalError: null,
    });
  }

  signUp(request: UserRequest): Promise<ApiResponse<string>> {
    return this.api.post('/register', request);
  }

  async signIn(credentials: AccountCredentials): Promise<ApiResponse<unknown>> {
    const response = await this.api.post('/login', credentials);
    return this.transformLoginResponse(response);
  }

  async transformLoginResponse(
    response: ApiResponse<any>,
  ): Promise<ApiResponse<unknown>> {
    if (response.ok && response.data) {
      const { token } = response.data;
      this.authToken = token;
    }
    return response;
  }

  private fillAuthHeader({ headers }: AxiosRequestConfig): void {
    try {
      if (this.authToken && headers) {
        headers[AUTHORIZATION_HEADER] = AUTH_TOKEN_PREFIX + this.authToken;
      }
    } catch (error) {
      console.error('Failed to set authorization header:', error);
    }
  }
}

let instance: AuthApi;

export function getAuthApiInstance(api: ApiInstance): AuthApi {
  if (!instance) {
    const myApi = api || getApiInstance();
    instance = new AuthApi(myApi);
  }
  return instance;
}
