import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { InputProps } from '@material-ui/core/Input';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import { COLORS } from '../../Styles/themes';

export type DefaultTextFieldProps = TextFieldProps & {
  inputProps?: InputProps;
};

const DefaultTextField: React.FC<DefaultTextFieldProps> = ({
  inputProps,
  ...props
}: DefaultTextFieldProps) => {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      variant="outlined"
      InputProps={{
        ...inputProps,
        className: classes.input,
      }}
    />
  );
};

export const useStyles = makeStyles({
  input: {
    color: COLORS.GRAY,
    borderRadius: '40px',
    backgroundColor: COLORS.WHITE,
    '&.Mui-focused': {
      border: `3px solid ${COLORS.LIGHTEST_BLUE}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
});

export { DefaultTextField };
