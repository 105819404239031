import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

import { UserResponse } from '../User/Entity';
import { AccountCredentials } from '../../Services/Api/Auth';

export enum AuthTypes {
  SIGN_IN_REQUEST = '[auth] SIGN_REQUEST',
  SIGN_IN_SUCCESS = '[auth] SIGN_SUCCESS',
  SIGN_IN_FAILURE = '[auth] SIGN_FAILURE',
  LOGOUT_SUCCESS = '[auth] LOGOUT_SUCCESS',
}

export const AuthActionsCreators = {
  signInRequest: (
    credentials: AccountCredentials,
  ): Readonly<{
    type: AuthTypes.SIGN_IN_REQUEST;
    payload: AccountCredentials;
  }> => createAction(AuthTypes.SIGN_IN_REQUEST, credentials),

  signInSuccess: (
    response: UserResponse,
  ): Readonly<{ type: AuthTypes.SIGN_IN_SUCCESS; payload: UserResponse }> =>
    createAction(AuthTypes.SIGN_IN_SUCCESS, response),

  signInFailure: (
    errorMsg: string,
  ): Readonly<{
    type: AuthTypes.SIGN_IN_FAILURE;
    payload: string;
  }> => createAction(AuthTypes.SIGN_IN_FAILURE, errorMsg),

  logout: (): Readonly<{ type: AuthTypes.LOGOUT_SUCCESS }> =>
    createAction(AuthTypes.LOGOUT_SUCCESS),
};

export type AuthActions = ActionsUnion<typeof AuthActionsCreators>;
