import { RequestState } from '../../../Services/Api/models';

import { AddressTypes } from '../types';
import { AddressActions } from '../actions';

export type AddressRetrieveOneState = RequestState;

const initialState: AddressRetrieveOneState = {
  isLoading: false,
  isDone: false,
  hasError: false,
  errorMsg: '',
};

export function addressRetrieveOneReducer(
  state = initialState,
  action: AddressActions,
): AddressRetrieveOneState {
  switch (action.type) {
    case AddressTypes.RETRIEVE_ONE_ADDRESS_REQUEST: {
      return {
        errorMsg: '',
        isDone: false,
        isLoading: true,
        hasError: false,
      };
    }
    case AddressTypes.RETRIEVE_ONE_ADDRESS_SUCCESS: {
      return {
        isDone: true,
        errorMsg: '',
        hasError: false,
        isLoading: false,
      };
    }
    case AddressTypes.RETRIEVE_ONE_ADDRESS_FAILURE: {
      const errorMsg = action.payload;
      return {
        errorMsg,
        isDone: true,
        hasError: true,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
