import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Alert } from '../../../../../Components/Alert';
import { StatusBar } from '../../../../../Components/StatusBar';

import {
  LotEntitySelector,
  LotRetrieveAllStateSelectors,
} from '../../../../../Redux/Lot/selectors';
import { LotRetrieveActionsCreators } from '../../../../../Redux/Lot/actions';
import {
  AuctionItemEntitySelector,
  AuctionItemCreateSelectors,
  AuctionItemRetrieveAllSelectors,
} from '../../../../../Redux/AuctionItem/selector';
import {
  AuctionItemCreateActionsCreators,
  AuctionItemRetrieveActionsCreators,
} from '../../../../../Redux/AuctionItem/actions';

import { AuctionCardList } from './AuctionCardList';

import {
  toAuctionCapitalized,
  atAuctionCapitalized,
  auctionedCapitalized,
} from '../../../../../Common/strings';

export const BackOfficeAuctionListPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const tabs = [
    toAuctionCapitalized,
    atAuctionCapitalized,
    auctionedCapitalized,
  ];

  const lots = useSelector(LotEntitySelector.selectLots);
  const isLoading = useSelector(LotRetrieveAllStateSelectors.selectIsLoading);

  const auctionItems = useSelector(
    AuctionItemEntitySelector.selectAuctionItems,
  );
  const isLoadingAuctionItems = useSelector(
    AuctionItemRetrieveAllSelectors.selectIsLoading,
  );

  const [tabValue, setTabValue] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);

  const isDoneCreateAuctionItem = useSelector(
    AuctionItemCreateSelectors.selectIsDone,
  );
  const errorMsgCreateAuctionItem = useSelector(
    AuctionItemCreateSelectors.selectErrorMsg,
  );

  const handleOnResumeClick = useCallback(
    (id: string) => {
      history.push(`${history.location.pathname}/${id}/notas`);
    },
    [history],
  );

  const isAuctionTabSelected = useCallback(() => tabValue === 1, [tabValue]);

  const loadAuctions = useCallback(() => {
    dispatch(
      AuctionItemRetrieveActionsCreators.auctionItemRetrieveAllRequest(),
    );
  }, [dispatch]);

  const loadLotsWithApprovedInvoices = useCallback(() => {
    dispatch(
      LotRetrieveActionsCreators.lotRetrieveAllRequest(10000, 'approved'),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(AuctionItemCreateActionsCreators.auctionItemCreateInitialState());
  }, [dispatch]);

  useEffect(() => {
    if (isDoneCreateAuctionItem && !errorMsgCreateAuctionItem) {
      setOpenAlert(true);
    }
  }, [errorMsgCreateAuctionItem, isDoneCreateAuctionItem]);

  useEffect(() => {
    if (isAuctionTabSelected()) {
      loadAuctions();
      return;
    }
    loadLotsWithApprovedInvoices();
  }, [
    tabValue,
    loadAuctions,
    isAuctionTabSelected,
    loadLotsWithApprovedInvoices,
  ]);

  return (
    <>
      <StatusBar tabs={tabs} value={tabValue} onChange={setTabValue} />
      {isAuctionTabSelected() ? (
        <AuctionCardList
          auctionItems={auctionItems}
          isLoading={isLoadingAuctionItems}
          onResumeClick={handleOnResumeClick}
        />
      ) : (
        <AuctionCardList
          lots={lots}
          isLoading={isLoading}
          onResumeClick={handleOnResumeClick}
        />
      )}

      <Alert
        open={openAlert}
        severity="success"
        text="Leilão criado com sucesso"
        onClose={() => setOpenAlert(false)}
      />
    </>
  );
};
