import React from 'react';

import { Bid } from '../../../../../Redux/models';

import * as S from '../Components/Styles/AuctionCard.styled';
import { CustomButton, ButtonsContainer } from './AuctionCard.styled';

import { Lot } from '../../../Components/Lot';

import {
  expectedRate,
  auctionQuantity,
  totalValueOfTheLot,
  lotResumeCapitalized,
  bidsFirstLetterCapitalized,
  providerFirstLetterCapitalized,
} from '../../../../../Common/strings';

interface Props {
  data: {
    endsIn?: Date;
    bestBid?: Bid;
    fullName: string;
    totalValue: number;
    approvedInvoices: number;
    returnPercentExpected: number;
  };
  onResumeClick: () => void;
}

const AuctionCard: React.FC<Props> = (props: Props) => {
  const { data, onResumeClick } = props;

  const setThreePoints = (): string => {
    if (data.fullName.length > 30) {
      return `${data.fullName.substring(0, 30)}...`;
    }
    return data.fullName;
  };

  return (
    <Lot>
      <S.CardContainer>
        <S.CardInfoContainer>
          <S.InfoContainer>
            <S.Label>{providerFirstLetterCapitalized}</S.Label>
            <S.Text>{setThreePoints()}</S.Text>
          </S.InfoContainer>
          <S.InfoContainer>
            <S.Label>{auctionQuantity}</S.Label>
            <S.Text>{data.approvedInvoices}</S.Text>
          </S.InfoContainer>
          <S.InfoContainer>
            <S.Label>{totalValueOfTheLot}</S.Label>
            <S.Text>{`R$ ${data.totalValue}`}</S.Text>
          </S.InfoContainer>
        </S.CardInfoContainer>

        <S.CardStatusContainer>
          <S.SmallInfoContainer>
            <S.Label>Finaliza em:</S.Label>
            <S.Time>{data.endsIn ? 'tempo' : '-'}</S.Time>
          </S.SmallInfoContainer>
          <S.SmallInfoContainer>
            <S.Label>{bidsFirstLetterCapitalized}</S.Label>
            <S.Bid>{data.bestBid ? `${data.bestBid.value}%` : '-'}</S.Bid>
          </S.SmallInfoContainer>
          <S.SmallInfoContainer>
            <S.Label>{expectedRate}</S.Label>
            <S.BestBid>{`${data.returnPercentExpected}%`}</S.BestBid>
          </S.SmallInfoContainer>
        </S.CardStatusContainer>
        <ButtonsContainer>
          <CustomButton variant="primary" onClick={onResumeClick}>
            {lotResumeCapitalized}
          </CustomButton>
        </ButtonsContainer>
      </S.CardContainer>
    </Lot>
  );
};

export { AuctionCard };
