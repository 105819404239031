import { makeStyles } from '@material-ui/core/styles';

import { SIZE } from '../../../Common/enums';

import { Props } from '.';

export const useStyles = makeStyles({
  root: {
    textTransform: 'capitalize',
    gridColumn: ({ size }: Props) => setWidth(size),
  },
  helperText: {
    textTransform: 'none',
  },
});

function setWidth(size: SIZE): string {
  if (size === SIZE.MEDIUM) {
    return 'span 4';
  }
  if (size === SIZE.LARGE) {
    return 'span 6';
  }
  return 'span 2';
}
