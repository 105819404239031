import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { COLORS } from '../../../../Styles/themes';

import {
  ok,
  cancelCapitalized,
  expectedRateRequired,
  expectedRateGreaterThenZero,
  provideTheBasicToStartAuction,
  expectedRateLessThenOneHundred,
  expectedRate as expectedRateStr,
} from '../../../../Common/strings';

interface Props {
  open: boolean;
  onCancelClick: () => void;
  onOkClick: (expectedRate: number) => void;
}

interface Values {
  expectedRate: number;
}

const ExpectedRateDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { open, onCancelClick, onOkClick } = props;

  const initialValues: Values = { expectedRate: 0 };

  const validationSchema = Yup.object().shape({
    expectedRate: Yup.number()
      .min(0, expectedRateGreaterThenZero)
      .max(100, expectedRateLessThenOneHundred)
      .required(expectedRateRequired),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ expectedRate }) => onOkClick(expectedRate),
  });

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onCancelClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          Taxa esperada
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-moreThanOneInvoice"
            className={classes.subtitle}
          >
            {provideTheBasicToStartAuction}
          </DialogContentText>

          <TextField
            type="number"
            id="expectedRate"
            label={expectedRateStr}
            className={classes.textfield}
            value={formik.values.expectedRate}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: { min: 0, max: 100, step: 0.1 },
            }}
            helperText={formik.errors.expectedRate}
            error={Boolean(formik.errors.expectedRate)}
            onChange={formik.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelClick} className={classes.cancelButton}>
            {cancelCapitalized}
          </Button>
          <Button type="submit" className={classes.okButton}>
            {ok}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles({
  title: {
    color: '#2196F3',
  },
  subtitle: {
    marginBottom: '34px',
  },
  cancelButton: {
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    fontSize: 14,
    opacity: '56%',
    color: COLORS.BLACK,
  },
  okButton: {
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    fontSize: 14,
    color: '#2196F3',
  },
  textfield: {
    width: '200px',
  },
});

export { ExpectedRateDialog };
