import React from 'react';

import SearchIcon from '@material-ui/icons/Search';
import { DefaultButton } from '../../../../Components/Buttons';

import { evaluateCapitalized } from '../../../../Common/strings';

interface Props {
  onEvaluateClick: () => void;
}

const InvestorTableActionsColumn: React.FC<Props> = (props: Props) => {
  const { onEvaluateClick } = props;

  return (
    <DefaultButton
      endIcon={<SearchIcon />}
      variant_type="primary"
      text={evaluateCapitalized}
      onClick={onEvaluateClick}
    />
  );
};

export { InvestorTableActionsColumn };
