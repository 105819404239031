import styled from 'styled-components';
import colorfulLogo from '../../../../Assets/colorful_simple_logo.svg';

import { COLORS } from '../../../../Styles/themes';

export const EmptyCard = styled.div`
  max-width: 320px;
  margin: 240px auto;
  text-align: center;

  p {
    font-size: 20px;
    margin-top: 30px;
    color: ${COLORS.DARKEST_GRAY};
  }
`;

export const ColorfulLogo = styled.img.attrs({
  src: colorfulLogo,
  alt: 'Arena Crédito',
})`
  max-width: 320px;
`;
