import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import MaterialTextField, { TextFieldProps } from '@material-ui/core/TextField';

import { SIZE } from '../../../Common/enums';

import { useStyles } from './styles';

export interface SelectTextFieldData {
  label: string;
  value?: string | number;
}

export interface Props {
  size: SIZE;
  textFieldProps?: TextFieldProps;
  data: SelectTextFieldData[];
}

const SelectTextField: React.FC<Props> = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { size, data, textFieldProps, ...rest } = props;

  const classes = useStyles(props);

  return (
    <MaterialTextField
      select
      variant="filled"
      className={classes.root}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      {...textFieldProps}
      {...rest}
    >
      {data.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MaterialTextField>
  );
};

export { SelectTextField };
