import styled from 'styled-components';

import { COLORS } from '../../../../Styles/themes';

export const Container = styled.div`
  width: 50vw;
  height: 100vh;
  background: ${COLORS.BLUE};
`;

export const Content = styled.div`
  display: flex;
  margin-top: 6vh;
  margin-left: 7vw;
  margin-right: 7vw;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 43.75rem;
  color: ${COLORS.WHITE};
  font-family: 'Open Sans';
`;

export const Text = styled.span`
  font-weight: 25rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  color: ${COLORS.WHITE};
  font-family: 'Open Sans';

  margin-top: 6vh;
`;
