import React, { useCallback } from 'react';

import { SIZE } from '../../../../Common/enums';
import { SelectTextField } from '../../../../Components/TextFields';
import { SelectTextFieldData } from '../../../../Components/TextFields/SelectTextField';

import { Evaluation } from '../../../../Redux/Evaluation/Entity';
import { ParameterOption } from '../../../../Redux/ParameterOption';

import { pleaseSelectTheParameter } from '../../../../Common/strings';

interface Props {
  label: string;
  parameterId: string;
  evaluation?: Evaluation;
  data: ParameterOption[];
  setParameters: (id: string, selected: string) => void;
}

const ParameterOptionSelect: React.FC<Props> = (props: Props) => {
  const { label, data, parameterId, evaluation, setParameters } = props;
  const [value, setValue] = React.useState('');

  const setValueIfHasEvaluation = (): void => {
    if (evaluation) {
      const findParameter = evaluation.parameters.find(
        parameter => parameter.name === label,
      );
      setValue(`${findParameter?.selected}` || '');
    }
  };

  React.useEffect(() => {
    setValueIfHasEvaluation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatParameterOptions(
    options: ParameterOption[],
  ): SelectTextFieldData[] {
    return options.map((option, index) => ({
      label: option.name,
      value: index,
    }));
  }

  const handleChange = useCallback(
    ({ selected, parameterId }) => {
      setValue(selected);
      setParameters(parameterId, `${selected}`);
    },
    [setParameters],
  );

  return (
    <SelectTextField
      key={parameterId}
      size={SIZE.MEDIUM}
      data={formatParameterOptions(data)}
      textFieldProps={{
        value,
        label: `${label}:`,
        helperText: pleaseSelectTheParameter,
        onChange: event =>
          handleChange({
            selected: event.target.value,
            parameterId,
          }),
      }}
    />
  );
};

export { ParameterOptionSelect };
