import { RequestState } from '../../../Services/Api/models';

import { AuctionItemsTypes } from '../types';
import { AuctionItemPutActions } from '../actions';

export type AuctionItemPutState = RequestState;

const initialState: AuctionItemPutState = {
  errorMsg: '',
  isDone: false,
  hasError: false,
  isLoading: false,
};

export function auctionItemPutReducer(
  state = initialState,
  action: AuctionItemPutActions,
): AuctionItemPutState {
  switch (action.type) {
    case AuctionItemsTypes.FAVORITE_AUCTION_ITEM_REQUEST: {
      return {
        isLoading: true,
        isDone: false,
        hasError: false,
        errorMsg: '',
      };
    }
    case AuctionItemsTypes.FAVORITE_AUCTION_ITEM_SUCCESS: {
      return {
        isLoading: false,
        isDone: true,
        hasError: false,
        errorMsg: '',
      };
    }
    case AuctionItemsTypes.FAVORITE_AUCTION_ITEM_FAILURE: {
      return {
        isLoading: false,
        isDone: true,
        hasError: true,
        errorMsg: action.payload,
      };
    }
    case AuctionItemsTypes.UNFAVORITE_AUCTION_ITEM_REQUEST: {
      return {
        isLoading: true,
        isDone: false,
        hasError: false,
        errorMsg: '',
      };
    }
    case AuctionItemsTypes.UNFAVORITE_AUCTION_ITEM_SUCCESS: {
      return {
        isLoading: false,
        isDone: true,
        hasError: false,
        errorMsg: '',
      };
    }
    case AuctionItemsTypes.UNFAVORITE_AUCTION_ITEM_FAILURE: {
      return {
        isLoading: false,
        isDone: true,
        hasError: true,
        errorMsg: action.payload,
      };
    }
    case AuctionItemsTypes.GIVE_BID_REQUEST: {
      return {
        isLoading: true,
        isDone: false,
        hasError: false,
        errorMsg: '',
      };
    }
    case AuctionItemsTypes.GIVE_BID_SUCCESS: {
      return {
        isLoading: false,
        isDone: true,
        hasError: false,
        errorMsg: '',
      };
    }
    case AuctionItemsTypes.GIVE_BID_FAILURE: {
      return {
        isLoading: false,
        isDone: true,
        hasError: true,
        errorMsg: action.payload,
      };
    }
    default:
      return state;
  }
}
