import { RequestState } from '../../../Services/Api/models';

import { UserTypes } from '../types';
import { UserActions } from '../actions';

export type UserCreateState = RequestState;

const initialState: UserCreateState = {
  isLoading: false,
  isDone: false,
  hasError: false,
  errorMsg: '',
};

export function userCreateReducer(
  state = initialState,
  action: UserActions,
): UserCreateState {
  switch (action.type) {
    case UserTypes.CREATE_USER_REQUEST: {
      return {
        errorMsg: '',
        isDone: false,
        isLoading: true,
        hasError: false,
      };
    }
    case UserTypes.CREATE_USER_SUCCESS: {
      return {
        errorMsg: '',
        isDone: true,
        isLoading: false,
        hasError: false,
      };
    }
    case UserTypes.CREATE_USER_FAILURE: {
      const errorMsg = action.payload;
      return {
        errorMsg,
        isDone: true,
        isLoading: false,
        hasError: true,
      };
    }
    default:
      return state;
  }
}
