import React, { useEffect } from 'react';
import { FormikProps } from 'formik';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  CnpjTextField,
  EmailTextField,
  DefaultTextField,
  PasswordTextField,
} from '../../../../../Components/TextFields';
import { Alert } from '../../../../../Components/Alert';
import { RoundedButton } from '../../../../../Components/Buttons';

import { USER_TYPES } from '../../../../../Common/enums';

import { CreateUserActionRequest } from '../../../../../Redux/User/actions';

import * as S from './RegisterComponent.styled';

import {
  yourFullName,
  whichProfile,
  weWillUseYourData,
  registerGuidelines,
  registerCapitalized,
  yourRegisterOnOurPlatform,
  investorFirstLetterCapitalized,
  providerFirstLetterCapitalized,
} from '../../../../../Common/strings';

export interface SignUpProps {
  open: boolean;
  errorMsg: string;
  isSubmitting: boolean;
  onCloseAlert: () => void;
  formik: FormikProps<CreateUserActionRequest>;
}

const RegisterComponent: React.FC<SignUpProps> = (props: SignUpProps) => {
  const { open, formik, isSubmitting, errorMsg, onCloseAlert } = props;

  const [value, setValue] = React.useState('fornecedor');

  const classes = S.useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    formik.setFieldValue('role', value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <S.Container>
      <S.Title>{registerCapitalized}</S.Title>
      <S.Subtitle>{registerGuidelines}</S.Subtitle>
      <S.InterTitle>{weWillUseYourData}</S.InterTitle>
      <S.Form onSubmit={formik.handleSubmit}>
        <DefaultTextField
          name="full_name"
          placeholder={yourFullName}
          value={formik.values.full_name}
          onChange={formik.handleChange}
          style={{ gridColumn: 'span 6', marginBottom: '24px' }}
          error={formik.touched.full_name && Boolean(formik.errors.full_name)}
          helperText={formik.touched.full_name && formik.errors.full_name}
        />
        <CnpjTextField
          value={formik.values.cnpj}
          onChange={formik.handleChange}
          style={{ gridColumn: 'span 6', marginBottom: '24px' }}
          error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
          helperText={formik.touched.cnpj && formik.errors.cnpj}
        />
        <EmailTextField
          value={formik.values.email}
          onChange={formik.handleChange}
          style={{ gridColumn: 'span 6', marginBottom: '24px' }}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <PasswordTextField
          value={formik.values.password}
          onChange={formik.handleChange}
          style={{ gridColumn: 'span 6', marginBottom: '24px' }}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <S.WitchProfileText>{whichProfile}</S.WitchProfileText>
        <RadioGroup
          value={value}
          onChange={handleChange}
          className={classes.radioGroup}
        >
          <FormControlLabel
            value={USER_TYPES.FORNECEDOR}
            control={<Radio color="primary" />}
            className={classes.providerButton}
            label={providerFirstLetterCapitalized}
          />
          <FormControlLabel
            value={USER_TYPES.INVESTIDOR}
            control={<Radio color="primary" />}
            className={classes.investorButton}
            label={investorFirstLetterCapitalized}
          />
        </RadioGroup>
        <S.RoundedButtonWrapper>
          <RoundedButton
            variant="primary"
            isLoading={isSubmitting}
            text={registerCapitalized}
            onClick={() => formik.handleSubmit()}
          />
        </S.RoundedButtonWrapper>
      </S.Form>
      <S.IllegalInfoText>{yourRegisterOnOurPlatform}</S.IllegalInfoText>
      <Alert
        open={open}
        severity="error"
        text={errorMsg}
        onClose={onCloseAlert}
      />
    </S.Container>
  );
};

export { RegisterComponent };
