import { call, put } from 'redux-saga/effects';

import { SellingOrderApi } from '../../Services/Api/SellingOrder';

import { SellingOrderRetrieveActionsCreators } from '../../Redux/SellingOrder/actions';

export function* retrieveAllSellingOrder(
  api: SellingOrderApi,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: ReturnType<
    typeof SellingOrderRetrieveActionsCreators.sellingOrderRetrieveAllRequest
  >,
): any {
  const { ok, data, problem } = yield call([api, api.retrieveSellingOrders]);

  if (ok) {
    yield put(
      SellingOrderRetrieveActionsCreators.sellingOrderRetrieveAllSuccess(
        data.data,
      ),
    );
  } else {
    const message = data && data.message ? data.message : `${problem}`;

    yield put(
      SellingOrderRetrieveActionsCreators.sellingOrderRetrieveAllFailure(
        message,
      ),
    );
  }
}
