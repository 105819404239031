import { combineReducers } from 'redux';

import { InvoiceRetrieveAllState, invoiceRetrieveAllReducer } from './all';

export interface InvoiceRetrieveState {
  all: InvoiceRetrieveAllState;
}

const reducer = combineReducers<InvoiceRetrieveState>({
  all: invoiceRetrieveAllReducer,
});

export { reducer as invoiceRetrieveReducer };
