import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

interface Props extends AlertProps {
  text?: string;
  open: boolean;
  onClose: () => void;
}

const Alert: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const { open, text, onClose, ...rest } = props;
  return (
    <Snackbar open={open} onClose={onClose} autoHideDuration={5000}>
      <MuiAlert
        elevation={6}
        variant="filled"
        className={classes.root}
        {...rest}
      >
        {text}
      </MuiAlert>
    </Snackbar>
  );
};

const useStyles = makeStyles({
  root: {
    width: '50vw',
  },
});

export { Alert };
