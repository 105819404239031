import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DialogContentText from '@material-ui/core/DialogContentText';

import * as S from './TitleWithGoBack.styled';

import { BidButton } from '../Components/Styles/AuctionItemCard.styled';

import { GiveBidDialog } from './GiveBidDialog';

import { FingerUpIcon } from '../../../../../Components/Icons';
import { StarIcon } from '../../../../../Components/Icons/StarIcon';
import { ConfirmDialog } from '../../../../../Components/Dialog/ConfirmDialog';

import {
  lotResume,
  backFirstLetterCapitalized,
  giveAnBidFirstLetterCapitalized,
} from '../../../../../Common/strings';

interface Props {
  isFilled: boolean;
  providerName: string;
  openBidDialog: boolean;
  openFollowDialog: boolean;
  onBidClick: () => void;
  onGoBackClick: () => void;
  onFollowClick: () => void;
  onCancelBidDialogClick: () => void;
  onConfirmBidDialogClick: () => void;
  onCancelFollowDialogClick: () => void;
  onConfirmFollowDialogClick: () => void;
}

const TitleWithGoBack: React.FC<Props> = (props: Props) => {
  const {
    isFilled,
    providerName,
    openBidDialog,
    openFollowDialog,
    onGoBackClick,
    onBidClick,
    onFollowClick,
    onCancelBidDialogClick,
    onConfirmBidDialogClick,
    onCancelFollowDialogClick,
    onConfirmFollowDialogClick,
  } = props;

  return (
    <>
      <S.Container>
        <Tooltip title="Voltar" arrow>
          <IconButton
            size="small"
            onClick={onGoBackClick}
            aria-label={backFirstLetterCapitalized}
            style={{ marginRight: '24px' }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Tooltip>
        <S.Title>{lotResume}</S.Title>
        <p>
          <strong>Fornecedor: </strong>
          {`${providerName}`}
        </p>

        <S.BidButtonContainer>
          <BidButton variant="secondary" onClick={onBidClick}>
            <FingerUpIcon />
            <p>{giveAnBidFirstLetterCapitalized}</p>
          </BidButton>
        </S.BidButtonContainer>

        <S.FollowButtonContainer>
          <Tooltip title="Acompanhar" arrow>
            <IconButton
              size="small"
              aria-label="Acompanhar"
              onClick={onFollowClick}
            >
              <StarIcon isFilled={isFilled} />
            </IconButton>
          </Tooltip>
        </S.FollowButtonContainer>
      </S.Container>
      <GiveBidDialog
        open={openBidDialog}
        onCancelClick={onCancelBidDialogClick}
        onConfirmClick={onConfirmBidDialogClick}
      />

      <ConfirmDialog
        fullWidth
        maxWidth="sm"
        title="Acompanhar"
        open={openFollowDialog}
        onCancelClick={onCancelFollowDialogClick}
        onConfirmClick={onConfirmFollowDialogClick}
      >
        <DialogContentText id="alert-dialog-description">
          {isFilled
            ? 'Você realmente deseja deixar de acompanhar este leilão ?'
            : 'Você realmente deseja acompanhar este leilão ?'}
        </DialogContentText>
      </ConfirmDialog>
    </>
  );
};

export { TitleWithGoBack };
