import React from 'react';

import { Container, Content, Title, Text } from './styles';

import {
  registerAnalyze,
  thanksForRegistering,
  youWillReceiveConfirmationEmail,
} from '../../../../Common/strings';

const ConclusionStep: React.FC = () => {
  return (
    <Container>
      <Content>
        <Title>{thanksForRegistering}</Title>
        <Text>{registerAnalyze}</Text>
        <Text>{youWillReceiveConfirmationEmail}</Text>
      </Content>
    </Container>
  );
};

export { ConclusionStep };
