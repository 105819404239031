import React from 'react';

import MUIDataTable, { MUIDataTableProps } from 'mui-datatables';

import {
  sorryNoRecordsFounded,
  printFirstLetterCapitalized,
  searchFirstLetterCapitalized,
  nextPageFirstLetterCapitalized,
  rowsPerPageFirstLetterCapitalized,
  filterTableFirstLetterCapitalized,
  previousPageFirstLetterCapitalized,
} from '../../Common/strings';

type Diff<T, K> = Omit<T, keyof K>;

interface Props extends Diff<MUIDataTableProps, { data: any; title: string }> {
  title?: string;
  data: (any | number[] | string[])[] | undefined;
}

const Table: React.FC<Props> = (props: Props) => {
  const { title, data, columns, options, ...rest } = props;
  return (
    <>
      {data && (
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={{
            textLabels: {
              body: {
                noMatch: sorryNoRecordsFounded,
              },
              pagination: {
                next: nextPageFirstLetterCapitalized,
                previous: previousPageFirstLetterCapitalized,
                rowsPerPage: rowsPerPageFirstLetterCapitalized,
              },
              toolbar: {
                search: searchFirstLetterCapitalized,
                print: printFirstLetterCapitalized,
                filterTable: filterTableFirstLetterCapitalized,
              },
            },
            ...options,
          }}
          {...rest}
        />
      )}
    </>
  );
};

export { Table };
