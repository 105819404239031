import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Lot as LotEntity } from '../../../Redux/Lot/Entity';
import { LotCreateStateSelectors } from '../../../Redux/Lot/selectors';
import {
  LotCreateActionsCreators,
  LotRetrieveActionsCreators,
} from '../../../Redux/Lot/actions';

import { Dialog } from '../../../Components/Dialog';
import { DefaultButton } from '../../../Components/Buttons';
import { DROPZONE_MAX_FILES } from '../../../Components/Dropzone';
import { LotList, AddInvoiceDropzone, ExpectedRateDialog } from './Components';

import {
  ok,
  addFirstLetterCapitalized,
  yourInvoiceInUnderAnalysis,
  successfulSubmittedInvoice,
  refreshFirstLetterCapitalized,
} from '../../../Common/strings';

interface Props {
  lots: LotEntity[];
  isLoading: boolean;
}

const LotListPageProvider: React.FC<Props> = (props: Props) => {
  const { lots, isLoading } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDone = useSelector(LotCreateStateSelectors.selectIsDone);
  const errorMsg = useSelector(LotCreateStateSelectors.selectErrorMsg);

  const [files, setFiles] = React.useState<File[]>([]);
  const [openDropzone, setOpenDropzone] = React.useState<boolean>(false);
  const [
    openSubmittedInvoiceDialog,
    setOpenSubmittedInvoiceDialog,
  ] = React.useState<boolean>(false);
  const [openExpectedRateDialog, setOpenExpectedRateDialog] = React.useState<
    boolean
  >(false);

  const differenceBetweenArrays = useCallback(
    (arrA: File[], arrB: File[]) =>
      arrA.filter(
        (itemA: File) => !arrB.some((itemB: File) => itemB.name === itemA.name),
      ),
    [],
  );

  const hasReachedLimitOfFiles = useCallback(
    (acceptedFiles: File[]) =>
      files.length + acceptedFiles.length > DROPZONE_MAX_FILES,
    [files.length],
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (hasReachedLimitOfFiles(acceptedFiles)) {
        return;
      }

      const difference = differenceBetweenArrays(acceptedFiles, files);

      setFiles([...files, ...difference]);
    },
    [differenceBetweenArrays, files, hasReachedLimitOfFiles],
  );

  const handleDeleteFile = useCallback(
    (file: File) => {
      const filteredFiles = files.filter(item => item.name !== file.name);
      setFiles(filteredFiles);
    },
    [files],
  );

  useEffect(() => {
    if (isDone && !errorMsg) {
      setOpenSubmittedInvoiceDialog(true);
      dispatch(LotRetrieveActionsCreators.lotRetrieveAllRequest());
    }
  }, [dispatch, errorMsg, isDone]);

  const handleOkButtonClickSubmittedInvoiceDialog = useCallback(() => {
    setOpenSubmittedInvoiceDialog(false);
    setOpenDropzone(false);
    setFiles([]);
    dispatch(LotCreateActionsCreators.lotCreateInitialState());
  }, [dispatch]);

  const handleExpectedRateDialogOkClick = (expectedRate: number): void => {
    setOpenExpectedRateDialog(false);
    uploadInvoices(files, expectedRate);
  };

  const uploadInvoices = (invoices: File[], expectedRate: number): void => {
    dispatch(
      LotCreateActionsCreators.lotCreateRequest({ invoices, expectedRate }),
    );
  };

  const handleRefreshLotClick = (): void => {
    dispatch(LotRetrieveActionsCreators.lotRetrieveAllRequest());
  };

  return (
    <>
      <div
        style={{
          display: 'inline-grid',
          gridTemplateColumns: 'max-content max-content',
          marginBottom: '32px',
        }}
      >
        <Button
          onClick={() => setOpenDropzone(true)}
          className={classes.addButton}
        >
          {addFirstLetterCapitalized}
        </Button>
        <DefaultButton
          endIcon={<RefreshIcon />}
          text={refreshFirstLetterCapitalized}
          variant_type="secondary"
          onClick={handleRefreshLotClick}
        />
      </div>
      <AddInvoiceDropzone
        files={files}
        open={openDropzone}
        onDrop={handleDrop}
        onDeleteClick={handleDeleteFile}
        onClose={() => setOpenDropzone(false)}
        onOkButtonClick={() => setOpenExpectedRateDialog(true)}
      />
      <Dialog
        confirmButtonText={ok}
        title={successfulSubmittedInvoice}
        open={openSubmittedInvoiceDialog}
        text={yourInvoiceInUnderAnalysis}
        onClose={handleOkButtonClickSubmittedInvoiceDialog}
      />
      <ExpectedRateDialog
        open={openExpectedRateDialog}
        onOkClick={handleExpectedRateDialogOkClick}
        onCancelClick={() => setOpenExpectedRateDialog(false)}
      />
      <LotList lots={lots} isLoading={isLoading} />
    </>
  );
};

const useStyles = makeStyles({
  addButton: {
    fontSize: 14,
    marginRight: '16px',
    fontWeight: 500,
    width: '110px',
    height: '36px',
    color: 'white',
    background: '#2C98F0',
    fontFamily: 'Roboto Slab',
  },
});

export { LotListPageProvider };
