import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { AuthRoute } from './AuthRoute';

import { USER_ROLE } from '../Redux/User/Entity';

import { Sample } from '../Pages/Sample';
import { SignInPage } from '../Pages/SignIn';
import { SignUpPage } from '../Pages/SignUp';
import { MainContainer } from '../Pages/Main';
import { LotListPage } from '../Pages/Main/Lot';
import { ForgotPasswordPage } from '../Pages/ForgotPassword';

import { InvoiceListPage, InvoiceEvaluatePage } from '../Pages/Main/Invoice';
import { InvestorListPage, InvestorEvaluatePage } from '../Pages/Main/Investor';
import {
  AuctionListPage,
  AuctionInvoiceListPage,
  AuctionInvoiceDetailsPage,
} from '../Pages/Main/Auction';
import {
  ProviderListPage,
  ProviderEvaluatePage,
  ProviderUploadEvaluatePage,
} from '../Pages/Main/Provider';
import {
  SellingOrderPage,
  SellingOrderInvoiceListPage,
} from '../Pages/Main/SellingOrder';

export enum AuthRoutes {
  auction = '/leilao',
  dashboard = '/dashboard',
  provider = '/fornecedor',
  investor = '/investidor',
  backoffice = '/backoffice',
  lots = '/lotes',
  invoice = 'nota',
  sellingOrders = '/ordem-vendas',
}

enum NonAuthRoutes {
  signin = '/',
  sample = '/sample',
  signup = '/signup',
}

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path={NonAuthRoutes.signin} exact component={SignInPage} />
      <Route path={NonAuthRoutes.sample} exact component={Sample} />
      <Route path={NonAuthRoutes.signup} exact component={SignUpPage} />
      <Route path="/esqueci-senha" exact component={ForgotPasswordPage} />
      <MainContainer>
        <AuthRoute
          exact
          isPrivate
          path={`${AuthRoutes.backoffice}/fornecedores`}
          component={ProviderListPage}
          requiredRoles={[USER_ROLE.backOffice, USER_ROLE.admin]}
        />
        <AuthRoute
          exact
          isPrivate
          path={`${AuthRoutes.backoffice}/investidores`}
          component={InvestorListPage}
          requiredRoles={[USER_ROLE.backOffice, USER_ROLE.admin]}
        />
        <AuthRoute
          exact
          isPrivate
          path={`${AuthRoutes.backoffice}${AuthRoutes.investor}/avaliacao`}
          component={InvestorEvaluatePage}
          requiredRoles={[USER_ROLE.backOffice, USER_ROLE.admin]}
        />
        <AuthRoute
          isPrivate
          exact
          path={`${AuthRoutes.backoffice}${AuthRoutes.provider}/avaliacao`}
          component={ProviderEvaluatePage}
          requiredRoles={[USER_ROLE.backOffice, USER_ROLE.admin]}
        />
        <AuthRoute
          isPrivate
          exact
          path={`${AuthRoutes.backoffice}${AuthRoutes.lots}`}
          component={LotListPage}
          requiredRoles={[USER_ROLE.backOffice, USER_ROLE.admin]}
        />
        <AuthRoute
          isPrivate
          exact
          path={`${AuthRoutes.backoffice}${AuthRoutes.lots}/nota/avaliacao`}
          component={InvoiceEvaluatePage}
          requiredRoles={[USER_ROLE.backOffice, USER_ROLE.admin]}
        />
        <AuthRoute
          isPrivate
          exact
          path={`${AuthRoutes.backoffice}${AuthRoutes.auction}`}
          component={AuctionListPage}
          requiredRoles={[USER_ROLE.backOffice, USER_ROLE.admin]}
        />
        <AuthRoute
          isPrivate
          exact
          path={`${AuthRoutes.backoffice}${AuthRoutes.auction}/:id/notas`}
          component={AuctionInvoiceListPage}
          requiredRoles={[USER_ROLE.backOffice, USER_ROLE.admin]}
        />
        <AuthRoute
          isPrivate
          exact
          path={`${AuthRoutes.backoffice}${AuthRoutes.auction}/${AuthRoutes.lots}/nota/detalhes`}
          component={AuctionInvoiceDetailsPage}
          requiredRoles={[USER_ROLE.backOffice, USER_ROLE.admin]}
        />
        <AuthRoute
          isPrivate
          exact
          path={`${AuthRoutes.backoffice}${AuthRoutes.sellingOrders}`}
          component={SellingOrderPage}
          requiredRoles={[USER_ROLE.backOffice, USER_ROLE.admin]}
        />
        <AuthRoute
          isPrivate
          exact
          path={`${AuthRoutes.backoffice}${AuthRoutes.sellingOrders}/:id/notas`}
          component={SellingOrderInvoiceListPage}
          requiredRoles={[USER_ROLE.backOffice, USER_ROLE.admin]}
        />
        <AuthRoute
          isPrivate
          exact
          path={`${AuthRoutes.provider}${AuthRoutes.lots}/nota/analise`}
          component={InvoiceEvaluatePage}
          requiredRoles={[USER_ROLE.provider, USER_ROLE.admin]}
        />
        <AuthRoute
          exact
          isPrivate
          path={`${AuthRoutes.backoffice}${AuthRoutes.lots}/:id/notas`}
          component={InvoiceListPage}
          requiredRoles={[USER_ROLE.backOffice, USER_ROLE.admin]}
        />
        <AuthRoute
          exact
          isPrivate
          path={`${AuthRoutes.investor}${AuthRoutes.auction}`}
          component={AuctionListPage}
          requiredRoles={[USER_ROLE.investor]}
        />
        <AuthRoute
          exact
          isPrivate
          path={`${AuthRoutes.investor}${AuthRoutes.auction}/:id/notas`}
          component={AuctionInvoiceListPage}
          requiredRoles={[USER_ROLE.investor]}
        />
        <AuthRoute
          isPrivate
          exact
          path={`${AuthRoutes.investor}${AuthRoutes.auction}${AuthRoutes.lots}/nota/detalhes`}
          component={AuctionInvoiceDetailsPage}
          requiredRoles={[USER_ROLE.investor]}
        />
        <AuthRoute
          exact
          isPrivate
          path={`${AuthRoutes.provider}/lotes`}
          component={LotListPage}
          requiredRoles={[USER_ROLE.provider]}
        />
        <AuthRoute
          exact
          isPrivate
          path={`${AuthRoutes.provider}/validacao`}
          component={ProviderUploadEvaluatePage}
          requiredRoles={[USER_ROLE.provider]}
        />
        <AuthRoute
          exact
          isPrivate
          path={`${AuthRoutes.provider}${AuthRoutes.lots}/:id/notas`}
          component={InvoiceListPage}
          requiredRoles={[USER_ROLE.provider]}
        />
      </MainContainer>
    </Switch>
  );
};

export { Routes };
