import { RequestState } from '../../../Services/Api/models';

import { ParameterTypes } from '../types';
import { ParameterActions } from '../actions';

export type ParameterRetrieveAllState = RequestState;

const initialState: ParameterRetrieveAllState = {
  isLoading: false,
  isDone: false,
  hasError: false,
  errorMsg: '',
};

export function parameterRetrieveAllReducer(
  state = initialState,
  action: ParameterActions,
): ParameterRetrieveAllState {
  switch (action.type) {
    case ParameterTypes.RETRIEVE_PARAMETERS_REQUEST: {
      return {
        errorMsg: '',
        isDone: false,
        isLoading: true,
        hasError: false,
      };
    }
    case ParameterTypes.RETRIEVE_PARAMETERS_SUCCESS: {
      return {
        isDone: true,
        errorMsg: '',
        hasError: false,
        isLoading: false,
      };
    }
    case ParameterTypes.RETRIEVE_PARAMETERS_FAILURE: {
      const errorMsg = action.payload;
      return {
        errorMsg,
        isDone: true,
        hasError: true,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
