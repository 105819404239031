export enum COLORS {
  BLACK = '#000',
  WHITE = '#FFF',
  GRAY = '#999591',
  LIGHTEST_GRAY = '#6D6D6D',
  DARKEST_GRAY = '#353535',
  GREEN = '#80C14D',
  BLUE = '#173A5A',
  LIGHTEST_ORANGE = '#FFA412',
  ORANGE = '#FF9000',
  MEDIUM_BLUE = '#2B478B',
  LIGHTEST_BLUE = '#00CAF9',
  PRIMARY_BLUE = '#2C98F0',
  RED = '#D60404',
}
