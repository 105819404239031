import { ApiInstance, getApiInstance } from './Api';

import { LotApi, getLotApiInstance } from './Api/Lot';
import { AuthApi, getAuthApiInstance } from './Api/Auth';
import { UserApi, getUserApiInstance } from './Api/User';
import { AuctionApi, getAuctionApiInstance } from './Api/Auction';
import { AddressApi, getAddressApiInstance } from './Api/Address';
import { ParameterApi, getParameterApiInstance } from './Api/Parameter';
import {
  SellingOrderApi,
  getSellingOrdersApiInstance,
} from './Api/SellingOrder';

export interface Apis {
  lot: LotApi;
  auth: AuthApi;
  user: UserApi;
  address: AddressApi;
  auction: AuctionApi;
  parameter: ParameterApi;
  sellingOrder: SellingOrderApi;
}

let instance: Apis;

export function getApisInstance(apiParam?: ApiInstance): Apis {
  if (!instance) {
    const api = apiParam || getApiInstance();

    instance = {
      lot: getLotApiInstance(api),
      user: getUserApiInstance(api),
      auth: getAuthApiInstance(api),
      auction: getAuctionApiInstance(api),
      address: getAddressApiInstance(api),
      parameter: getParameterApiInstance(api),
      sellingOrder: getSellingOrdersApiInstance(api),
    };
  }
  return instance;
}
