import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { Dropzone } from '../../../../Components/Dropzone';

import {
  ok,
  addInvoice,
  onlyXMLFiles,
  moreThanOneInvoice,
} from '../../../../Common/strings';

interface Props {
  files: File[];
  open: boolean;
  onClose: () => void;
  onOkButtonClick: () => void;
  onDeleteClick: (file: File) => void;
  onDrop: (acceptedFiles: File[]) => void;
}

const AddInvoiceDropzone: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    files,
    open,
    onDrop,
    onClose,
    onOkButtonClick,
    onDeleteClick,
  } = props;

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{addInvoice}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-moreThanOneInvoice">
          {moreThanOneInvoice}
        </DialogContentText>
        <DialogContentText id="alert-dialog-onlyXML">
          {onlyXMLFiles}
        </DialogContentText>
        <Dropzone files={files} onDrop={onDrop} onDeleteClick={onDeleteClick} />
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.okButton}
          disabled={files.length === 0}
          onClick={onOkButtonClick}
        >
          {ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  okButton: {
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    fontSize: 14,
    color: '#2196F3',
  },
});

export { AddInvoiceDropzone };
