import styled from 'styled-components';

export const Container = styled.div`
  z-index: 120;
  position: relative;
  border-radius: 4px;
  background-color: var(--color-light-gray-2);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

export const Content = styled.div`
  display: flex;
  min-height: 64px;
  align-items: center;
  padding: 8px 0px 8px 0px;
  margin: 0px 16px 0px 16px;
  justify-content: space-between;
`;
