export const loginFirstLetterCapitalized = 'Login';
export const requiredEmail = 'E-mail obrigatório';
export const emailFirstLetterCapitalized = 'E-mail';
export const writeValidEmail = 'Digite um e-mail válido';
export const passwordFirstLetterCapitalized = 'Senha';
export const requiredPassword = 'Senha obrigatória';
export const minPasswordLength = 'Sua senha deve conter no mínimo 8 caracteres';
export const maxPasswordLength =
  'Sua senha deve conter no máximo 20 caracteres';
export const forgotPassword = 'Esqueci minha senha';
export const landingPageMessage = `Antecipe sua Nota Fiscal<br>Obtenha as melhores ofertas de taxa 100%
Digital e seguro<br>Contratos com assinatura eletrônica<br>Painel online
de acompanhamento<br>Confidencialidade`;
export const backFirstLetterCapitalized = 'Voltar';
export const resetPasswordMessage = `Digite abaixo o e-mail cadastrado. Enviaremos
uma nova senha para recuperar seu acesso`;
export const resetPasswordTitle = 'REDEFINIR SENHA';
export const getNewPassword = 'Receber nova senha';
export const thanksForRegistering = 'Obrigado por se cadastrar.';
export const registerAnalyze =
  'A análise do seu cadastro pode durar em torno de 1 hora a 2 horas.';
export const youWillReceiveConfirmationEmail =
  'Você receberá um e-mail confirmando ou não o sucesso do seu cadastro';
export const provideYourInformationWithSafety =
  'Forneça suas informações com segurança';
export const ifYourCompany = `Se a sua empresa trabalha com venda de mercadorias a longo prazo,
obtenha crédito rápido ao submeter sua nota a leilão.`;
export const registerCapitalized = 'CADASTRO';
export const registerGuidelines = 'Diretrizes para o cadastramento: ';
export const weWillUseYourData = `Utilizaremos os seus dados apenas para fim de validação e comprovação
de sua empresa.`;
export const yourFullName = 'Seu nome completo';
export const whichProfile = 'Em qual perfil você quer se cadastrar?';
export const investorFirstLetterCapitalized = 'Investidor';
export const investorsFirstLetterCapitalized = 'Investidores';
export const providerFirstLetterCapitalized = 'Fornecedor';
export const providersFirstLetterCapitalized = 'Fornecedores';
export const yourRegisterOnOurPlatform = `O cadastro em nossa plataforma acarreta no seu comprometimento de que
estará fornecendo informações legais. Em caso de ilegalidade medidas
judiciais poderão ser tomadas.`;
export const nameRequired = 'Nome obrigatório';
export const cnpjRequired = 'CNPJ obrigatório';
export const invalidCnpj = 'CNPJ inválido';
export const notificationsFirstLetterCapitalized = 'Notificações';
export const profileFirstLetterCapitalized = 'Perfil';
export const underAnalysisFirstLetterCapitalized = 'Em análise';
export const approvedFirstLetterCapitalized = 'Aprovados';
export const refusedFirstLetterCapitalized = 'Recusados';
export const evaluateCapitalized = 'AVALIAR';
export const refuseCapitalized = 'RECUSAR';
export const preRegistersFirstLetterCapitalized = 'Pré-Cadastros';
export const providersEvaluateFirstLetterCapitalized =
  'Avaliação de Fornecedores';
export const approveCapitalized = 'APROVAR';
export const commentFirstLetterCapitalized = 'Comentário';
export const commentaryFirstLetterCapitalized = 'Comentar';
export const seeCommentsCapitalized = 'VER COMENTÁRIOS';
export const cpfCnpjCapitalized = 'CPF/CNPJ';
export const apiDataFirstLetterCapitalized = 'Dados da API';
export const preRegisterFirstLetterCapitalized = 'Pré-Cadastro';
export const socialReasonFirstLetterCapitalized = 'Razão Social';
export const cepCapitalized = 'CEP';
export const addressFirstLetterCapitalized = 'Endereço';
export const numberFirstLetterCapitalized = 'Número';
export const complementFirstLetterCapitalized = 'Complemento';
export const neighborhoodFirstLetterCapitalized = 'Bairro';
export const cityFirstLetterCapitalized = 'Cidade';
export const ufCapitalized = 'UF';
export const commercialPhoneFirstLetterCapitalized = 'Telefone Comercial';
export const firstContactFirstLetterCapitalized = 'Contato 1';
export const mobilePhoneFirstLetterCapitalized = 'Telefone Celular';
export const secondContactFirstLetterCapitalized = 'Contato 2';
export const serasaFirstLetterCapitalized = 'Serasa';
export const receitaFederalFirstLetterCapitalized = 'Receita Federal';
export const judicialBlockFirstLetterCapitalized = 'Bloqueio Judicial';
export const googleFirstLetterCapitalized = 'Google';
export const googleEarthFirstLetterCapitalized = 'Google Earth';
export const commercialSiteFirstLetterCapitalized = 'Site comercial';
export const reclameAquiFirstLetterCapitalized = 'Reclame Aqui';
export const dtFundacaoFirstLetterCapitalized = 'Dt fundação';
export const partnerTimeFirstLetterCapitalized = 'Tempo de sócio na empresa';
export const investorsEvaluateFirstLetterCapitalized =
  'Avaliação de Investidores';
export const signUpCapitalized = 'CADASTRAR';
export const endsInFirstLetterCapitalized = 'Finaliza em';
export const bidsFirstLetterCapitalized = 'Lances';
export const bestBidFirstLetterCapitalized = 'Melhor lance';
export const followFirstLetterCapitalized = 'Acompanhar';
export const removeBidFirstLetterCapitalized = 'Retirar lance';
export const ok = 'ok';
export const youAreFollowingTheAuction = 'Você está acompanhando este leilão';
export const goToAccompanimentsTab = `Vá até a aba de acompanhamentos e visualize as atualizações deste
leilão. Para deixar de acompanhar basta clicar no botão novamente`;
export const giveAnBidFirstLetterCapitalized = 'Dar um lance';
export const selectedAuctionsFirstLetterCapitalized = 'Leilões Selecionados';
export const auctionStatusFirstLetterCapitalized = 'Status do Leilão';
export const activateAuctionsCapitalized = 'LEILÕES ATIVOS';
export const participateCapitalized = 'PARTICIPANDO';
export const followingCapitalized = 'ACOMPANHANDO';
export const historyCapitalized = 'HISTÓRICO';
export const provideABetterValue = 'Forneça um valor melhor que o atual';
export const bidInPercent = 'Lance em %';
export const inProgressFirstLetterCapitalized = 'Em andamento';
export const thirdMinutesLeft = 'Em andamento menos de 30 min';
export const toNegotiate = 'Para negociar';
export const welcomeToSecondStep = `Seja Bem-vindo a nossa segunda etapa do processo
de validação decadastro.`;
export const makeUploadOfRequiredFiles = `Agora faça o upload dos documentos solicitados no local indicado, para
que possamos fazer uma análise manual de toda a documentação`;
export const renameTheDocuments = `* Renomei os documentos de acordo com os nomes das documentações
exigidas abaixo`;
export const necessaryDocumentation = 'Documentação necessária';
export const cancelFirstLetterCapitalized = 'Cancelar';
export const finishFirstLetterCapitalized = 'Finalizar';
export const dragDropFiles = 'Arraste e solte os documentos aqui';
export const clickToSelect = 'Ou clique aqui para selecionar';
export const addMore = 'Adicionar mais';
export const nextPageFirstLetterCapitalized = 'Próxima página';
export const previousPageFirstLetterCapitalized = 'Página anterior';
export const rowsPerPageFirstLetterCapitalized = 'Linhas por página';
export const searchFirstLetterCapitalized = 'Pesquisar';
export const printFirstLetterCapitalized = 'Imprimir';
export const filterTableFirstLetterCapitalized = 'Filtrar tabela';
export const sorryNoRecordsFounded =
  'Desculpe, mas não foi encontrado nenhum resultado.';
export const addInvoice = 'Adicionar Nota ou Lote';
export const moreThanOneInvoice =
  '*Caso haja mais de uma nota, as notas desta sessão serão agrupadas.';
export const onlyXMLFiles = '*Apenas serão aceitos arquivos XML.';
export const yourInvoiceInUnderAnalysis =
  'Sua nota está em análise, uma notificação e um e-mail será enviado com o resultado em até 3 horas';
export const successfulSubmittedInvoice = 'Nota submetida com sucesso';
export const addFirstLetterCapitalized = 'Adicionar';
export const auctionInvoice = 'Leiloar Nota / Lote';
export const provideTheBasicToStartAuction =
  'Forneça a base inicial ao leilão.';
export const expectedRate = 'Taxa esperada %';
export const cancelCapitalized = 'CANCELAR';
export const auctionCapitalized = 'LEILOAR';
export const excludeCapitalized = 'EXCLUIR';
export const invoicesAndLots = 'NOTAS E LOTES';
export const underAnalysisCapitalized = 'EM ANÁLISE';
export const lotResume = 'Resumo do Lote/Parte';
export const lotPart = 'Lote/Parte';
export const auctionQuantity = 'QTD/NF';
export const totalValueOfTheLot = 'Valor total do lote';
export const lotResumeCapitalized = 'RESUMO DO LOTE';
export const followCapitalized = 'ACOMPANHAR';
export const refusedProvider = 'Fornecedor reprovado';
export const approvedProvider = 'Fornecedor aprovado';
export const opsHasAnError = 'Opa, há algum erro';
export const successFirstLetterCapitalized = 'Sucesso';
export const verifyIfAllFieldsAreFilled =
  'Por favor, verifique se os campos foram preenchidos';
export const pleaseSelectTheParameter = 'Por favor, selecione o parâmetro';
export const refuseInvestor = 'Investidor recusado';
export const approvedInvestor = 'Investidor aprovado';
export const nameFirstLetterCapitalized = 'Nome';
export const emptyEvaluateLots =
  'Você ainda não possui nenhum lote para avaliar.';
export const requiredComment = 'Comentário obrigatório';
export const totalValue = 'Valor total';
export const productValue = 'Valor do produto';
export const shipping = 'Frete';
export const formOfPayment = 'Forma de pagamento';
export const insurance = 'Seguro';
export const successfulEvaluatedInvoice = 'Nota avaliada com sucesso !';
export const failureEvaluatedInvoice =
  'Não foi possível avaliar a nota. Tente novamente mais tarde';
export const expectedRateGreaterThenZero = 'A taxa deve ser maior que 0';
export const expectedRateLessThenOneHundred = 'A taxa deve ser menor que 100';
export const expectedRateRequired = 'Taxa obrigatória';
export const invalidCapitalized = 'INVÁLIDAS';
export const analyzeCapitalized = 'ANALISAR';
export const refreshFirstLetterCapitalized = 'Atualizar';
export const toAuctionCapitalized = 'A LEILOAR';
export const atAuctionCapitalized = 'EM LEILÃO';
export const auctionedCapitalized = 'LEILOADA';
