import React from 'react';

import * as S from './Score.styled';

interface Props {
  score: number;
}

const Score: React.FC<Props> = ({ score }: Props) => {
  return <S.CustomScore score={score} />;
};

export { Score };
