import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  padding: 16px 46px 42px 42px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

export const Header = styled.div`
  grid-column: span 12;
  display: flex;

  padding-bottom: 16px;
  align-items: center;

  border-bottom: 1px solid var(--color-mid-gray);
  h1 {
    color: var(--color-black);
  }
`;

export const HeaderTitle = styled.h1`
  white-space: nowrap;
  font-size: calc(var(--font-size-base) + 6px);
`;

export const InfosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column: span 12;
  grid-row-gap: 20px;
  border-bottom: 1px solid var(--color-mid-gray);
  padding-bottom: 16px;
`;

export const ReceiverWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column: span 12;
  grid-row-gap: 20px;
  margin-bottom: 24px;
`;

export const Title = styled.h2`
  font-size: calc(var(--font-size-base) + 6px);
  margin-top: 16px;
  grid-column: span 8;
  color: var(--color-black);
  grid-column: span 12;
`;

export const ValuesWrapper = styled.div`
  grid-column: span 4;
`;

export const Label = styled.p`
  color: var(--color-black);
  margin-bottom: 4px;
`;

export const Value = styled.p`
  font-size: calc(var(--font-size-base) - 2px);
  color: var(--color-gray);
`;

export const useStyles = makeStyles({
  commentsTextArea: {
    marginTop: '16px',
    gridColumn: 'span 4',
    width: 361,
    marginBottom: '42px',
  },
});
