import { AddressTypes } from '../types';
import { AddressActions } from '../actions';

export interface AddressCommon {
  cep: string;
  state: string;
  city: string;
  street: string;
  neighborhood: string;
}

export interface AddressRequest {
  cep: string;
}

export type AddressResponse = AddressCommon;

export interface Address extends AddressCommon {
  number?: string;
}

export type AddressEntityState = Address;

const initialState: AddressEntityState = {
  cep: '',
  city: '',
  state: '',
  number: '',
  street: '',
  neighborhood: '',
};

export function addressEntityReducer(
  state = initialState,
  action: AddressActions,
): AddressEntityState {
  switch (action.type) {
    case AddressTypes.RETRIEVE_ONE_ADDRESS_SUCCESS: {
      const {
        cep,
        state: cepState,
        city,
        neighborhood,
        street,
      } = action.payload;
      return {
        cep,
        city,
        street,
        neighborhood,
        state: cepState,
      };
    }
    default:
      return state;
  }
}
