import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { requiredEmail, writeValidEmail } from '../../Common/strings';

import { ForgotPassword } from './Components';

export interface ForgotPasswordValues {
  email: string;
}

const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();
  const initialValues: ForgotPasswordValues = { email: '' };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(writeValidEmail).required(requiredEmail),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => navigateTo({ screenName: '' }),
  });

  const navigateTo = useCallback(
    ({ screenName }) => {
      history.push(screenName);
    },
    [history],
  );

  return (
    <ForgotPassword
      formik={formik}
      goBack={() => navigateTo({ screenName: '/' })}
    />
  );
};

export { ForgotPasswordPage };
