import { call, put } from 'redux-saga/effects';

import { Parameter } from '../../Redux/Parameter/Entity';
import { ParameterApi } from '../../Services/Api/Parameter';
import { ParameterRetrieveActionsCreators } from '../../Redux/Parameter/actions';

export function* parameterRetrieveAll(
  api: ParameterApi,
  action: ReturnType<
    typeof ParameterRetrieveActionsCreators.parameterRetrieveAllRequest
  >,
): any {
  const role = action.payload;
  const { ok, data, problem } = yield call(
    [api, api.retrieveParametersByRole],
    role,
  );

  if (ok) {
    const parameters = formatResponseToParameters(data.data);

    yield put(
      ParameterRetrieveActionsCreators.parameterRetrieveAllSuccess(parameters),
    );
  } else {
    const message = data && data.message ? data.message : `${problem}`;
    yield put(
      ParameterRetrieveActionsCreators.parameterRetrieveAllFailure(message),
    );
  }
}

function formatResponseToParameters(response: any): Parameter[] {
  return response.map(({ _id, ...rest }: any) => ({
    id: _id,
    ...rest,
  }));
}
