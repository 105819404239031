import React from 'react';
import { orderBy } from 'lodash';

import { AuctionItem } from '../../../../../Redux/AuctionItem/Entity';

import { AuctionItemCard } from './AuctionItemCard';
import { Container } from './Styles/AuctionCardList.styled';

import { Loading } from '../../../../../Components/Loading';

interface Props {
  isLoading: boolean;
  auctionItems: AuctionItem[];
  onResumeClick: (id: string) => void;
  onFollowClick: (auctionId: string) => void;
  onGiveABidClick: (auctionId: string) => void;
}

const AuctionItemCardList: React.FC<Props> = (props: Props) => {
  const {
    auctionItems,
    isLoading,
    onResumeClick,
    onFollowClick,
    onGiveABidClick,
  } = props;
  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      {orderBy(auctionItems, ['amIWinning']).map((auctionItem: AuctionItem) => (
        <li key={auctionItem._id}>
          <AuctionItemCard
            auctionItem={auctionItem}
            onFollowClick={onFollowClick}
            onGiveABidClick={onGiveABidClick}
            onResumeClick={() => onResumeClick(`${auctionItem._id}`)}
          />
        </li>
      ))}
    </Container>
  );
};

export { AuctionItemCardList };
