import { call, put } from 'redux-saga/effects';

import { LotApi } from '../../Services/Api/Lot';
import {
  LotCreateActionsCreators,
  LotRetrieveActionsCreators,
} from '../../Redux/Lot/actions';
import { Lot } from '../../Redux/Lot/Entity';

export function* lotRetrieveAll(
  api: LotApi,
  action: ReturnType<typeof LotRetrieveActionsCreators.lotRetrieveAllRequest>,
): any {
  const { limited_at, invoiceStatus } = action.payload;
  const { ok, data, problem } = yield call([api, api.retrieveLots], {
    limited_at,
    status: invoiceStatus,
  });

  if (ok) {
    const lots: Lot[] = data.data.map(({ id_lot, ...rest }: any) => ({
      id: id_lot,
      ...rest,
    }));

    yield put(LotRetrieveActionsCreators.lotRetrieveAllSuccess(lots));
  } else {
    const message = data && data.message ? data.message : `${problem}`;
    yield put(LotRetrieveActionsCreators.lotRetrieveAllFailure(message));
  }
}

export function* lotPost(
  api: LotApi,
  action: ReturnType<typeof LotCreateActionsCreators.lotCreateRequest>,
): any {
  const { invoices, expectedRate } = action.payload;

  const { ok, data, problem } = yield call(
    [api, api.uploadInvoice],
    invoices,
    expectedRate,
  );

  if (ok) {
    yield put(LotCreateActionsCreators.lotCreateSuccess());
  } else {
    const message = data && data.message ? data.message : `${problem}`;
    yield put(LotCreateActionsCreators.lotCreateFailure(message));
  }
}
