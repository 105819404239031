import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import { commentFirstLetterCapitalized } from '../../../../Common/strings';

interface Props {
  comment: string;
  onCommentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CommentsContainer: React.FC<Props> = (props: Props) => {
  const { comment, onCommentChange } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TextField
        rows={4}
        multiline
        id="comment"
        variant="outlined"
        defaultValue={comment || ''}
        onChange={onCommentChange}
        className={classes.textField}
        label={commentFirstLetterCapitalized}
      />
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      gridColumn: 'span 12',
    },
    textField: {
      width: 361,
    },
  }),
);

export { CommentsContainer };
