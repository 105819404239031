import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { AuthSelectors } from '../../../Redux/Auth/selectors';

import { InvestorAuctionListPage } from './Contexts/Investor/AuctionListPage';
import { BackOfficeAuctionListPage } from './Contexts/BackOffice/AuctionListPage';

import { Dialog } from '../../../Components/Dialog';

import {
  ok,
  goToAccompanimentsTab,
  youAreFollowingTheAuction,
} from '../../../Common/strings';

const AuctionListPage: React.FC = () => {
  const isBackOffice = useSelector(AuthSelectors.selectIsBackOffice);
  const [openFollowDialog, setOpenFollowDialog] = useState<boolean>(false);

  const onOkDialogClick = useCallback(() => setOpenFollowDialog(false), []);

  return (
    <>
      {isBackOffice ? (
        <BackOfficeAuctionListPage />
      ) : (
        <InvestorAuctionListPage />
      )}
      <Dialog
        confirmButtonText={ok}
        open={openFollowDialog}
        text={goToAccompanimentsTab}
        title={youAreFollowingTheAuction}
        onClose={onOkDialogClick}
      />
    </>
  );
};

export { AuctionListPage };
