import React from 'react';

import {
  Container,
  Content,
  Title,
  Subtitle,
  Logo,
  InvoiceImage,
} from './styles';

import {
  ifYourCompany,
  provideYourInformationWithSafety,
} from '../../../../Common/strings';

const LeftContainer: React.FC = () => {
  return (
    <Container>
      <Content>
        <Logo />
        <Title>{provideYourInformationWithSafety}</Title>
        <Subtitle>{ifYourCompany}</Subtitle>
      </Content>
      <InvoiceImage />
    </Container>
  );
};

export { LeftContainer };
