import { RequestState } from '../../../Services/Api/models';

import { EvaluationTypes } from '../types';
import { EvaluationActions } from '../actions';

export type EvaluationCreateState = RequestState;

const initialState: EvaluationCreateState = {
  isLoading: false,
  isDone: false,
  hasError: false,
  errorMsg: '',
};

export function evaluationCreateReducer(
  state = initialState,
  action: EvaluationActions,
): EvaluationCreateState {
  switch (action.type) {
    case EvaluationTypes.CREATE_EVALUATION_REQUEST: {
      return {
        errorMsg: '',
        isDone: false,
        isLoading: true,
        hasError: false,
      };
    }
    case EvaluationTypes.CREATE_EVALUATION_SUCCESS: {
      return {
        errorMsg: '',
        isDone: true,
        isLoading: false,
        hasError: false,
      };
    }
    case EvaluationTypes.CREATE_EVALUATION_FAILURE: {
      const errorMsg = action.payload;
      return {
        errorMsg,
        isDone: true,
        isLoading: false,
        hasError: true,
      };
    }
    case EvaluationTypes.CREATE_EVALUATION_INITIAL_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
