import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from '../../../../Styles/themes';

export const Form = styled.form`
  padding: 16px 46px 42px 42px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  p {
    font-family: Roboto Slab;
  }
`;

interface HeaderProps {
  isProvider: boolean;
}

export const Header = styled.div<HeaderProps>`
  display: ${props => (props.isProvider ? 'none' : 'grid')};
  grid-column: span 12;
  grid-gap: 8px;
  padding-bottom: 16px;
  align-items: center;
  grid-template-columns: repeat(12, 1fr);
  border-bottom: 1px solid #e4e4e4;
  h1,
  p {
    color: ${COLORS.BLACK};
  }
`;

export const HeaderTitle = styled.h1`
  white-space: nowrap;
  font-size: 20px;
`;

export const HeaderSubtitle = styled.p`
  grid-column: span 7;
  font-size: 16px;
  white-space: nowrap;
`;

export const HeaderButtonsWrapper = styled.div`
  grid-column: span 4;
  justify-self: end;
`;

export const InfosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column: span 12;
  grid-row-gap: 20px;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 16px;
`;

export const ReceiverWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column: span 12;
  grid-row-gap: 20px;
  margin-bottom: 24px;
`;

export const Title = styled.h2`
  font-size: 20px;
  margin-top: 16px;
  grid-column: span 8;
  color: ${COLORS.BLACK};
  grid-column: span 12;
`;

export const ValuesWrapper = styled.div`
  grid-column: span 4;
`;

export const Label = styled.p`
  color: #000000;
  font-size: 14px;
`;

export const Value = styled.p`
  font-size: 12px;
  color: ${COLORS.LIGHTEST_GRAY};
`;

export const useStyles = makeStyles({
  commentsTextArea: {
    marginTop: '16px',
    gridColumn: 'span 4',
    width: 361,
    marginBottom: '42px',
  },
});
