import React from 'react';
import { FormikProps } from 'formik';

import { EmailTextField } from '../../../Components/TextFields';
import { RoundedButton, BackButton } from '../../../Components/Buttons';

import { ForgotPasswordValues } from '..';

import {
  getNewPassword,
  resetPasswordTitle,
  resetPasswordMessage,
} from '../../../Common/strings';

import * as S from './styles';

interface Props {
  goBack: () => void;
  formik: FormikProps<ForgotPasswordValues>;
}

const ForgotPassword: React.FC<Props> = ({ goBack, formik }: Props) => {
  return (
    <S.Container>
      <S.Content>
        <S.Header>
          <S.Logo />
          <S.Title>{resetPasswordTitle}</S.Title>
          <S.Subtitle>{resetPasswordMessage}</S.Subtitle>
        </S.Header>

        <EmailTextField
          style={{ gridColumn: 'span 2', alignSelf: 'center' }}
          value={formik.values.email}
          onChange={formik.handleChange}
          helperText={formik.touched.email && formik.errors.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
        />
        <S.ButtonContainer>
          <RoundedButton
            text={getNewPassword}
            variant="primary"
            onClick={() => formik.handleSubmit()}
          />
        </S.ButtonContainer>
        <BackButton
          onClick={goBack}
          style={{ gridColumn: 'span 3', justifySelf: 'center' }}
        >
          <p>Voltar</p>
        </BackButton>
      </S.Content>
    </S.Container>
  );
};

export { ForgotPassword };
