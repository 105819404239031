import React from 'react';

import * as S from './ParametersContainer.styled';

import { Score } from './Score';
import { Loading } from '../../../../Components/Loading';
import { ParameterOptionSelect } from '../../Components';

import { Parameter } from '../../../../Redux/Parameter/Entity';
import { Evaluation } from '../../../../Redux/Evaluation/Entity';

interface Props {
  score: number;
  isLoading: boolean;
  evaluation: Evaluation;
  parameters: Parameter[];
  setParameters: (parameterId: string, selected: string) => void;
}

const ParametersContainer: React.FC<Props> = (props: Props) => {
  const { score, isLoading, evaluation, parameters, setParameters } = props;

  return isLoading ? (
    <Loading />
  ) : (
    <S.Container>
      {parameters.map(parameter => (
        <ParameterOptionSelect
          key={parameter.id}
          label={parameter.name}
          evaluation={evaluation}
          data={parameter.options}
          parameterId={parameter.id}
          setParameters={setParameters}
        />
      ))}

      <Score score={score} />
    </S.Container>
  );
};

export { ParametersContainer };
