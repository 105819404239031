import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthSelectors } from '../../../Redux/Auth/selectors';
import {
  LotEntitySelector,
  LotRetrieveAllStateSelectors,
} from '../../../Redux/Lot/selectors';
import { LotRetrieveActionsCreators } from '../../../Redux/Lot/actions';

import * as S from './LotListPage.styled';

import { LotListPageProvider } from './LotListPageProvider';
import { LotList } from './Components';

const LotListPage: React.FC = () => {
  const dispatch = useDispatch();
  const lots = useSelector(LotEntitySelector.selectLots);
  const user = useSelector(AuthSelectors.selectLoggedUser);
  const isProvider = useSelector(AuthSelectors.selectIsProvider);
  const isLoading = useSelector(LotRetrieveAllStateSelectors.selectIsLoading);

  const loadLots = useCallback(() => {
    dispatch(LotRetrieveActionsCreators.lotRetrieveAllRequest());
  }, [dispatch]);

  useEffect(() => loadLots(), [loadLots]);

  return (
    <S.Wrapper>
      {isProvider && user ? (
        <LotListPageProvider lots={lots} isLoading={isLoading} />
      ) : (
        <LotList lots={lots} isLoading={isLoading} />
      )}
    </S.Wrapper>
  );
};

export { LotListPage };
