import { Entity } from '../../models';
import { UserTypes } from '../types';
import { UserActions } from '../actions';
import { Evaluation } from '../../Evaluation/Entity';

export enum USER_ROLE {
  none = '',
  admin = 'admin',
  provider = 'fornecedor',
  investor = 'investidor',
  backOffice = 'backoffice',
}

interface UserContacts {
  contact_name: string;
  phone: string;
  is_commercial: boolean;
}

export interface UserCommon {
  cpf?: string;
  cnpj: string;
  email: string;
  cep?: string;
  city?: string;
  state?: string;
  role: USER_ROLE;
  address?: string;
  full_name?: string;
  complement?: string;
  request_id?: number;
  house_number?: number;
  neighborhood?: string;
  is_approved?: boolean;
  social_reason?: string;
  evaluations: Evaluation[];
  user_contacts?: UserContacts[];
}

export interface UserRequest {
  cnpj: string;
  email: string;
  role: USER_ROLE;
  password: string;
  full_name: string;
  user_contacts: [];
}

export interface UserResponse {
  authToken: string;
  user: User;
}

export interface User extends UserCommon, Entity {}

export interface UserEntityState {
  users: User[];
}

const initialState: UserEntityState = {
  users: [],
};

export function userEntityReducer(
  state = initialState,
  action: UserActions,
): UserEntityState {
  switch (action.type) {
    case UserTypes.RETRIEVE_ALL_USERS_SUCCESS: {
      return {
        users: action.payload,
      };
    }
    default:
      return state;
  }
}
