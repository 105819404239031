import React from 'react';

import * as S from './EmptyState.styled';

interface Props {
  message: string;
}

const EmptyState: React.FC<Props> = ({ message }: Props) => {
  return (
    <S.EmptyCard>
      <S.ColorfulLogo />
      <p>{message}</p>
    </S.EmptyCard>
  );
};

export { EmptyState };
