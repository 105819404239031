import styled from 'styled-components';

export const AuctionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 0px 32px 32px;

  h1 {
    color: #000;
    font-size: 1.25rem;
    font-weight: 25rem;
    margin-right: 24px;
    font-family: 'Roboto Slab';
  }

  p {
    color: #000;
    opacity: '87%';
    font-size: 1rem;
    font-weight: 25rem;
    font-family: 'Roboto Slab';
  }
`;
