/* eslint-disable no-param-reassign */
import { call, put } from 'redux-saga/effects';

import { AuthApi } from '../../Services/Api/Auth';
import { AuthActionsCreators } from '../../Redux/Auth/actions';

export function* signIn(
  api: AuthApi,
  action: ReturnType<typeof AuthActionsCreators.signInRequest>,
): any {
  const { email, password } = action.payload;

  const { ok, data, problem } = yield call([api, api.signIn], {
    email,
    password,
  });

  if (ok) {
    const { token: authToken, data: user } = data;

    yield put(
      AuthActionsCreators.signInSuccess({
        authToken,
        user: {
          id: user._id,
          ...user,
        },
      }),
    );
  } else {
    const message = data && data.message ? data.message : `${problem}`;
    yield put(AuthActionsCreators.signInFailure(message));
  }
}

export function* logout(
  api: AuthApi,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _: ReturnType<typeof AuthActionsCreators.logout>,
): any {
  yield call([api, api.logout]);
}
