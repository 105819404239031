import styled from 'styled-components';
import { COLORS } from '../../../Styles/themes';

interface ButtonProps {
  readonly variant: 'primary' | 'secondary';
}

export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  width: 100%;
  height: 58px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 700px;
  line-height: 1.18rem;
  font-family: 'Open Sans';

  background-color: ${props =>
    props.variant === 'primary' ? COLORS.MEDIUM_BLUE : COLORS.WHITE};
  color: ${props =>
    props.variant === 'primary' ? COLORS.WHITE : COLORS.BLACK};

  &:hover {
    opacity: 0.7;
  }
`;
