import styled from 'styled-components';

import { Button } from '../../../../../../Components/Buttons/Button';

export const CustomButton = styled(Button)`
  height: 32px;
  width: 144px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
`;

export const Text = styled.p`
  padding: 0;
  margin: 0 8px 0 0;
`;
