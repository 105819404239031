import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from '../../../Styles/themes';

export const useStyles = makeStyles({
  root: {
    height: 36,
    fontSize: 14,
    color: COLORS.BLUE,
    borderRadius: 4,
    fontWeight: 500,
    marginRight: '16px',
    borderColor: COLORS.BLUE,
    fontFamily: 'Roboto Slab',

    '&:hover': {
      color: COLORS.WHITE,
      background: COLORS.BLUE,
    },
  },
});
