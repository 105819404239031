import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from '../../../Components/Loading';
import { SellingOrderList } from './sellingOrderList.component';

import { SellingOrderRetrieveActionsCreators } from '../../../Redux/SellingOrder/actions';
import {
  SellingOrderEntitySelector,
  SellingOrderRetrieveAllStateSelectors,
} from '../../../Redux/SellingOrder/selectors';

export const SellingOrderPage: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    SellingOrderRetrieveAllStateSelectors.selectIsLoading,
  );
  const sellingOrders = useSelector(
    SellingOrderEntitySelector.selectSellingOrders,
  );

  const loadSellingOrders = useCallback(() => {
    dispatch(
      SellingOrderRetrieveActionsCreators.sellingOrderRetrieveAllRequest(),
    );
  }, [dispatch]);

  useEffect(() => {
    loadSellingOrders();
  }, [loadSellingOrders]);

  return isLoading ? (
    <Loading />
  ) : (
    <SellingOrderList sellingOrders={sellingOrders} />
  );
};
