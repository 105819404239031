import { combineReducers } from 'redux';

import { auctionItemPutReducer, AuctionItemPutState } from './Put';
import { auctionItemCreateReducer, AuctionItemCreateState } from './Create';
import {
  auctionItemRetrieveReducer,
  AuctionItemRetrieveState,
} from './Retrieve';

export interface AuctionItemCrudState {
  put: AuctionItemPutState;
  create: AuctionItemCreateState;
  retrieve: AuctionItemRetrieveState;
}

const reducer = combineReducers<AuctionItemCrudState>({
  put: auctionItemPutReducer,
  create: auctionItemCreateReducer,
  retrieve: auctionItemRetrieveReducer,
});

export * from './Put';
export * from './Entity';
export * from './Create';

export { reducer as crudAuctionItemReducer };
