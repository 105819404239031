import React, { useState, useEffect } from 'react';

import { Bid } from '../../../../../Redux/models';
import { Lot } from '../../../../../Redux/Lot/Entity';
import { AuctionItem } from '../../../../../Redux/AuctionItem/Entity';

import { AuctionCard } from './AuctionCard';
import { Container } from '../Components/Styles/AuctionCardList.styled';

import { Loading } from '../../../../../Components/Loading';

interface Props {
  lots?: Lot[];
  auctionItems?: AuctionItem[];
  isLoading: boolean;
  onResumeClick: (id: string) => void;
}

interface Data {
  endsIn?: Date;
  bestBid?: Bid;
  fullName: string;
  totalValue: number;
  id: number | string;
  approvedInvoices: number;
  returnPercentExpected: number;
}

const AuctionCardList: React.FC<Props> = (props: Props) => {
  const { lots, auctionItems, isLoading, onResumeClick } = props;

  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    if (lots) {
      setData(
        lots.map(lot => ({
          id: lot.id,
          fullName: lot.owner_lot_name,
          totalValue: lot.total_value_lot,
          approvedInvoices: lot.total_approved_invoices,
          returnPercentExpected: lot.return_percent_expected,
        })),
      );
    } else if (auctionItems) {
      setData(
        auctionItems.map(auctionItem => ({
          id: auctionItem.id,
          bestBid: auctionItem.bestBid,
          totalValue: auctionItem.totalValue,
          fullName: auctionItem.supplier.full_name,
          approvedInvoices: auctionItem.invoiceCount,
          returnPercentExpected: auctionItem.returnPercentExpected,
        })),
      );
    }
  }, [auctionItems, lots]);

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      {data.map(item => (
        <li key={item.id}>
          <AuctionCard
            data={item}
            onResumeClick={() => onResumeClick(`${item.id}`)}
          />
        </li>
      ))}
    </Container>
  );
};

export { AuctionCardList };
