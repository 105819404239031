import React from 'react';

import * as S from './styled';

interface Props {
  text: string;
  selectedRows?: {
    data: {
      index: number;
      dataIndex: number;
    }[];
    lookup: any;
  };
  children: React.ReactNode;
}

export const TableToolbarSelect: React.FC<Props> = (props: Props) => {
  const { text, children, selectedRows } = props;

  return (
    <S.Container>
      <S.Content>
        <p>{`${selectedRows?.data.length} ${text}`}</p>
        {children}
      </S.Content>
    </S.Container>
  );
};
