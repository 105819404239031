import React from 'react';

import * as S from './EvaluateScore.styled';

interface Props {
  score: number;
}

const EvaluateScore: React.FC<Props> = ({ score }: Props) => {
  return <S.CustomScore score={score} />;
};

export { EvaluateScore };
