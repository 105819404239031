/* eslint-disable no-useless-constructor */
import { ApiInstance, ApiResponse, getApiInstance } from '..';

import { SellingOrderResponse } from '../../../Redux/SellingOrder/Entity/index';

export interface SellingOrderApi {
  retrieveSellingOrders(): Promise<ApiResponse<SellingOrderResponse>>;
}

export class SellingOrderImpl implements SellingOrderApi {
  url = 'selling-orders';

  constructor(private api: ApiInstance) {}

  retrieveSellingOrders(): Promise<ApiResponse<SellingOrderResponse>> {
    return this.api.get(`${this.url}`);
  }
}

let instance: SellingOrderApi;

export function getSellingOrdersApiInstance(api: ApiInstance): SellingOrderApi {
  if (!instance) {
    const myApi = api || getApiInstance(api);
    instance = new SellingOrderImpl(myApi);
  }

  return instance;
}
