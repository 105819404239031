import { AppState } from '..';
import { AuctionItem, AuctionItemEntityState } from '.';
import { RequestState } from '../../Services/Api/models';

const AuctionItemState = {
  selectState: ({ requests: { auctionItem } }: AppState) => auctionItem,
};

const AuctionItemEntitySelector = {
  selectState: ({
    entities: { auctionItem },
  }: AppState): AuctionItemEntityState => auctionItem,
  selectAuctionItems: (appState: AppState): AuctionItem[] => {
    const { auctionItems } = AuctionItemEntitySelector.selectState(appState);
    return auctionItems;
  },
  selectAuctionItemById: (
    appState: AppState,
    id: string,
  ): AuctionItem | undefined => {
    const auctionItems = AuctionItemEntitySelector.selectAuctionItems(appState);
    return auctionItems.find(auctionItem => auctionItem._id === id);
  },
};

const AuctionItemRetrieveSelectors = {
  selectState: (appState: AppState) => {
    const { retrieve } = AuctionItemState.selectState(appState);
    return retrieve;
  },
};

const AuctionItemRetrieveAllSelectors = {
  selectState: (appState: AppState): RequestState => {
    const { all } = AuctionItemRetrieveSelectors.selectState(appState);
    return all;
  },
  selectIsLoading: (appState: AppState): boolean => {
    const { isLoading } = AuctionItemRetrieveAllSelectors.selectState(appState);
    return isLoading;
  },
  selectErrorMsg: (appState: AppState): string => {
    const { errorMsg } = AuctionItemRetrieveAllSelectors.selectState(appState);
    return errorMsg;
  },
};

const AuctionItemRetrieveAllFavoriteSelectors = {
  selectState: (appState: AppState): RequestState => {
    const { all } = AuctionItemRetrieveSelectors.selectState(appState);
    return all;
  },
  selectIsLoading: (appState: AppState): boolean => {
    const { isLoading } = AuctionItemRetrieveAllFavoriteSelectors.selectState(
      appState,
    );
    return isLoading;
  },
  selectErrorMsg: (appState: AppState): string => {
    const { errorMsg } = AuctionItemRetrieveAllFavoriteSelectors.selectState(
      appState,
    );
    return errorMsg;
  },
};

const AuctionItemPutSelectors = {
  selectState: (appState: AppState): RequestState => {
    const { put } = AuctionItemState.selectState(appState);
    return put;
  },
  selectIsLoading: (appState: AppState): boolean => {
    const { isLoading } = AuctionItemPutSelectors.selectState(appState);
    return isLoading;
  },
  selectErrorMsg: (appState: AppState): string => {
    const { errorMsg } = AuctionItemPutSelectors.selectState(appState);
    return errorMsg;
  },
  selectIsDone: (appState: AppState): boolean => {
    const { isDone } = AuctionItemPutSelectors.selectState(appState);
    return isDone;
  },
};

const AuctionItemCreateSelectors = {
  selectState: (appState: AppState): RequestState => {
    const { create } = AuctionItemState.selectState(appState);
    return create;
  },
  selectIsLoading: (appState: AppState): boolean => {
    const { isLoading } = AuctionItemCreateSelectors.selectState(appState);
    return isLoading;
  },
  selectErrorMsg: (appState: AppState): string => {
    const { errorMsg } = AuctionItemCreateSelectors.selectState(appState);
    return errorMsg;
  },
  selectIsDone: (appState: AppState): boolean => {
    const { isDone } = AuctionItemCreateSelectors.selectState(appState);
    return isDone;
  },
};

export {
  AuctionItemPutSelectors,
  AuctionItemEntitySelector,
  AuctionItemCreateSelectors,
  AuctionItemRetrieveAllSelectors,
  AuctionItemRetrieveAllFavoriteSelectors,
};
