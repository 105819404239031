import React, { useState, useCallback } from 'react';

import { LeftContainer, RegisterStep, ConclusionStep } from './Components';

const SignUpPage: React.FC = () => {
  const [step, setStep] = useState(0);

  const renderComponentByStep = useCallback(() => {
    if (step === 0) {
      return <RegisterStep sumStep={setStep} />;
    }
    return <ConclusionStep />;
  }, [step]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <LeftContainer />
      {renderComponentByStep()}
    </div>
  );
};

export { SignUpPage };
