import React from 'react';
import { useDropzone } from 'react-dropzone';

import { EmptyState, DropzoneList } from './Components';

import * as S from './styles';

export const DROPZONE_MAX_FILES = 100;

interface Props {
  files: File[];
  maxFiles?: number;
  onDeleteClick: (file: File) => void;
  onDrop: (acceptedFiles: File[]) => void;
}

const Dropzone: React.FC<Props> = ({
  files,
  maxFiles = DROPZONE_MAX_FILES,
  onDrop,
  onDeleteClick,
}: Props) => {
  const { open, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.xml',
    maxFiles,
    noClick: true,
  });

  return (
    <S.Wrapper {...getRootProps()}>
      {files.length === 0 ? (
        <EmptyState open={open} getInputProps={getInputProps} />
      ) : (
        <DropzoneList
          files={files}
          maxFiles={maxFiles}
          open={open}
          onDeleteClick={onDeleteClick}
          getInputProps={getInputProps}
        />
      )}
    </S.Wrapper>
  );
};

export { Dropzone };
