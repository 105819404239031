import React, { ButtonHTMLAttributes } from 'react';

import * as S from './styled';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant: 'primary' | 'secondary';
}

export const Button: React.FC<Props> = (props: Props) => {
  const { children, ...rest } = props;

  return <S.Button {...rest}>{children}</S.Button>;
};
