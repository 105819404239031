import React, { useCallback } from 'react';
import { FormikProps } from 'formik';

import { Alert } from '../../../Components/Alert';
import { RoundedButton } from '../../../Components/Buttons';
import {
  PasswordTextField,
  EmailTextField,
} from '../../../Components/TextFields';

import { LoginValues } from '..';

import {
  signUpCapitalized,
  loginFirstLetterCapitalized,
} from '../../../Common/strings';

import { LeftContainer } from './LeftContainer';

import { Form, Content, Container, LoginText, ButtonContainer } from './styles';

interface SignProps {
  open: boolean;
  errorMsg?: string;
  isLogging?: boolean;
  onSignUp: () => void;
  onCloseAlert: () => void;
  formik: FormikProps<LoginValues>;
}

const SignIn: React.FC<SignProps> = (props: SignProps) => {
  const { open, formik, onSignUp, errorMsg, isLogging, onCloseAlert } = props;

  const renderErrorAlert = useCallback(() => {
    return (
      <Alert
        open={open}
        text={errorMsg}
        severity="error"
        onClose={onCloseAlert}
      />
    );
  }, [errorMsg, onCloseAlert, open]);

  return (
    <Container>
      <LeftContainer />
      <Content>
        <LoginText>{loginFirstLetterCapitalized}</LoginText>
        <Form onSubmit={formik.handleSubmit}>
          <EmailTextField
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <PasswordTextField
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          <ButtonContainer>
            <RoundedButton
              type="submit"
              variant="primary"
              isLoading={isLogging}
              text={loginFirstLetterCapitalized}
              onClick={() => formik.handleSubmit()}
            />
            <RoundedButton
              type="submit"
              variant="secondary"
              text={signUpCapitalized}
              onClick={onSignUp}
            />
          </ButtonContainer>
        </Form>

        {renderErrorAlert()}
      </Content>
    </Container>
  );
};

export { SignIn };
