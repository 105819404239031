import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';

import { Provider } from 'react-redux';

import GlobalStyle from './Styles/global';

import { initCommon } from './Common';

import { createAppStore } from './Redux';

import { Routes } from './Routes';

initCommon();

const App: React.FC = () => {
  const { store } = createAppStore();

  return (
    <Provider store={store}>
      <Router>
        <Routes />
      </Router>

      <GlobalStyle />
    </Provider>
  );
};

export { App };
