import { AppState } from '..';
import { RequestState } from '../../Services/Api/models';

const EvaluationRequestSelector = {
  selectState: ({ requests: { evaluation } }: AppState) => evaluation,
};

const EvaluationCreateSelector = {
  selectState: (appState: AppState): RequestState => {
    const { create } = EvaluationRequestSelector.selectState(appState);
    return create;
  },
  selectIsLoading: (appState: AppState): boolean => {
    const { isLoading } = EvaluationCreateSelector.selectState(appState);
    return isLoading;
  },
  selectIsDone: (appState: AppState): boolean => {
    const { isDone } = EvaluationCreateSelector.selectState(appState);
    return isDone;
  },
  selectErrorMsg: (appState: AppState): string => {
    const { errorMsg } = EvaluationCreateSelector.selectState(appState);
    return errorMsg;
  },
};

export { EvaluationCreateSelector };
