import styled from 'styled-components';
import MaterialTab from '@material-ui/core/Tab';

export const Tab = styled(MaterialTab)`
  height: 48px;
  color: '#000';
  opacity: '54%';
  &.MuiTab-textColorInherit {
    opacity: '54%';
    color: var(--color-black);
  }
  &.Mui-selected {
    color: var(--color-blue);
  }
`;
