import React from 'react';

import TextField from '@material-ui/core/TextField';

import CloseIcon from '@material-ui/icons/Close';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import { DefaultButton } from '../../../../Components/Buttons';

import { User } from '../../../../Redux/User';

import { Divider } from '..';
import { PreRegisterContainer } from '../PreRegister';
import { CommentsContainer } from './CommentsContainer';

import * as S from './styles';

import {
  refuseCapitalized,
  approveCapitalized,
} from '../../../../Common/strings';

interface Props {
  user: User;
  city: string;
  title: string;
  isLoading?: boolean;
  children: React.ReactNode;
  onApprove: () => void;
  onRefuse: () => void;
  onCommentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EvaluateForm: React.FC<Props> = (props: Props) => {
  const classes = S.useStyles();
  const {
    city,
    user,
    title,
    isLoading,
    children,
    onApprove,
    onRefuse,
    onCommentChange,
  } = props;

  return (
    <S.FormContainer>
      <S.EvaluateContainer>
        <S.Title>{title}</S.Title>

        <DefaultButton
          isLoading={isLoading}
          variant_type="primary"
          endIcon={<ThumbUpIcon />}
          text={approveCapitalized}
          onClick={onApprove}
        />
        <DefaultButton
          variant_type="secondary"
          endIcon={<CloseIcon />}
          text={refuseCapitalized}
          onClick={onRefuse}
        />
      </S.EvaluateContainer>
      <TextField
        required
        id="name"
        label="Nome"
        variant="outlined"
        value={user?.full_name}
        className={classes.nameTextfield}
      />
      <Divider />

      <PreRegisterContainer user={user} city={city} />
      <Divider />

      {children}
      <Divider />

      <CommentsContainer
        comment={user.evaluations[0].comment}
        onCommentChange={onCommentChange}
      />
    </S.FormContainer>
  );
};

export { EvaluateForm };
