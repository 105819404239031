import styled from 'styled-components';
import MaterialStar from '@material-ui/icons/Star';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

import { Lot } from '../../../../Components/Lot';
import { Button } from '../../../../../../Components/Buttons/Button';

export const CustomLot = styled(Lot)`
  width: 372px;
  height: 372px;

  > img {
    width: 372px;
    height: 372px;
  }
`;

export const CardContainer = styled.div`
  position: absolute;
  top: 0;
  padding: 24px;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

export const CardInfoContainer = styled.div`
  grid-column: span 8;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(3, max-content);
  row-gap: 32px;
  margin-right: 12px;
`;

export const SmallInfoContainer = styled.div`
  grid-column: span 4;
  max-width: 96px;
`;

export const InfoContainer = styled.div`
  grid-column: span 8;
`;

export const CardStatusContainer = styled.div`
  grid-column: span 4;
  background-color: var(--color-light-gray);
  border-radius: 8px;
  min-height: 207px;
  min-width: 96px;
  padding: 8px 8px 16px 6px;
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

export const TrophyIcon = styled(EmojiEventsIcon)`
  color: var(--color-dark-yellow);
`;

export const FilledStar = styled(MaterialStar)`
  color: var(--color-dark-yellow);
`;

export const CardStatusContent = styled.div`
  display: grid;
  grid-template-rows: (3, max-content);
  row-gap: 32px;
`;

export const Label = styled.p`
  color: var(--color-gray);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Text = styled.p`
  color: var(--color-black);
`;

export const Time = styled.p`
  color: var(--color-red);
  font-size: 12px;
`;

export const Bid = styled.p`
  font-size: calc(var(--font-size-base) + 2px);
  color: var(--color-black);
`;

export const BestBid = styled.p`
  font-weight: bold;
  font-size: calc(var(--font-size-base) + 4px);
  color: var(--color-dark-yellow);
`;

export const ButtonsContainer = styled.div`
  grid-column: span 12;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--color-mid-gray);
  display: flex;
  justify-content: center;
`;

export const BidButton = styled(Button)`
  margin-left: 16px;
  display: flex;
  flex-direction: row;

  align-items: center;

  > img {
    width: 24px;
    height: 24px;

    filter: invert(49%) sepia(0%) saturate(2%) hue-rotate(55deg) brightness(87%)
      contrast(89%);

    margin-right: 8px;
  }
`;
