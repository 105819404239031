import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Props, useStyles } from './styles';

const DefaultButton: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props);
  const { text, isLoading, endIcon, ...rest } = props;

  return (
    <Button {...rest} endIcon={!isLoading && endIcon} className={classes.root}>
      {isLoading ? (
        <CircularProgress size={24} className={classes.loading} />
      ) : (
        text
      )}
    </Button>
  );
};

export { DefaultButton };
