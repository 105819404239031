import React from 'react';

import { CloseButton } from '../../Buttons';
import * as S from './Items.styled';

interface Props {
  items: File[];
  onDeleteClick: (file: File) => void;
}

const Items: React.FC<Props> = (props: Props) => {
  const { items, onDeleteClick } = props;

  return (
    <S.List>
      {items.map(file => (
        <>
          <S.Item key={file.name}>
            <p>{file.name}</p>
            <CloseButton onClick={() => onDeleteClick(file)} />
          </S.Item>
          <S.Divider />
        </>
      ))}
    </S.List>
  );
};

export { Items };
