import { AppState } from '..';
import { AuthState } from '.';
import { User, USER_ROLE } from '../User/Entity';

const AuthSelectors = {
  selectState: ({ auth }: AppState): AuthState => auth,
  selectIsLogging: (appState: AppState): boolean => {
    const { isLogging } = AuthSelectors.selectState(appState);
    return isLogging;
  },
  selectErrorMsg: (appState: AppState): string => {
    const { errorMsg } = AuthSelectors.selectState(appState);
    return errorMsg;
  },
  selectLoggedUser: (appState: AppState): User | undefined => {
    const { user } = AuthSelectors.selectState(appState);
    return user;
  },
  selectIsAdmin: (appState: AppState): boolean => {
    const user = AuthSelectors.selectLoggedUser(appState);
    if (!user) return false;
    return user.role === USER_ROLE.admin;
  },
  selectIsBackOffice: (appState: AppState): boolean => {
    const user = AuthSelectors.selectLoggedUser(appState);
    if (!user) return false;
    return user.role === USER_ROLE.backOffice;
  },
  selectIsInvestor: (appState: AppState): boolean => {
    const user = AuthSelectors.selectLoggedUser(appState);
    if (!user) return false;
    return user.role === USER_ROLE.investor;
  },
  selectIsProvider: (appState: AppState): boolean => {
    const user = AuthSelectors.selectLoggedUser(appState);
    if (!user) return false;
    return user.role === USER_ROLE.provider;
  },
};

export { AuthSelectors };
