import * as Yup from 'yup';
import {
  isValidCpf,
  isValidCnpj,
  isValidCellphoneNumber,
  isValidPostalCodeNumber,
} from './functionUtils';

Yup.addMethod(Yup.string, 'cpf', function verifyCpf(message) {
  return Yup.string().test('cpf-valid', message, cpf => isValidCpf(cpf || ''));
});

Yup.addMethod(Yup.string, 'cnpj', function verifyCnpj(message) {
  return Yup.string().test('cnpj-valid', message, cnpj =>
    isValidCnpj(cnpj || ''),
  );
});

Yup.addMethod(Yup.mixed, 'cellphone', function verifyCellPhone(message) {
  return Yup.string().test('cellphone-valid', message, cellPhoneNumber =>
    isValidCellphoneNumber(cellPhoneNumber || ''),
  );
});

Yup.addMethod(Yup.mixed, 'postalCode', function verifyPostalCodeNumber(
  message,
) {
  return Yup.string().test('postalCode-valid', message, postalCode =>
    isValidPostalCodeNumber(postalCode || ''),
  );
});
