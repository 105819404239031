import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Invoice } from '../../../Redux/Invoice/Entity';

import {
  shipping,
  insurance,
  totalValue,
  productValue,
  formOfPayment,
  ufCapitalized,
  cepCapitalized,
  backFirstLetterCapitalized,
  nameFirstLetterCapitalized,
  numberFirstLetterCapitalized,
  commentFirstLetterCapitalized,
  addressFirstLetterCapitalized,
  complementFirstLetterCapitalized,
  neighborhoodFirstLetterCapitalized,
} from '../../../Common/strings';

import * as S from './AuctionInvoiceDetailsPage.styled';
import { AppState } from '../../../Redux';
import { InvoiceEntitySelector } from '../../../Redux/Invoice/selectors';

interface LocationState {
  invoiceId: string;
}

export const AuctionInvoiceDetailsPage: React.FC = () => {
  const classes = S.useStyles();
  const { state } = useLocation<LocationState>();
  const history = useHistory();
  const { invoiceId } = state;

  const handleGoBackClick = (): void => {
    history.goBack();
  };

  const selectInvoiceById = (
    appState: AppState,
    id: string,
  ): Invoice | undefined => {
    return InvoiceEntitySelector.selectInvoiceById(appState, id);
  };

  const invoice = useSelector((appState: AppState) =>
    selectInvoiceById(appState, invoiceId),
  );

  return invoice ? (
    <S.Container>
      <S.Header>
        <Tooltip title={backFirstLetterCapitalized} arrow>
          <IconButton
            size="small"
            onClick={handleGoBackClick}
            aria-label={backFirstLetterCapitalized}
            style={{ marginRight: '24px' }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Tooltip>
        <S.HeaderTitle>
          {`Detalhes da NFe - ${invoice.invoiceId}`}
        </S.HeaderTitle>
      </S.Header>
      <S.InfosWrapper>
        <S.Title>Informações da NFe</S.Title>
        <S.ValuesWrapper>
          <S.Label>{`${totalValue}:`}</S.Label>
          <S.Value>{`R$ ${invoice.value}`}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${productValue}:`}</S.Label>
          <S.Value>{`R$ ${invoice.productValue}`}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${shipping}:`}</S.Label>
          <S.Value>{`R$ ${invoice.shippingValue}`}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${formOfPayment}:`}</S.Label>
          <S.Value>{invoice.payment?.indicatorDetail}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>Natureza da operação:</S.Label>
          <S.Value>{invoice.operationNature}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${insurance}:`}</S.Label>
          <S.Value>{`R$ ${invoice.insurance}`}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>Data de emissão:</S.Label>
          <S.Value>{invoice.emissionDate}</S.Value>
        </S.ValuesWrapper>
      </S.InfosWrapper>
      <S.InfosWrapper>
        <S.Title>Informações do sacado</S.Title>
        <S.ValuesWrapper>
          <S.Label>{`${nameFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.name}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${cepCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.address.cep}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${addressFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.address.street}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${numberFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.address.number}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${complementFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.address.complement}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${neighborhoodFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.address.neighborhood}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${ufCapitalized}:`}</S.Label>
          <S.Value>{invoice.emitter?.address.uf}</S.Value>
        </S.ValuesWrapper>
      </S.InfosWrapper>
      <S.InfosWrapper>
        <S.Title>Informações do destinatário</S.Title>
        <S.ValuesWrapper>
          <S.Label>Destinatário:</S.Label>
          <S.Value>{invoice.receiver?.name}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${cepCapitalized}:`}</S.Label>
          <S.Value>{invoice.receiver?.address.cep}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${addressFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.receiver?.address.street}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${numberFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.receiver?.address.number}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${complementFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.receiver?.address.complement}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${neighborhoodFirstLetterCapitalized}:`}</S.Label>
          <S.Value>{invoice.receiver?.address.neighborhood}</S.Value>
        </S.ValuesWrapper>
        <S.ValuesWrapper>
          <S.Label>{`${ufCapitalized}:`}</S.Label>
          <S.Value>{invoice.receiver?.address.uf}</S.Value>
        </S.ValuesWrapper>
      </S.InfosWrapper>
      <TextField
        rows={4}
        disabled
        multiline
        id="comment"
        variant="outlined"
        value={invoice.comments}
        label={commentFirstLetterCapitalized}
        className={classes.commentsTextArea}
      />
    </S.Container>
  ) : (
    <h1>Error</h1>
  );
};
