import { call, put } from 'redux-saga/effects';
import { format } from 'date-fns';

import { InvoiceStatus } from '../../Models';
import { LotApi } from '../../Services/Api/Lot';
import { Invoice } from '../../Redux/Invoice/Entity';
import {
  InvoicePatchActionsCreators,
  InvoiceRetrieveActionsCreators,
} from '../../Redux/Invoice/actions';

export function* lotInvoiceRetrieveByLotId(
  api: LotApi,
  action: ReturnType<
    typeof InvoiceRetrieveActionsCreators.invoiceRetrieveByLotIdRequest
  >,
): any {
  const { id, page, status } = action.payload;

  const { ok, data, problem } = yield call(
    [api, api.retrieveInvoicesByLotId],
    id,
    page,
    status,
  );

  if (ok) {
    const invoices = formatResponseToInvoice(data.data);
    yield put(
      InvoiceRetrieveActionsCreators.invoiceRetrieveByLotIdSuccess(invoices),
    );
  } else {
    const message = data && data.message ? data.message : `${problem}`;
    yield put(
      InvoiceRetrieveActionsCreators.invoiceRetrieveByLotIdFailure(message),
    );
  }
}

export function* lotInvoiceEvaluateById(
  api: LotApi,
  action: ReturnType<
    typeof InvoicePatchActionsCreators.invoiceEvaluateByLotIdAndInvoiceIdRequest
  >,
): any {
  const { lotId, invoiceId, is_approved, comments } = action.payload;

  let status: InvoiceStatus = 'refused';

  if (is_approved) status = 'approved';

  const { ok, data, problem } = yield call(
    [api, api.evaluateInvoiceByLotIdAndInvoiceId],
    {
      lotId,
      status,
      comments,
      invoiceId,
      is_approved,
    },
  );

  if (ok) {
    yield put(
      InvoicePatchActionsCreators.invoiceEvaluateByLotIdAndInvoiceIdSuccess(),
    );
  } else {
    const message = data && data.message ? data.message : `${problem}`;
    yield put(
      InvoicePatchActionsCreators.invoiceEvaluateByLotIdAndInvoiceIdFailure(
        message,
      ),
    );
  }
}

function formatResponseToInvoice(response: any): Invoice[] {
  return response.map((invoiceResponse: any) => {
    const {
      id,
      nf_id,
      status,
      comments,
      is_approve,
      is_valid,
      nf_encoded,
    } = invoiceResponse;

    const response = {
      id,
      invoiceId: nf_id,
      comments,
      is_approve,
      is_valid,
      status,
    };

    const { emitter, invoice, receiver } = nf_encoded;
    return {
      ...response,
      docNumber: invoice.docNumber,
      operationNature: invoice.operationNature,
      emissionDate: format(new Date(invoice.emissionDate), 'dd/MM/yyyy HH:mm'),
      payment: {
        indicatorDetail: invoice.payment.indicatorDetail,
        detail: invoice.payment.typeDetail,
        value: invoice.payment.value,
      },
      insurance: invoice.totalValues.insuranceValue,
      value: invoice.totalValues.invoiceTotalValue,
      productValue: invoice.totalValues.productValue,
      shippingValue: invoice.totalValues.shippingValue,
      emitter: {
        address: emitter.address,
        crt: emitter.crt,
        docNumber: emitter.docNumber,
        name: emitter.name,
        type: emitter.type,
      },
      receiver: {
        address: receiver.address,
        docNumber: receiver.docNumber,
        name: receiver.name,
        type: receiver.type,
      },
    };
  });
}
