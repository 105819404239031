import styled from 'styled-components';

import { Button } from '../../../../../Components/Buttons/Button';

export const ButtonsContainer = styled.div`
  grid-column: span 12;
  height: 56px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--color-mid-gray);
  display: flex;
  justify-content: center;
`;

export const CustomButton = styled(Button)`
  width: 144px;
  height: 40px;
`;
