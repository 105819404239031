import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { ROLE } from '../../../Redux/models';
import { AppState } from '../../../Redux';
import { User } from '../../../Redux/User';

import { UserEntitySelector } from '../../../Redux/User/selectors';
import { EvaluateParameters } from '../../../Services/Api/User';

import { EvaluationCreateSelector } from '../../../Redux/Evaluation/selectors';

import { AddressEntitySelector } from '../../../Redux/Address/selectors';
import { AddressRetrieveOneActionCreators } from '../../../Redux/Address/actions';

import { ParameterEntitySelector } from '../../../Redux/Parameter/selectors';
import { ParameterRetrieveActionsCreators } from '../../../Redux/Parameter/actions';

import { EvaluationCreateActionCreators } from '../../../Redux/Evaluation/actions';

import { EvaluateForm } from '../Components';
import { ParametersContainer } from './Components';
import { Dialog } from '../../../Components/Dialog';

import { AuthRoutes } from '../../../Routes';

import {
  ok,
  opsHasAnError,
  refuseInvestor,
  approvedInvestor,
  verifyIfAllFieldsAreFilled,
  successFirstLetterCapitalized,
  investorsEvaluateFirstLetterCapitalized,
} from '../../../Common/strings';

interface LocationState {
  id: string;
}

const InvestorEvaluatePage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation<LocationState>();
  const { id } = state;

  const selectUser = useCallback(
    (appState: AppState): User | undefined => {
      return UserEntitySelector.selectUserById(appState, id);
    },
    [id],
  );

  const investor = useSelector(selectUser);
  const hasDone = useSelector(EvaluationCreateSelector.selectIsDone);
  const errorMsg = useSelector(EvaluationCreateSelector.selectErrorMsg);
  const parameters = useSelector(ParameterEntitySelector.selectParameters);
  const investorAddress = useSelector(AddressEntitySelector.selectAddress);

  const [score, setScore] = useState(0);
  const [comment, setComment] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [values, setValue] = React.useState([] as EvaluateParameters[]);

  const getInvestorCity = useCallback(() => {
    if (investor && investor.cep) {
      dispatch(
        AddressRetrieveOneActionCreators.addressRetrieveOneRequest({
          cep: investor.cep,
        }),
      );
    }
  }, [dispatch, investor]);

  const getParameters = useCallback(() => {
    dispatch(
      ParameterRetrieveActionsCreators.parameterRetrieveAllRequest(
        ROLE.INVESTOR,
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    getParameters();
    getInvestorCity();
  }, [getInvestorCity, getParameters]);

  useEffect(() => {
    if (hasDone || errorMsg) {
      setOpenDialog(true);
    }
  }, [errorMsg, hasDone]);

  useEffect(() => {
    if (investor) {
      const selectedParameters: EvaluateParameters[] = investor.evaluations[0].parameters.map(
        parameter => ({
          id: parameter.parameter || '',
          selected: `${parameter.selected}` || '0',
        }),
      );
      setValue(selectedParameters);
    }
  }, [investor]);

  const handleSetParameters = useCallback(
    (parameterId: string, selected: string) => {
      const filteredParameters = values.filter(
        parameter => parameter.id !== parameterId,
      );

      setValue([...filteredParameters, { id: parameterId, selected }]);
    },
    [values],
  );

  const sumScore = useCallback(() => {
    let scoreValue = 0;

    parameters.forEach(parameter => {
      values.forEach(value => {
        if (parameter.id === value.id) {
          scoreValue += parameter.options[Number(value.selected)].score;
        }
      });
    });

    setScore(scoreValue);
  }, [parameters, values]);

  useEffect(() => {
    sumScore();
  }, [sumScore]);

  const handleCommentChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComment(event.target.value);
    },
    [],
  );

  const handleCloseDialog = (): void => {
    setOpenDialog(false);
    if (!errorMsg) {
      history.push(`${AuthRoutes.backoffice}/investidores`);
    }
  };

  const handleRefuse = (): void => handleClick(false, refuseInvestor);
  const handleApprove = (): void => handleClick(true, approvedInvestor);

  const handleClick = (approved: boolean, dialogText: string): void => {
    dispatchApprove(approved);
    setDialogText(dialogText);
  };

  const dispatchApprove = (approved: boolean): void => {
    dispatch(
      EvaluationCreateActionCreators.evaluationCreateEvaluationRequest(id, {
        approved,
        comment,
        parameters: values,
      }),
    );
  };

  return investor ? (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDialog}
        error={!!errorMsg}
        confirmButtonText={ok}
        text={errorMsg ? verifyIfAllFieldsAreFilled : dialogText}
        title={errorMsg ? opsHasAnError : successFirstLetterCapitalized}
        onClose={handleCloseDialog}
      />
      <EvaluateForm
        city={investorAddress.city}
        user={investor}
        title={investorsEvaluateFirstLetterCapitalized}
        onRefuse={handleRefuse}
        onApprove={handleApprove}
        onCommentChange={handleCommentChange}
      >
        <ParametersContainer
          score={score}
          isLoading={false}
          parameters={parameters}
          evaluation={investor.evaluations[0]}
          setParameters={handleSetParameters}
        />
      </EvaluateForm>
    </>
  ) : (
    <h1>Error</h1>
  );
};

export { InvestorEvaluatePage };
