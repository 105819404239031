import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const FormContainer = styled.form`
  padding: 24px 40px 24px 16px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

export const EvaluateContainer = styled.div`
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 32px;
  align-items: center;

  button:first-of-type {
    margin-right: 18px;
  }
`;

export const Title = styled.h1`
  color: #000;
  font-size: 20px;
  font-family: 'Roboto Slab';
  grid-column: span 10;
`;

export const useStyles = makeStyles({
  nameTextfield: {
    gridColumn: 'span 12',
    maxWidth: 316,
    marginBottom: 32,
  },
});
