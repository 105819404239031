import React from 'react';

import { User } from '../../../../Redux/User';

import * as S from './styles';

import {
  ufCapitalized,
  cepCapitalized,
  cpfCnpjCapitalized,
  cityFirstLetterCapitalized,
  emailFirstLetterCapitalized,
  numberFirstLetterCapitalized,
  addressFirstLetterCapitalized,
  apiDataFirstLetterCapitalized,
  complementFirstLetterCapitalized,
  preRegisterFirstLetterCapitalized,
  socialReasonFirstLetterCapitalized,
  neighborhoodFirstLetterCapitalized,
  firstContactFirstLetterCapitalized,
  commercialPhoneFirstLetterCapitalized,
} from '../../../../Common/strings';

interface Props {
  city: string;
  user?: User;
}

const PreRegisterContainer: React.FC<Props> = (props: Props) => {
  const { user, city } = props;

  return (
    <S.Container>
      <S.Title>
        {`${apiDataFirstLetterCapitalized} / ${preRegisterFirstLetterCapitalized}`}
      </S.Title>

      <S.DataContainer>
        <S.PersonalInfoContainer>
          <p>{`${cpfCnpjCapitalized}:`}</p>
          <p>{user?.cnpj}</p>
        </S.PersonalInfoContainer>
        <S.PersonalInfoContainer>
          <p>{`${socialReasonFirstLetterCapitalized}:`}</p>
          <p>Pessoa Jurídica</p>
        </S.PersonalInfoContainer>
        <S.PersonalInfoContainer>
          <p>{`${cepCapitalized}:`}</p>
          <p>{user?.cep}</p>
        </S.PersonalInfoContainer>
      </S.DataContainer>

      <S.DataContainer>
        <S.PersonalInfoContainer>
          <p>{`${addressFirstLetterCapitalized}:`}</p>
          <p>{user?.address}</p>
        </S.PersonalInfoContainer>
        <S.PersonalInfoContainer>
          <p>{`${numberFirstLetterCapitalized}:`}</p>
          <p>{user?.house_number}</p>
        </S.PersonalInfoContainer>
        <S.PersonalInfoContainer>
          <p>{`${complementFirstLetterCapitalized}:`}</p>
          <p>{user?.complement}</p>
        </S.PersonalInfoContainer>
        <S.PersonalInfoContainer>
          <p>{`${neighborhoodFirstLetterCapitalized}:`}</p>
          <p>{user?.neighborhood}</p>
        </S.PersonalInfoContainer>
      </S.DataContainer>

      <S.DataContainer>
        <S.PersonalInfoContainer>
          <p>{`${cityFirstLetterCapitalized}:`}</p>
          <p>{city}</p>
        </S.PersonalInfoContainer>
        <S.UFContainer>
          <p>{`${ufCapitalized}:`}</p>
          <p>{user?.state}</p>
        </S.UFContainer>
      </S.DataContainer>

      <S.DataContainer>
        <S.PersonalInfoContainer>
          <p>{`${emailFirstLetterCapitalized}:`}</p>
          <p>{user?.email}</p>
        </S.PersonalInfoContainer>
        {user?.user_contacts?.map(contact => (
          <>
            <S.PersonalInfoContainer>
              <p>{`${commercialPhoneFirstLetterCapitalized}:`}</p>
              <p>{contact.phone}</p>
            </S.PersonalInfoContainer>
            <S.PersonalInfoContainer>
              <p>{`${firstContactFirstLetterCapitalized}:`}</p>
              <p>{contact.contact_name}</p>
            </S.PersonalInfoContainer>
          </>
        ))}
      </S.DataContainer>
    </S.Container>
  );
};

export { PreRegisterContainer };
