import { call, put } from 'redux-saga/effects';

import { AddressApi } from '../../Services/Api/Address';
import { AddressRetrieveOneActionCreators } from '../../Redux/Address/actions';

export function* addressRetrieveOneSaga(
  api: AddressApi,
  action: ReturnType<
    typeof AddressRetrieveOneActionCreators.addressRetrieveOneRequest
  >,
): any {
  const { cep } = action.payload;

  const { ok, data, problem } = yield call([api, api.getAddressByCep], { cep });

  if (ok) {
    yield put(AddressRetrieveOneActionCreators.addressRetrieveOneSuccess(data));
  } else {
    const message = data && data.message ? data.message : `${problem}`;
    yield put(
      AddressRetrieveOneActionCreators.addressRetrieveOneFailure(message),
    );
  }
}
