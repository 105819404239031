import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  listCSS,
  labelCSS,
  valueCSS,
  containerCSS,
  expectedRateCSS,
  leftContainerCSS,
  infoContainerCSS,
  rightContainerCSS,
  buttonsContainerCSS,
} from './sellingOrder.styled';

import { Lot } from '../Components/Lot';
import { Button } from '../../../Components/Buttons/Button';

import { SellingOrder as TSellingOrder } from '../../../Redux/SellingOrder/Entity';

const StyledList = styled.ul`
  ${listCSS}
`;

const StyledContainer = styled.div`
  ${containerCSS}
`;

const StyledInfoContainer = styled.div`
  ${infoContainerCSS}
`;

const StyledLeftContainer = styled.div`
  ${leftContainerCSS}
`;

const StyledRightContainer = styled.div`
  ${rightContainerCSS}
`;

const StyledLabel = styled.p`
  ${labelCSS}
`;

const StyledValue = styled.p`
  ${valueCSS}
`;

const StyledExpectedRate = styled.p`
  ${expectedRateCSS}
`;

const StyledButtonContainer = styled.div`
  ${buttonsContainerCSS}
`;

type SellingOrderProps = {
  sellingOrder: TSellingOrder;
};

export const SellingOrder = ({
  sellingOrder,
}: SellingOrderProps): JSX.Element => {
  return (
    <Lot>
      <StyledContainer>
        <StyledInfoContainer>
          <StyledLeftContainer>
            <div>
              <StyledLabel>Vencedor</StyledLabel>
              <StyledValue>{sellingOrder.winner.full_name}</StyledValue>
            </div>

            <div>
              <StyledLabel>Fornecedor</StyledLabel>
              <StyledValue>{sellingOrder.owner.full_name}</StyledValue>
            </div>

            <div>
              <StyledLabel>Valor total do lote</StyledLabel>
              <StyledValue>R$ {sellingOrder.item.totalValue}</StyledValue>
            </div>

            <div>
              <StyledLabel>QTD/NF</StyledLabel>
              <StyledValue>{sellingOrder?.total_invoices}</StyledValue>
            </div>
          </StyledLeftContainer>

          <StyledRightContainer>
            <div>
              <StyledLabel>CPF</StyledLabel>
              <StyledValue>{sellingOrder.winner.cpf ?? '-'}</StyledValue>
            </div>

            <div>
              <StyledLabel>CNPJ</StyledLabel>
              <StyledValue>{sellingOrder.owner.cnpj ?? '-'}</StyledValue>
            </div>

            <div>
              <StyledLabel>Taxa Esperada</StyledLabel>
              <StyledExpectedRate>{sellingOrder.bid.value}%</StyledExpectedRate>
            </div>

            <Link to={`ordem-vendas/${sellingOrder._id}/notas`}>VER NOTAS</Link>
          </StyledRightContainer>
        </StyledInfoContainer>

        <StyledButtonContainer>
          <Button variant="secondary" onClick={() => console.log('')}>
            RECUSAR
          </Button>

          <Button variant="primary" onClick={() => console.log('')}>
            APROVAR
          </Button>
        </StyledButtonContainer>
      </StyledContainer>
    </Lot>
  );
};

type SellingOrderListProps = {
  sellingOrders: TSellingOrder[];
};

export const SellingOrderList = ({
  sellingOrders,
}: SellingOrderListProps): JSX.Element => {
  return (
    <StyledList style={{ padding: '20px 0 0 25px' }}>
      {sellingOrders.map(sellingOrder => (
        <li key={sellingOrder._id}>
          <SellingOrder sellingOrder={sellingOrder} />
        </li>
      ))}
    </StyledList>
  );
};
