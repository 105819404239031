import React from 'react';

import { DialogProps } from '@material-ui/core/Dialog';

import { DefaultButton } from '../../../../../Components/Buttons/DefaultButton';

import * as S from './ConfirmAuctionDialog.styled';
import { SelectedInvoice } from './AuctionInvoiceListPage';

interface Props extends DialogProps {
  title: string;
  text: string;
  isLoading: boolean;
  totalValue: number;
  providerName: string;
  expectedRate: number;
  cancelButtonText: string;
  confirmButtonText: string;
  selectedInvoices: SelectedInvoice[];
  quantitySelectedInvoices: number;
  onClose: () => void;
  onCancelClick: () => void;
  onConfirmClick: () => void;
}

export const ConfirmAuctionDialog: React.FC<Props> = (props: Props) => {
  const {
    text,
    title,
    isLoading,
    totalValue,
    providerName,
    expectedRate,
    cancelButtonText,
    confirmButtonText,
    selectedInvoices,
    quantitySelectedInvoices,
    onClose,
    onCancelClick,
    onConfirmClick,
    ...rest
  } = props;

  return (
    <S.Dialog
      {...rest}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <S.Title>{title}</S.Title>
      <S.Content>
        <S.Description>{text}</S.Description>
        <S.ContentContainer>
          <S.Label>
            Fornecedor: <S.Value>{providerName}</S.Value>
          </S.Label>
          <S.Label>
            Taxa esperada: <S.Value>{`${expectedRate}%`}</S.Value>
          </S.Label>
          <S.Label>
            Valor total do lote: <S.Value>{`R$ ${totalValue}`}</S.Value>
          </S.Label>
          <S.Label>
            Quantidade de notas selecionadas:
            <S.Value>{quantitySelectedInvoices}</S.Value>
          </S.Label>
          <S.ListTitle>Notas:</S.ListTitle>
          <S.InvoiceList>
            {selectedInvoices.map(({ invoiceId }) => (
              <S.InvoiceItem key={invoiceId}>
                <S.Label>{invoiceId}</S.Label>
              </S.InvoiceItem>
            ))}
          </S.InvoiceList>
        </S.ContentContainer>
      </S.Content>
      <S.ButtonsContainer>
        <DefaultButton
          text={cancelButtonText}
          variant_type="secondary"
          onClick={onCancelClick}
        />
        <DefaultButton
          isLoading={isLoading}
          text={confirmButtonText}
          variant_type="primary"
          onClick={onConfirmClick}
        />
      </S.ButtonsContainer>
    </S.Dialog>
  );
};
