import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import GavelIcon from '@material-ui/icons/Gavel';
import ArchiveIcon from '@material-ui/icons/Archive';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { ContentPage } from '../../Components/ContentPage';
import { SideBarWithAppBar } from '../../Components/Sidebar';
import { ProviderIcon, InvestorIcon } from '../../Components/Icons';

import { AuthSelectors } from '../../Redux/Auth/selectors';

import { SideBarItem, ROLE } from '../../Models';

const backOfficeSideBar: SideBarItem[] = [
  {
    label: 'Fornecedores',
    icon: <ProviderIcon />,
    route: `/backoffice/fornecedores`,
  },
  {
    label: 'Investidores',
    icon: <InvestorIcon />,
    route: '/backoffice/investidores',
  },
  {
    label: 'Validação de NFe',
    icon: <InsertDriveFileIcon />,
    route: '/backoffice/lotes',
  },
  {
    label: 'Leilão',
    icon: <GavelIcon />,
    route: '/backoffice/leilao',
  },
  {
    label: 'Ordem de Vendas',
    icon: <GavelIcon />,
    route: '/backoffice/ordem-vendas',
  },
];

const investidorSideBar: SideBarItem[] = [
  {
    label: 'Leilão',
    icon: <GavelIcon />,
    route: '/investidor/leilao',
  },
  {
    label: 'Arremates',
    icon: <ArchiveIcon />,
    route: '/investidor/leilao',
  },
];

const providerSideBar: SideBarItem[] = [
  {
    label: 'Meus NFe',
    icon: <ArchiveIcon />,
    route: '/fornecedor/lotes',
  },
];

interface Props {
  children: React.ReactNode;
}

const MainContainer: React.FC<Props> = ({ children }: Props) => {
  const history = useHistory();
  const isAdmin = useSelector(AuthSelectors.selectIsAdmin);
  const isProvider = useSelector(AuthSelectors.selectIsProvider);
  const isInvestor = useSelector(AuthSelectors.selectIsInvestor);
  const isBackOffice = useSelector(AuthSelectors.selectIsBackOffice);

  const [sidebarItems, setSideBarItems] = React.useState<SideBarItem[]>([]);
  const [role, setRole] = React.useState<ROLE>(ROLE.NONE);

  useEffect(() => {
    if (isBackOffice || isAdmin) {
      setSideBarItems(backOfficeSideBar);
      setRole(ROLE.BACK_OFFICE);
    } else if (isInvestor) {
      setSideBarItems(investidorSideBar);
      setRole(ROLE.INVESTOR);
    } else if (isProvider) {
      setSideBarItems(providerSideBar);
      setRole(ROLE.PROVIDER);
    }
  }, [isAdmin, isBackOffice, isInvestor, isProvider]);

  const navigateTo = useCallback(
    ({ screenName }) => {
      history.push(screenName);
    },
    [history],
  );

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <SideBarWithAppBar
        items={sidebarItems}
        role={role}
        onClick={({ route }) => navigateTo({ screenName: route })}
      />
      <ContentPage>{children}</ContentPage>
    </div>
  );
};

export { MainContainer };
