export enum AuctionItemsTypes {
  GIVE_BID_REQUEST = '[auction_item] GIVE_BID_REQUEST',
  GIVE_BID_SUCCESS = '[auction_item] GIVE_BID_SUCCESS',
  GIVE_BID_FAILURE = '[auction_item] GIVE_BID_FAILURE',
  CREATE_AUCTION_ITEM_REQUEST = '[auction_item] CREATE_AUCTION_ITEM_REQUEST',
  CREATE_AUCTION_ITEM_SUCCESS = '[auction_item] CREATE_AUCTION_ITEM_SUCCESS',
  CREATE_AUCTION_ITEM_FAILURE = '[auction_item] CREATE_AUCTION_ITEM_FAILURE',
  FAVORITE_AUCTION_ITEM_REQUEST = '[auction_item] FAVORITE_AUCTION_ITEM_REQUEST',
  FAVORITE_AUCTION_ITEM_SUCCESS = '[auction_item] FAVORITE_AUCTION_ITEM_SUCCESS',
  FAVORITE_AUCTION_ITEM_FAILURE = '[auction_item] FAVORITE_AUCTION_ITEM_FAILURE',
  UNFAVORITE_AUCTION_ITEM_REQUEST = '[auction_item] UNFAVORITE_AUCTION_ITEM_REQUEST',
  UNFAVORITE_AUCTION_ITEM_SUCCESS = '[auction_item] UNFAVORITE_AUCTION_ITEM_SUCCESS',
  UNFAVORITE_AUCTION_ITEM_FAILURE = '[auction_item] UNFAVORITE_AUCTION_ITEM_FAILURE',
  CREATE_AUCTION_ITEM_INITIAL_STATE = '[auction_item] CREATE_AUCTION_ITEM_INITIAL_STATE',
  RETRIEVE_ALL_AUCTION_ITEM_REQUEST = '[auction_item] RETRIEVE_ALL_AUCTION_ITEM_REQUEST',
  RETRIEVE_ALL_AUCTION_ITEM_SUCCESS = '[auction_item] RETRIEVE_ALL_AUCTION_ITEM_SUCCESS',
  RETRIEVE_ALL_AUCTION_ITEM_FAILURE = '[auction_item] RETRIEVE_ALL_AUCTION_ITEM_FAILURE',
  RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_REQUEST = '[auction_item] RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_REQUEST',
  RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_SUCCESS = '[auction_item] RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_SUCCESS',
  RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_FAILURE = '[auction_item] RETRIEVE_ALL_FAVORITE_AUCTION_ITEM_FAILURE',
  RETRIEVE_ALL_BIDDED_AUCTION_ITEM_REQUEST = '[auction_item] RETRIEVE_ALL_BIDDED_AUCTION_ITEM_REQUEST',
  RETRIEVE_ALL_BIDDED_AUCTION_ITEM_SUCCESS = '[auction_item] RETRIEVE_ALL_BIDDED_AUCTION_ITEM_SUCCESS',
  RETRIEVE_ALL_BIDDED_AUCTION_ITEM_FAILURE = '[auction_item] RETRIEVE_ALL_BIDDED_AUCTION_ITEM_FAILURE',
}
