import React from 'react';
import { DropzoneInputProps } from 'react-dropzone';
import Button from '@material-ui/core/Button';

import * as S from './EmptyState.styled';

import { dragDropFiles, clickToSelect } from '../../../Common/strings';

interface Props {
  open: () => void;
  getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
}

const EmptyState: React.FC<Props> = ({ open, getInputProps }: Props) => {
  const classes = S.useStyles();

  return (
    <S.DragAndDrop>
      <input {...getInputProps()} />
      <S.UploadImage />
      <S.DragAndDropText>{dragDropFiles}</S.DragAndDropText>
      <Button
        className={classes.clickToSelectButton}
        variant="contained"
        onClick={open}
      >
        {clickToSelect}
      </Button>
    </S.DragAndDrop>
  );
};

export { EmptyState };
