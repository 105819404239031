import styled from 'styled-components';

import { COLORS } from '../../../../Styles/themes';

import colorfulLogo from '../../../../Assets/colorful_logo.svg';
import invoiceImage from '../../../../Assets/auction.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
  background: ${COLORS.WHITE};
`;

export const Content = styled.div`
  display: flex;
  margin-top: 6vh;
  flex-direction: column;
  margin-left: 7vw;
  margin-right: 8vw;
  height: 100vh;

  > :first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 56px;
  }
`;

export const Logo = styled.img.attrs({
  src: colorfulLogo,
  alt: 'Arena Crédito',
})`
  width: 15vw;
  height: 7vh;
`;

export const Title = styled.h1`
  font-weight: 25rem;
  font-size: 2.25rem;
  color: ${COLORS.DARKEST_GRAY};
  font-family: 'Open Sans';
  margin-bottom: 4vh;
`;

export const Subtitle = styled.span`
  color: #999;
  font-size: 1.5rem;
  font-weight: 25rem;
  font-family: 'Open Sans';
`;

export const InvoiceImage = styled.img.attrs({
  src: invoiceImage,
  alt: 'Nota fiscal',
})`
  width: 50vw;
  height: 50vh;
  margin-bottom: 13vh;
`;
