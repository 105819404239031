import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import {
  expectedRateRequired,
  expectedRateGreaterThenZero,
  expectedRateLessThenOneHundred,
} from '../../../../../Common/strings';

interface Props {
  open: boolean;
  onCancelClick: () => void;
  onConfirmClick: (bid: number) => void;
}

interface Values {
  bid: number;
}

export const GiveBidDialog: React.FC<Props> = (props: Props) => {
  const { open, onCancelClick, onConfirmClick } = props;

  const classes = useStyles();
  const initialValues: Values = { bid: 0 };

  const validationSchema = Yup.object().shape({
    bid: Yup.number()
      .min(0, expectedRateGreaterThenZero)
      .max(100, expectedRateLessThenOneHundred)
      .required(expectedRateRequired),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => onConfirmClick(values.bid),
  });

  return (
    <Dialog open={open} fullWidth maxWidth="sm" title="Dar um lance">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          Dar um lance
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-moreThanOneInvoice">
            Adicione seu lance (%)
          </DialogContentText>

          <TextField
            id="bid"
            type="number"
            label="Lance (%)"
            className={classes.textField}
            value={formik.values.bid}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: { min: 0, max: 100, step: 0.001 },
            }}
            helperText={formik.errors.bid}
            error={Boolean(formik.errors.bid)}
            onChange={formik.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelClick} className={classes.cancelButton}>
            Cancelar
          </Button>
          <Button type="submit" className={classes.confirmButton}>
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles({
  title: {
    color: 'var(--color-light-blue)',
  },
  textField: {
    width: '200px',
  },
  cancelButton: {
    color: 'var(--color-gray)',
  },
  confirmButton: {
    color: 'var(--color-light-blue)',
  },
});
