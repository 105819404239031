import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';

import { Status } from '../../../Common/Types';

import { UserRetrieveAllActionCreators } from '../../../Redux/User/actions';
import {
  UserEntitySelector,
  UserRetrieveSelector,
} from '../../../Redux/User/selectors';
import { USER_ROLE } from '../../../Redux/User/Entity';
import { EvaluationCreateActionCreators } from '../../../Redux/Evaluation/actions';

import { AuthRoutes } from '../../../Routes';
import { StatusBar } from '../../../Components/StatusBar';
import { InvestorTable, InvestorTableActionsColumn } from './Components';

import {
  nameFirstLetterCapitalized,
  profileFirstLetterCapitalized,
  approvedFirstLetterCapitalized,
  refusedFirstLetterCapitalized,
  underAnalysisFirstLetterCapitalized,
} from '../../../Common/strings';

const InvestorListPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pageName = `${AuthRoutes.backoffice}${AuthRoutes.investor}/avaliacao`;

  const investors = useSelector(UserEntitySelector.selectInvestors);
  const isLoading = useSelector(UserRetrieveSelector.selectIsLoading);

  const [tabValue, setTabValue] = React.useState<number>(0);

  const isPending = useCallback(() => tabValue === 0, [tabValue]);
  const isApproved = useCallback(() => tabValue === 1, [tabValue]);

  const loadInvestors = useCallback(
    (filter_by: Status): void => {
      dispatch(
        UserRetrieveAllActionCreators.userRetrieveAllRequest({
          role_name: USER_ROLE.investor,
          filter_by,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    let filter_by: Status = 'awaiting';

    if (isPending()) {
      filter_by = 'awaiting';
    } else if (isApproved()) {
      filter_by = 'approved';
    } else {
      filter_by = 'rejected';
    }

    loadInvestors(filter_by);
  }, [isApproved, isPending, loadInvestors]);

  useEffect(() => {
    dispatch(
      EvaluationCreateActionCreators.userPostCreateEvaluationInitialState(),
    );
  }, [dispatch]);

  const columns: MUIDataTableColumnDef[] = [
    { label: nameFirstLetterCapitalized, name: 'full_name' },
    { label: profileFirstLetterCapitalized, name: 'role' },
    {
      label: ' ',
      name: 'id',
      options: {
        customBodyRender: id => {
          return (
            <InvestorTableActionsColumn
              onEvaluateClick={() => history.push(pageName, { id })}
            />
          );
        },
      },
    },
  ];

  const tabs = [
    underAnalysisFirstLetterCapitalized,
    approvedFirstLetterCapitalized,
    refusedFirstLetterCapitalized,
  ];

  return (
    <>
      <StatusBar tabs={tabs} value={tabValue} onChange={setTabValue} />
      <InvestorTable
        investors={investors}
        columns={columns}
        isLoading={isLoading}
      />
    </>
  );
};

export { InvestorListPage };
