import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

import { ROLE } from '../models';
import { Parameter } from './Entity';
import { ParameterTypes } from './types';

export const ParameterRetrieveActionsCreators = {
  parameterRetrieveAllRequest: (
    role: ROLE.INVESTOR | ROLE.PROVIDER,
  ): Readonly<{
    type: ParameterTypes.RETRIEVE_PARAMETERS_REQUEST;
    payload: ROLE.INVESTOR | ROLE.PROVIDER;
  }> => createAction(ParameterTypes.RETRIEVE_PARAMETERS_REQUEST, role),
  parameterRetrieveAllSuccess: (
    parameters: Parameter[],
  ): Readonly<{
    payload: Parameter[];
    type: ParameterTypes.RETRIEVE_PARAMETERS_SUCCESS;
  }> => createAction(ParameterTypes.RETRIEVE_PARAMETERS_SUCCESS, parameters),
  parameterRetrieveAllFailure: (
    errorMsg: string,
  ): Readonly<{
    type: ParameterTypes.RETRIEVE_PARAMETERS_FAILURE;
    payload: string;
  }> => createAction(ParameterTypes.RETRIEVE_PARAMETERS_FAILURE, errorMsg),
};

export type ParameterRetrieveActions = ActionsUnion<
  typeof ParameterRetrieveActionsCreators
>;

export type ParameterActions = ParameterRetrieveActions;
