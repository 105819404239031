import { SampleTypes } from './types';
import { SampleActions } from './actions';

export interface SampleState {
  count: number;
}

const initialState: SampleState = {
  count: 0,
};

export function sampleReducer(
  state = initialState,
  action: SampleActions,
): SampleState {
  switch (action.type) {
    case SampleTypes.INCREMENT_COUNT_SUCCESS:
      return {
        count: state.count + 1,
      };
    case SampleTypes.DECREMENT_COUNT_SUCCESS:
      return {
        count: state.count - 1,
      };
    default:
      return state;
  }
}
