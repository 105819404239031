import { css } from 'styled-components';

export const listCSS = css`
  display: grid;
  grid-gap: 36px;
  grid-template-rows: max-content;
  grid-template-columns: repeat(auto-fill, 372px);
  padding: 20px 0px 0px 25px;
`;

export const containerCSS = css`
  position: absolute;
  top: 0;
  padding: 16px;
  width: 336px;
  height: 310px;
`;

export const infoContainerCSS = css`
  display: flex;
  justify-content: space-between;

  padding-bottom: 16px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--color-mid-gray);
`;

const flexContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const leftContainerCSS = css`
  ${flexContainerCSS};

  margin-right: 12px;
`;

export const rightContainerCSS = css`
  ${flexContainerCSS}

  background-color: var(--color-light-gray);
  border-radius: 8px;
  padding: 8px 8px 16px 6px;
`;

export const labelCSS = css`
  color: var(--color-gray);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const valueCSS = css`
  color: var(--color-black);
  font-size: var(--font-size-base);
`;

export const expectedRateCSS = css`
  font-weight: bold;
  font-size: calc(var(--font-size-base) + 4px);
  color: var(--color-dark-yellow);
`;

export const buttonsContainerCSS = css`
  display: flex;

  justify-content: space-around;
`;
