import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { EvaluateForm } from '../Components';
import { ParametersContainer } from './Components';
import { Dialog } from '../../../Components/Dialog';
import { Loading } from '../../../Components/Loading';

import { ROLE } from '../../../Redux/models';
import { AppState } from '../../../Redux';
import { User } from '../../../Redux/User';
import { UserEntitySelector } from '../../../Redux/User/selectors';
import { EvaluationCreateSelector } from '../../../Redux/Evaluation/selectors';

import { EvaluateParameters } from '../../../Services/Api/User';

import {
  AddressEntitySelector,
  AddressRetrieveOneSelectors,
} from '../../../Redux/Address/selectors';
import { AddressRetrieveOneActionCreators } from '../../../Redux/Address/actions';

import { ParameterEntitySelector } from '../../../Redux/Parameter/selectors';
import { ParameterRetrieveActionsCreators } from '../../../Redux/Parameter/actions';

import { EvaluationCreateActionCreators } from '../../../Redux/Evaluation/actions';

import { AuthRoutes } from '../../../Routes';

import * as S from './ProviderEvaluatePage.styled';

import {
  ok,
  opsHasAnError,
  refusedProvider,
  approvedProvider,
  verifyIfAllFieldsAreFilled,
  successFirstLetterCapitalized,
  providersEvaluateFirstLetterCapitalized,
} from '../../../Common/strings';

interface LocationState {
  id: string;
}

const ProviderEvaluatePage: React.FC = () => {
  const { state } = useLocation<LocationState>();
  const { id } = state;
  const dispatch = useDispatch();
  const history = useHistory();

  const selectUser = useCallback(
    (appState: AppState): User | undefined => {
      return UserEntitySelector.selectUserById(appState, id);
    },
    [id],
  );

  const provider = useSelector(selectUser);
  const hasDone = useSelector(EvaluationCreateSelector.selectIsDone);
  const errorMsg = useSelector(EvaluationCreateSelector.selectErrorMsg);
  const parameters = useSelector(ParameterEntitySelector.selectParameters);
  const providerAddress = useSelector(AddressEntitySelector.selectAddress);
  const isLoadingAddress = useSelector(
    AddressRetrieveOneSelectors.selectIsLoading,
  );

  const [score, setScore] = useState(0);
  const [comment, setComment] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [values, setValue] = React.useState([] as EvaluateParameters[]);

  const getProviderCity = useCallback(() => {
    if (provider && provider.cep) {
      dispatch(
        AddressRetrieveOneActionCreators.addressRetrieveOneRequest({
          cep: provider.cep,
        }),
      );
    }
  }, [dispatch, provider]);

  const getParameters = useCallback(() => {
    dispatch(
      ParameterRetrieveActionsCreators.parameterRetrieveAllRequest(
        ROLE.PROVIDER,
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    if (hasDone || errorMsg) {
      setOpenDialog(true);
    }
  }, [errorMsg, hasDone]);

  useEffect(() => {
    getParameters();
    getProviderCity();
  }, [getParameters, getProviderCity]);

  useEffect(() => {
    if (provider) {
      const selectedParameters: EvaluateParameters[] = provider.evaluations[0].parameters.map(
        parameter => ({
          id: parameter.parameter || '',
          selected: `${parameter.selected}` || '0',
        }),
      );
      setValue(selectedParameters);
    }
  }, [provider, provider?.evaluations]);

  const handleSetParameters = useCallback(
    (parameterId: string, selected: string) => {
      const filteredParameters = values.filter(
        parameter => parameter.id !== parameterId,
      );

      setValue([...filteredParameters, { id: parameterId, selected }]);
    },
    [values],
  );

  const sumScore = useCallback(() => {
    let scoreValue = 0;

    parameters.forEach(parameter => {
      values.forEach(value => {
        if (parameter.id === value.id) {
          scoreValue += parameter.options[Number(value.selected)].score;
        }
      });
    });

    setScore(scoreValue);
  }, [parameters, values]);

  useEffect(() => {
    sumScore();
  }, [sumScore]);

  const handleCommentChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComment(event.target.value);
    },
    [],
  );

  const handleCloseDialog = (): void => {
    setOpenDialog(false);
    if (!errorMsg) {
      history.push(`${AuthRoutes.backoffice}/fornecedores`);
    }
  };

  const handleRefuse = (): void => {
    dispatchApprove(false);
    setDialogText(refusedProvider);
  };
  const handleApprove = (): void => {
    dispatchApprove(true);
    setDialogText(approvedProvider);
  };

  const dispatchApprove = (approved: boolean): void => {
    dispatch(
      EvaluationCreateActionCreators.evaluationCreateEvaluationRequest(id, {
        approved,
        comment,
        parameters: values,
      }),
    );
  };

  return provider ? (
    <>
      {isLoadingAddress ? (
        <Loading />
      ) : (
        <S.Wrapper>
          <Dialog
            fullWidth
            maxWidth="sm"
            open={openDialog}
            error={!!errorMsg}
            confirmButtonText={ok}
            text={errorMsg ? verifyIfAllFieldsAreFilled : dialogText}
            title={errorMsg ? opsHasAnError : successFirstLetterCapitalized}
            onClose={handleCloseDialog}
          />
          <EvaluateForm
            user={provider}
            isLoading={false}
            city={providerAddress.city}
            title={providersEvaluateFirstLetterCapitalized}
            onApprove={handleApprove}
            onRefuse={handleRefuse}
            onCommentChange={handleCommentChange}
          >
            <ParametersContainer
              score={score}
              parameters={parameters}
              isLoading={false}
              evaluation={provider.evaluations[0]}
              setParameters={handleSetParameters}
            />
          </EvaluateForm>
        </S.Wrapper>
      )}
    </>
  ) : (
    <h1>Error</h1>
  );
};

export { ProviderEvaluatePage };
