import React from 'react';

import * as S from './index.styled';

interface Props {
  children: React.ReactNode;
}

export const Lot: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <S.Container {...rest}>
      <S.Ticket />
      {children}
    </S.Container>
  );
};
