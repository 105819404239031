import styled from 'styled-components';

export const Content = styled.div`
  position: absolute;
  top: 0;
  padding: 16px;
  width: 336px;
  height: 310px;
  align-items: center;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'info values'
    'footer footer';
  grid-column-gap: 16px;
  font-family: Roboto Slab;
  font-size: 13px;
`;

export const InfoWrapper = styled.div`
  grid-area: info;
  p:last-of-type {
    margin: 0;
  }
`;

export const ValuesWrapper = styled.div`
  grid-area: values;
  padding: 12px;
  margin-bottom: 12px;
  background: #f2f2f2;
  border-radius: 8px;

  p:last-of-type {
    margin: 0;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e0e0e0;
  padding: 12px;
  grid-area: footer;
  display: grid;
  justify-content: center;
`;

export const Label = styled.p`
  color: #707070;
`;

export const Value = styled.p`
  color: #000000;
  margin-bottom: 16px;
`;
