import { combineReducers } from 'redux';

import { AddressRetrieveOneState, addressRetrieveOneReducer } from './one';

export interface AddressRetrieveState {
  one: AddressRetrieveOneState;
}

const reducer = combineReducers<AddressRetrieveState>({
  one: addressRetrieveOneReducer,
});

export { reducer as addressRetrieveReducer };
