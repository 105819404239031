import styled from 'styled-components';

export const Container = styled.div`
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 32px;
  margin-bottom: 16px;
`;
