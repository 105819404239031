/* eslint-disable no-useless-constructor */
import { ApiInstance, ApiResponse, getApiInstance } from '..';

import { AuctionItem } from '../../../Redux/AuctionItem/Entity';
import {
  GiveBidRequest,
  AuctionItemCreateRequest,
} from '../../../Redux/AuctionItem/actions';

export interface AuctionApi {
  createAuctionItem(
    request: AuctionItemCreateRequest,
  ): Promise<ApiResponse<void>>;
  giveBid(request: GiveBidRequest): Promise<ApiResponse<void>>;
  retrieveAuctionItems(): Promise<ApiResponse<AuctionItem[]>>;
  favoriteAuctionItemById(auctionItemId: string): Promise<ApiResponse<void>>;
  unfavoriteAuctionItemById(auctionItemId: string): Promise<ApiResponse<void>>;
}

export class AuctionApiImpl implements AuctionApi {
  url = 'auction';

  constructor(private api: ApiInstance) {}

  createAuctionItem(
    request: AuctionItemCreateRequest,
  ): Promise<ApiResponse<void>> {
    return this.api.post(`${this.url}/items`, request);
  }

  retrieveAuctionItems(): Promise<ApiResponse<AuctionItem[]>> {
    return this.api.get(`${this.url}/items`);
  }

  favoriteAuctionItemById(auctionItemId: string): Promise<ApiResponse<void>> {
    return this.api.put(`${this.url}/items/${auctionItemId}/favorite`);
  }

  unfavoriteAuctionItemById(auctionItemId: string): Promise<ApiResponse<void>> {
    return this.api.put(`${this.url}/items/${auctionItemId}/unfavorite`);
  }

  giveBid({ auctionItemId, bid }: GiveBidRequest): Promise<ApiResponse<void>> {
    return this.api.put(`${this.url}/items/${auctionItemId}/bid`, { bid });
  }
}

let instance: AuctionApi;

export function getAuctionApiInstance(api: ApiInstance): AuctionApi {
  if (!instance) {
    const myApi = api || getApiInstance(api);
    instance = new AuctionApiImpl(myApi);
  }
  return instance;
}
