import React from 'react';

import * as S from './styles';

interface Props {
  children: React.ReactNode;
}

const ContentPage: React.FC<Props> = ({ children }: Props) => {
  return <S.Wrapper>{children}</S.Wrapper>;
};

export { ContentPage };
