/* eslint-disable prettier/prettier */
import { inProgressFirstLetterCapitalized, thirdMinutesLeft, toNegotiate } from "./strings";

export type InputMasksTypes = 'cpf' | 'cnpj' | 'phone' | 'cpfCnpj' | 'cep';

export const InputMasks = {
  cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/,
    /\d/, '-', /\d/, /\d/],
  cpfCnpj: (rawValue = ''): (string | RegExp)[] => {
    const v = rawValue.replace(/\D/g, '');
    if (v.length <= 11) {
      return InputMasks.cpf;
    }
    return InputMasks.cnpj;
  },
  phone: (rawValue = ''): (string | RegExp)[] => {
    const v = rawValue.replace(/\D/g, '');
    if (v.length <= 10) {
      return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
    return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  },
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
}

export enum USER_TYPES  {
  FORNECEDOR = 'fornecedor',
  INVESTIDOR = 'investidor',
}

export enum SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum BUTTON_BACKGROUND  {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export enum STATUS_VALUE {
  PROGRESS = 'PROGRESS',
  NEGOTIATE = 'NEGOTIATE',
  PROGRESS_LESS_TIME = 'PROGRESS_LESS_TIME',
}

export enum STATUS_COLOR {
  BLUE = '#2B478B',
  RED = '#E1000D',
  GREEN = '#075706'
}

export interface Status {
  name?: string;
  value: STATUS_VALUE;
  color: STATUS_COLOR;
}

export const AUCTION_STATUS = {
  PROGRESS: {
    name: inProgressFirstLetterCapitalized, value: STATUS_VALUE.PROGRESS , color: STATUS_COLOR.BLUE
  },
  PROGRESS_LESS_TIME: {
    name: thirdMinutesLeft, value: STATUS_VALUE.PROGRESS_LESS_TIME, color: STATUS_COLOR.RED
  },
  NEGOTIATE: {
    name: toNegotiate, value: STATUS_VALUE.NEGOTIATE, color: STATUS_COLOR.GREEN
  }
}

export enum PARAMETERS {
  RECEITA_FEDERAL = '1',
  SERASA = '2',
  GOOGLE = '3',
  COMMERCIAL_SITE = '4',
  BLOQUEIO_JUDICIAL = '5',
  RECLAME_AQUI = '6',
  DT_FUNDACAO = '7',
  MEMBERSHIP_TIME = '8',
}


export const DRAWER_WIDTH = 240;
