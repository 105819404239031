import styled from 'styled-components';

export const CardContainer = styled.div`
  position: absolute;
  top: 0;
  padding: 16px;
  width: 336px;
  height: 310px;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

export const CardInfoContainer = styled.div`
  grid-column: span 8;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(3, max-content);
  row-gap: 32px;
  margin-right: 12px;
`;

export const SmallInfoContainer = styled.div`
  grid-column: span 4;
  max-width: 96px;
`;

export const InfoContainer = styled.div`
  grid-column: span 8;
`;

export const CardStatusContainer = styled.div`
  grid-column: span 4;
  background-color: var(--color-light-gray);
  border-radius: 8px;
  min-height: 207px;
  min-width: 96px;
  padding: 8px 8px 16px 6px;

  display: grid;
  grid-template-rows: repeat(3, max-content);
  row-gap: 32px;
`;

export const ButtonsContainer = styled.div`
  grid-column: span 12;
  height: 56px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--color-mid-gray);
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.p`
  color: var(--color-gray);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Text = styled.p`
  color: var(--color-black);
`;

export const Time = styled.p`
  color: #e1000d;
  font-size: 12px;
`;

export const Bid = styled.p`
  font-size: calc(var(--font-size-base) + 2px);
  color: var(--color-black);
`;

export const BestBid = styled.p`
  font-weight: bold;
  font-size: calc(var(--font-size-base) + 4px);
  color: var(--color-dark-yellow);
`;
