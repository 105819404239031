export function isValidCpf(cpf: string): boolean {
  let auxCpf = cpf || '';
  auxCpf = auxCpf.replace(/\D/g, '');
  if (auxCpf.length !== 11) {
    return false;
  }

  const digits = auxCpf.split('').map(v => parseInt(v, 10));
  let aux = 0;
  // Soma os primeiros 9 (0 a 8) digitos multiplicando cada um por seu peso respectivo:
  // 10, 9, 8, 7, 6, 5, 4, 3, 2
  for (let i = 0; i < 9; i += 1) {
    aux += digits[i] * (10 - i);
  }
  aux = 11 - (aux % 11);
  aux = aux > 9 ? 0 : aux;
  // O penultimo digito deve ser igual ao calculado
  if (digits[9] !== aux) {
    return false;
  }
  // Soma os primeiros 10 digitos (0 a 9) multiplicando cada um por seu peso respectivo:
  // 11, 10, 9, 8, 7, 6, 5, 4, 3, 2
  aux = 0;
  for (let i = 0; i < 10; i += 1) {
    aux += digits[i] * (11 - i);
  }
  aux = 11 - (aux % 11);
  aux = aux > 9 ? 0 : aux;
  // O ultimo digito deve ser igual ao calculado
  if (digits[10] !== aux) {
    return false;
  }
  return true;
}

const cnpjPesos = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

export function isValidCnpj(cnpjParam: string): boolean {
  let cnpj = cnpjParam || '';
  cnpj = cnpj.replace(/\D/g, '');
  if (cnpj.length !== 14) {
    return false;
  }

  const digits = cnpj.split('').map(v => parseInt(v, 10));

  // Soma os 12 primeiros números multiplicados pelos respectivos pesos
  // (5,4,3,2,9,8...)
  let aux = 0;
  for (let i = 0; i < 12; i += 1) {
    aux += digits[i] * cnpjPesos[i + 1];
  }
  aux = 11 - (aux % 11);
  aux = aux > 9 ? 0 : aux;
  if (digits[12] !== aux) {
    return false;
  }
  // Soma os 13 primeiros números multiplicados pelos respectivos pesos
  // (6,5,4,3,2,9,8...)
  aux = 0;
  for (let i = 0; i < 13; i += 1) {
    aux += digits[i] * cnpjPesos[i];
  }
  aux = 11 - (aux % 11);
  aux = aux > 9 ? 0 : aux;
  if (digits[13] !== aux) {
    return false;
  }
  return true;
}

export function isValidCellphoneNumber(cellphoneNumber: string): boolean {
  return isValidLength(cellphoneNumber, 10, 11);
}

export function isValidPostalCodeNumber(postalCode: string): boolean {
  return isValidLength(postalCode, 8);
}

function isValidLength(
  maskedValue = '',
  ...acceptableLengthsParam: number[]
): boolean {
  const acceptableLengths = acceptableLengthsParam || [];
  const value = onlyNumbers(maskedValue);
  const valueLength = value.length;
  return (
    valueLength === 0 ||
    acceptableLengths.some(length => valueLength === length)
  );
}

export function parseToCep(cep: string): string {
  return cep.replace(/^(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');
}

export function parseToCnpj(cnpj: string): string {
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}

export function parseCep(cep: string): string {
  return onlyNumbers(cep);
}

export function parseCnpj(cnpj: string): string {
  return onlyNumbers(cnpj);
}

export function onlyNumbers(value: string): string {
  return value.replace(/\D/g, '');
}
