import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MaterialDialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { COLORS } from '../../Styles/themes';

interface Props extends DialogProps {
  open: boolean;
  title: string;
  text: string;
  error?: boolean;
  confirmButtonText: string;
  onClose: () => void;
}

const Dialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props);
  const { open, title, text, confirmButtonText, onClose, ...rest } = props;
  return (
    <MaterialDialog
      {...rest}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.title} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className={classes.okButton} autoFocus>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </MaterialDialog>
  );
};

const useStyles = makeStyles({
  title: {
    color: ({ error }: Props) => (error ? COLORS.RED : COLORS.PRIMARY_BLUE),
  },
  okButton: {
    color: COLORS.PRIMARY_BLUE,
  },
});

export { Dialog };
