import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './sample.css';

import { SampleCreators } from '../../Redux/Sample/actions';
import { CountSelector } from '../../Redux/Sample/selectors';

const Sample: React.FC = () => {
  const dispatch = useDispatch();
  const count = useSelector(CountSelector);

  const handlePlusClick = useCallback(() => {
    dispatch(SampleCreators.incrementCountRequest());
  }, [dispatch]);

  const handleDecrementClick = useCallback(() => {
    dispatch(SampleCreators.decrementCountRequest());
  }, [dispatch]);

  return (
    <div className="Sample-container">
      <div>
        <button
          onClick={handlePlusClick}
          className="Sample-button"
          type="button"
        >
          Adicionar
        </button>
        <button onClick={handleDecrementClick} type="button">
          Diminuir
        </button>
      </div>
      <p>{`Count: ${count}`}</p>
    </div>
  );
};

export { Sample };
