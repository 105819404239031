import styled from 'styled-components';

import { COLORS } from '../../../Styles/themes';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonChildren = styled.div``;

export const Divider = styled.div`
  width: 8vw;
  height: 2px;
  margin-top: 4px;
  background: ${COLORS.GREEN};
`;
